/* eslint-disable react/no-direct-mutation-state */
import { Formik } from "formik";
import _ from "lodash";
import React, { Component } from 'react';
import { Form } from 'react-bootstrap';
import { connect } from "react-redux";
import CONSTANTS from '../../config/constants.react';
import { appleloginUser, googleloginUser, loginUser } from '../../redux/apiCalls/authData';
import { LoginLogo, SocialApple, SocialFB, SocialGoogle } from '../icons/Icons';
import Spinner from '../shared/Spinner';
import { validateEmail, validatePassword } from "./../../helpers/helpers";

import {
  LoginSocialApple,
  LoginSocialGoogle
} from "reactjs-social-login";

const SOCIAL_URL = window.location.origin;

export class Login extends Component {

  constructor(props) {
    super(props);
  
    this.state = {
      loading:false,
      showPassword: true,
      tokenLogin: false
    };
  }

  componentDidMount = async () => {
    const searchParams = new URLSearchParams(document.location.search);
    const accessToken = searchParams.get('accessToken');
    if(accessToken){
      this.setState({tokenLogin:true});
      this.props.loginUser({via: "admin", accessToken:accessToken}, (status) => {
        if(!status){
          const page = searchParams.get('page');
          if(page){
            window.location = `/${page}`;
          } else {
            window.location = "/services";
          }
        }
        // this.setState({loading:false});
      })
    }
  }

  toggleSwitch = (e) => {
    this.state.showPassword = !this.state.showPassword;
    this.setState(this.state);
  }
  handleAppleSocialLogin = (googleObj) => {
    this.setState({loading:true});
    let completeData = {
      type: 'apple-android',
      code: googleObj.data.authorization.code,
      ...googleObj.data,
      callbackURL:SOCIAL_URL+"/login"
    }
    this.props.appleloginUser(completeData, (socialLogin) =>{
      if(socialLogin){
        // if(this.props.user.userType === "admin"){
          window.location = "/services";
        // } else {
        //   window.location = "/plans";
        // }
      }
      this.setState({loading:false});
      
    });
  };
  handleGoogleSocialLogin = (googleObj) => {
    this.setState({loading:true});
    let completeData = {
      type: googleObj.provider,
      ...googleObj.data,
      callbackURL:SOCIAL_URL
    }
    this.props.googleloginUser(completeData, (socialLogin) =>{
      this.setState({loading:false});
      console.log("socialLogin: ", socialLogin);
      if(socialLogin){
        // if(this.props.user.userType === "admin"){
          window.location = "/services";
        // } else {
        //   window.location = "/plans";
        // }
      }
    });
  };
  handleSocialLoginFailure = (err) => {
    console.error(err);
    this.setState({loading:false});
  };


  submitForm = (values, { setSubmitting }) => {
    
    this.setState({loading:true});
    let data = {
        email: values.email,
        password: values.password,
        via: "admin"
    }
    this.props.loginUser(data, (status) => {
      this.setState({loading:false});
        if(status){
            setSubmitting(false);
        } else {
          // if(this.props.user.userType === "admin" || this.props.user.userType === "developer"){
            window.location = "/services";
          // } else {
          //   window.location = "/plans";
          // }

          // this.props.history.push("/dashboard");
            // sessionService.loadSession().then((currentSession) => {
            //     if(currentSession.userType == "inspector"){
            //         this.props.history.push("/plans");
            //         // <Redirect from="/" to="/plans" />
            //     } else {
            //         this.props.history.push("/templates");
            //         // <Redirect from="/" to="/templates" />
            //     }
            // }).catch((err) => {
            //     console.log(err);
            // });
        }
    });
    
}

   
  render() {

    return (


      
      <div>
      {this.state.tokenLogin ? <><Spinner/><br/><div className="text-center ">Please wait..</div></> : 
      <div className="login-container">
          <div className="width60">
            {this.state.loading ? <Spinner/> : <></>}
          </div> 
        <div className="auth-form-login">      
          <div className="welcome-back-login">
            {/* <div className="welcome-back-title">Welcome back to,</div> */}
            <LoginLogo className="img-logo" />
          </div>
          <div className="scope-form">
            <div className="login-with">Login with</div>
            <div className="social-buttons">
              {/* <div className="social-fb">
                <SocialFB className="fb" /> 
              </div> */}
              <div >
              <LoginSocialGoogle 
              redirect_uri={SOCIAL_URL}
              scope="profile email"
              // isOnlyGetToken={true}
              // typeResponse="idToken"
              access_type="offline"
              client_id={CONSTANTS.GOOGLE_CLIENT_ID}
              onLogoutFailure={this.handleSocialLoginFailure}
              onResolve={this.handleGoogleSocialLogin
              //   ({ provider, data }) => {
              //   setUser(standardizedUserData(provider, data));
              // }
            }
              onReject={(err) => {
                console.log(err);
              }}
            >
              <button className="social-google" >
                <SocialGoogle className="google" />
              </button>
            </LoginSocialGoogle>
              </div>
              <div >
              <LoginSocialApple
              redirect_uri={`${SOCIAL_URL}/login`}
              client_id="app.login.scope"
              scope="name email"
              onLogoutFailure={this.handleSocialLoginFailure}
              onResolve={this.handleAppleSocialLogin}
              onReject={(err) => {
                console.log(err);
              }}
            >
              <button className="social-apple">
               <SocialApple className="apple" />
              </button>
            </LoginSocialApple>
              </div>
            </div>
            <div className="or-box">
              <div className="or-line"></div>
              <div className="or-login">or</div>
              <div className="or-line"></div>
            </div>
            <div className="login-form">
            <Formik
                    initialValues={{ email: "", password: "", }}
                    validate={values => {
                        let errors = {};
                        let email = validateEmail(values.email);
                        let password = validatePassword(values.password);
                        if(!_.isNil(email)) errors.email = email;
                        if(!_.isNil(password)) errors.password = password;
                        return errors;
                    }}
                    onSubmit={this.submitForm}
                >
                    {({
                        values,
                        errors,
                        touched,
                        handleChange,
                        handleBlur,
                        handleSubmit,
                        isSubmitting,
                        clickHandler
                    }) => (
                <Form className="email-box">
                  <div className="email-title">Email</div>
                  <Form.Group className="email-box">
                    <Form.Control 
                    id="email"
                    type="email"
                    placeholder="Email" 
                    // size="lg" 
                    className="email-field" 
                    onChange={handleChange} 
                    onBlur={handleBlur} 
                    value={values.email}
                    />
                  </Form.Group>
                  {errors.email && touched.email ? <div class="text-danger mb-2">{errors.email}</div> : null}
                  <div className="password-title">Password</div>
                  <Form.Group className="password-box">
                  <div className="input-group">
                    <Form.Control 
                    id="password"
                    type={this.state.showPassword ? "password" : "text"} 
                    placeholder="Password" 
                    // size="lg" 
                    className="password-field" 
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.password}
                    // helperText={errors.password && touched.password && errors.password}
                    error={errors.password && touched.password}
                    />
                    <div className="input-group-append">
                      <span className="input-group-text pass-eye"> <i onClick={this.toggleSwitch} className={this.state.showPassword ? 'mdi mdi-eye-off' : 'mdi mdi-eye'}></i></span>
                    </div>
                  </div>
                  </Form.Group>
                  {errors.password && touched.password ? <div class="text-danger mb-2">{errors.password}</div> : null}
              {/* <div className="remember-me">
                <div className="form-check">
                  <label className="form-check-label">
                    <input type="checkbox" className="form-check-input"/>
                    <i className="input-helper scope-checkbox"></i>
                    <div className="remember-me-title">Remember me</div>
                  </label>
                </div>
              </div> */}
            <button type="submit" disabled={isSubmitting} onClick={handleSubmit} className="login-button">
            <div  className="login-button-title">Login</div>
            </button>
            <div className="forgot">
              <span>
                Forgot your password?
              </span>
              <span>
              </span>
              <span>
                <a href="/reset" className="forgot-link"> Reset</a>
              </span>
            </div>
            <div className="login-sign-up">
              <span>
                Dont have an account yet?
              </span>
              <span>
              <a href="/signup" className="login-sign-up-link"> Signup!</a>
              </span>
            </div>
            </Form>
                )}
                </Formik>
              
            </div>
            
          </div>
        </div>
      </div>
      }
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  return {
      // templates: state.appData.templates,
      // rowsPerPage: state.appData.rowsPerPage,
      user: state.authData.user
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
      loginUser: (template, cb) => dispatch(loginUser(template, cb)),
      googleloginUser: (template, cb) => dispatch(googleloginUser(template, cb)),
      appleloginUser: (template, cb) => dispatch(appleloginUser(template, cb))
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(Login)
// export default Login
