import React, { Component } from 'react';
import { EditIcon, ExpandTileIcon, TrashIcon, UnExpandTileIcon } from '../../../icons/Icons';
import AddEditModifierModal from '../../../components/modal/addModifier';

class ExpandableList extends Component {
    constructor(props) {
        super(props);
        this.state = {
            modifiers: [],
            modifier: {
                id: "",
                modifier_name: "",
                options: [
                    {
                        "option_name": "",
                        "option_value": 0
                    }
                ]

            },
            showDeleteIcon: true,
            showCheckBoxButton: false,
            showEditIcon: false,
            showModal: false,
        };
    }

    componentDidMount = async () => {
        this.setState({ showDeleteIcon: this.props.showDeleteIcon, showCheckBoxButton: this.props.showCheckBoxButton, showEditIcon: this.props.showEditIcon, modifiers: this.props.selectedModifiers });
    }

    componentWillReceiveProps(nextProps) {
        this.setState({ showDeleteIcon: nextProps.showDeleteIcon, showCheckBoxButton: nextProps.showCheckBoxButton, showEditIcon: nextProps.showEditIcon, modifiers: nextProps.selectedModifiers });
    }

    handleItemClick = (itemId) => {
        this.setState(prevState => ({
            modifiers: prevState.modifiers.map(item =>
                item.id === itemId ? { ...item, expanded: !item.expanded } : item
            )
        }));
    };

    handleDeleteItemClick = (modifierId) => {
        const { modifiers } = this.state;
        const index = modifiers.findIndex(modifier => modifier.id === modifierId);
        if (index !== -1) {
            this.state.modifiers.splice(index, 1);
            this.setState(this.state)
            this.props.onDeleteItem(this.state.modifiers);
        }
    }; 

    handleEditItem = (modifier) => {
        this.state.showModal = true;
        this.state.modifier = modifier;
        this.setState(this.state);
    }

    onClose = () => {
        this.state.showModal = true;
        this.setState(this.state);
    }

    saveModifier = (modifier) => {
        this.props.onModifierChange(modifier);
    };

    onDone = (action, object) => {
        if (action === "Save") {
            this.saveModifier(object);
        }
        this.state.showModal = false;
        this.state.modifier = {
            id: "",
            modifier_name: "",
            options: [
                {
                    "option_name": "",
                    "option_value": 0
                }
            ]

        };
        this.setState(this.state);
    }


    render() {
        const { modifiers, showModal , modifier} = this.state;
        return (
            modifiers.length > 0 ?
                <div>
                    <div className='expandable-list-container'>
                    {modifiers.map((item, index) => (
                        <div className={`items-list ${index !== modifiers.length - 1 ? 'bordered' : ''}`} key={item.id}>
                            <div className='list-item'>
                                <div className='tile-actions' onClick={() => this.handleItemClick(item.id)}>
                                    <div className='modifier-name'>{item.modifier_name}</div>
                                    {item.expanded ? <UnExpandTileIcon /> : <ExpandTileIcon />}
                                </div>
                                {/* <div onClick={()=> this.handleEditItem(item)}>
                                <EditIcon />
                                </div> */}
                                <div onClick={() => this.handleDeleteItemClick(item.id)} >
                                    <TrashIcon />
                                </div>
                            </div>
                            {item.expanded && (
                                <div className='select-mod-sub-items'>
                                    {item.options.map((option, subIndex) => (
                                        <div key={subIndex} className='sub-item-list'>
                                            <p className='option-name'>{`${option.option_name}`}</p>
                                            <p className='option-value'>{`+ $${option.option_value}`}</p>
                                        </div>
                                    ))}
                                </div>
                            )}
                        </div>
                    ))}
                </div> 
                    <AddEditModifierModal show={showModal} save={this.saveModifier} modifier={modifier} close={this.onClose} done={this.onDone} />
                </div>
                :
                <div className='Empty-Section'>
                    No Modifier Selected
                </div>
        );
    }
}
export default ExpandableList;
