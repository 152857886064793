/* eslint-disable react/no-direct-mutation-state */
import { Component, React } from 'react';
import CustomTable from "../../components/tables/CustomTable";
import { PlusIcon, AddIconMobile, EmptyScreenIcon, DragSortIcon } from '../../icons/Icons';
import { connect } from 'react-redux';
import AddEditDiscountModal from '../../components/modal/addDiscountModal';
import { addCoupon, getAllCoupons, getAllServices, deleteCoupon, updateCoupon, updateMultipleCoupons } from '../../../redux/apiCalls/appData';
import { setRowsPerPage } from '../../../redux/actions/appAction';
import Spinner from '../../shared/Spinner';
import _ from "lodash";
import toast from 'react-hot-toast';

class ManageDiscounts extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showModal: false,
            page: 0,
            input: '',
            sort: 'ASC',
            coupons: [],
            coupon: {
                id: "",
                title: "",
                code: "",
                expiry: 0,
                limit: "0",
                type: "percent",
                value: "",
                enabled: false,
                services: []
            },
            services: [],
            loading: true,
            totalRecords: 0,
            rowsPerPage: 20,
            user: {},
            type: '',
            activeType: '',
            windowWidth: window.innerWidth,
        };
    }

    componentDidMount = async () => {
        window.addEventListener('resize', this.handleResize);
        // this.setState({ loading: true });
        try {
            this.props.getAllServices("", (data) => {
                if (data.services.length > 0) {
                    this.state.services = data.services;
                }
                this.state.loading = false;
                this.setState(this.state);
            });
        } catch (error) {
            console.error("Error fetching services:", error);
            this.setState({ loading: false });
        }
        this.getAllCoupons();
    }


    getAllCoupons = async () => {
        try {
            this.props.getAllCoupons({
                sort: this.state.sort,
                page: this.state.page,
                rowsPerPage: this.state.rowsPerPage,
            }, (data) => {
                if (data.records.length > 0) {
                    this.state.coupons = data.records;
                    this.state.totalRecords = data.totalRecords;
                    this.setState(this.state);
                }
            });
        } catch (error) {
            console.error("Error fetching coupons:", error);
        }
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.handleResize);
    }


    handleResize = () => {
        this.setState({ windowWidth: window.innerWidth });
    };


    addNewDiscount = () => {
        this.state.showModal = true;
        this.state.coupon = {
            id: "",
            title: "",
            code: "",
            expiry: 0,
            limit: "0",
            type: "percent",
            value: "",
            enabled: false,
            services: [],
        };
        this.setState(this.state);
    }

    saveDiscount = (coupon) => {
        if (coupon.id != '') {
            this.props.updateCoupon(coupon, (data) => {
                if (!data) {
                    toast.error("Code Already Exists");
                    this.editCoupon(coupon); return
                }
                this.getAllCoupons();
            });
        }
        else {
            delete coupon.id;
            this.props.addCoupon(coupon, () => {
                this.getAllCoupons();
            });
        }
    };

    onDone = (action, object) => {
        if (action === "Save") {
            this.saveDiscount(object);
        }
        this.state.showModal = false;
        this.state.coupon = {
            id: "",
            title: "",
            code: "",
            expiry: 0,
            limit: "0",
            type: "percent",
            value: "",
            enabled: false,
            services: []
        };
        this.setState(this.state);
    }

    onClose = () => {
        this.state.showModal = true;
        this.state.coupon = {
            id: "",
            title: "",
            code: "",
            expiry: 0,
            limit: "0",
            type: "percent",
            value: "",
            enabled: false,
            services: []
        };
        this.setState(this.state);
    }

    editCoupon = (coupon) => {
        this.state.showModal = true;
        this.state.coupon = _.merge({}, coupon);
        this.setState(this.state);
    }

    deleteCoupon = (id) => {
        this.props.deleteCoupon(id, {}, () => {
            window.location.reload();
        });
    }

    cloneCoupon = (coupon) => {
        this.state.showModal = true;
        this.state.coupon = _.merge({}, coupon);
        this.state.coupon.id = '';
        this.state.coupon.title = this.state.coupon.title + " (copy)";
        this.state.coupon.code = "";
        this.setState(this.state);
    }

    enableDiscount = (id, value, tableIndex) => {
        let coupon = this.state.coupons.find(
            (coupon) => coupon.id === id
        );
        coupon.enabled = value;
        this.props.updateCoupon(coupon, () => {
            // this.getAllCoupons();
        });
    }


    handleChangePage = (page) => {
        this.setState({ page, loading: true });
        this.props.getAllCoupons(
            {
                sort: this.state.sort,
                page: page,
                rowsPerPage: this.state.rowsPerPage,
            },
            (data) => {
                if (data.records.length > 0) {
                    let appendNewRecords = [...this.state.coupons, ...data.records];
                    this.setState({ coupons: appendNewRecords, page, totalRecords: data.totalRecords, loading: false });
                }
            }
        );
    };

    handleChangeRowsPerPage = (newRowSet) => {
        this.setState({ page: 0, loading: true });
        this.props.setRowsPerPage(newRowSet);
        this.props.getAllCoupons(
            {
                sort: this.state.sort,
                page: 0,
                rowsPerPage: newRowSet,
            },
            (data) => {
                if (data.records.length > 0) {
                    this.setState({ loading: false, coupons: data.records, totalRecords: data.totalRecords });
                }
            }
        );
    };

    sortingCoupons = (coupons) => {
        const couponsList = coupons.map((coupon) => {
            return {
                id: coupon.id,
                sortNo: coupon.sortNo + (this.state.page * this.state.rowsPerPage),
            }
        });
        this.props.updateMultipleCoupons(couponsList);
    }



    render() {
        const { showModal, loading, rowsPerPage, coupons, page, totalRecords, user, coupon, windowWidth } = this.state;
        const allServices = this.state.services ? this.state.services : [];
        let tableHeader = [
            { id: 'DiscountTitle', label: 'Title', sortable: false, beforeIcon: <DragSortIcon className="header-col scope-svg-header-icon" />, width: "150px" },
            { id: 'DiscountCode', label: 'Code', sortable: false },
            { id: 'DiscountExpiry', label: 'Expiry', sortable: false },
            { id: 'DiscountLimit', label: 'Utilization Limit', sortable: false },
            { id: 'DiscountUsedIn', label: 'Used in', sortable: false },
            { id: 'DiscountType', label: 'Discount Type', sortable: false },
            { id: 'DiscountApply', label: 'Apply ', sortable: false },
            { id: 'DiscountActions', label: 'Action', sortable: false },
        ];
        return (
            <div>
                <div className="scope-services-heading">
                    <div className="scope-services-title">Discount Codes</div>
                    <div onClick={this.addNewDiscount}>
                        {
                            windowWidth < 700 ? <AddIconMobile /> : <AddIconMobile height="50" width="40" />
                        }
                    </div>
                </div>
                {
                    loading ? <Spinner /> : !_.isEmpty(coupons) ? <CustomTable
                        tableHead={tableHeader}
                        tableData={coupons}
                        editRow={this.editCoupon}
                        clone={this.cloneCoupon}
                        remove={this.deleteCoupon}
                        type="coupons"
                        page={page}
                        loading={loading}
                        sorting={this.sortingCoupons}
                        rowsPerPage={rowsPerPage}
                        handleChangePage={this.handleChangePage}
                        handleChangeRowsPerPage={this.handleChangeRowsPerPage}
                        handleStatus={this.enableDiscount}
                        services={allServices}
                        totalNumber={totalRecords}
                        disableDrag={false}
                    /> : <div className="empty-modifier-container">
                        <div className="empty-modifier-content">
                            <EmptyScreenIcon />
                            <h2>Oops...!</h2>
                            <h3>No Discount Data Found</h3>
                        </div>
                    </div>
                }
                <AddEditDiscountModal show={showModal} coupon={coupon} close={this.onClose} done={this.onDone} allServices={allServices} />
            </div>
        );
    }
}




const mapStateToProps = (state) => {
    return {
        rowsPerPage: state.appData.rowsPerPage,
        coupons: state.appData.coupons
    };
};
const mapDispatchToProps = (dispatch) => {
    return {
        setRowsPerPage: (rows) => dispatch(setRowsPerPage(rows)),
        addCoupon: (coupon, cb) => dispatch(addCoupon(coupon, cb)),
        deleteCoupon: (id, params, cb) => dispatch(deleteCoupon(id, params, cb)),
        getAllServices: (inspectorId, cb) => dispatch(getAllServices(inspectorId, cb)),
        getAllCoupons: (params, cb) => dispatch(getAllCoupons(params, cb)),
        updateCoupon: (coupon, cb) => dispatch(updateCoupon(coupon, cb)),
        updateMultipleCoupons: (object, cb) => dispatch(updateMultipleCoupons(object, cb)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(ManageDiscounts);
