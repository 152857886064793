/* eslint-disable react/no-direct-mutation-state */
import React from 'react';
import { connect } from 'react-redux';
import { saveEmailHook, getEmailCopy, addMedia } from '../../../redux/apiCalls/appData';
import Spinner from '../../shared/Spinner';
import { Button } from "../../components/button/button.js";
import toast from "react-hot-toast";
import BundledEditor from "../../components/editor/tinyEditor.jsx";
class Email extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            selectedTemplate:"",
            loading: true,
            emailHook:{},
            editorVariables:[],
            modalSettings:{
                show:false,
                templates: this.props.templates
            }
        };
        this.quillEditorRef = React.createRef();
    }
    componentDidMount = () => {
        let emailHook = this.props.match.params.hook;
        if(emailHook !== undefined) {
            let currentHook = this.props.emailHooks.filter( hook => hook.hook === emailHook);
            this.state.emailHook = currentHook[0];
            this.state.loading = false;
            // this.props.getEmailCopy({hook:emailHook}, (data) => {
            //     if(data){
            //         this.state.emailHook.userContent = data;
            //     }
            // })
            // this.state.editorVariables = this.generateVars(this.state.emailHook.templateVariables);
            this.setState(this.state);
        }
    }

    generateVars = (editor) => {
        return this.state.emailHook.templateVariables.map((item) => {
                let newItem = {
                    type: item.type,
                    text: item.text
                };
                if(item.type === "nestedmenuitem"){
                    newItem.getSubmenuItems = function(){
                        return item.subItems.map((sub) => {
                            return {
                                type: sub.type,
                                text: sub.text,
                                onAction: function() {
                                    editor.insertContent(sub.value);
                                }
                            }
                        });
                    }
                }
                return newItem;
        });
    }

    componentDidUpdate = () => {
        console.log(this.quillEditorRef);
        if(this.quillEditorRef && this.quillEditorRef.current && this.state.agreement && this.state.agreement.content)
            this.quillEditorRef.current.setContent(this.state.agreement.content);
    }

    save = (e) => {
        
        const editorContent = this.quillEditorRef.current.getContent();
        let isValidContent = true;
        let listOfRules = [];
        const contentValidation = this.state.emailHook.contentValidation;
        if(contentValidation.variablesPresence){
            contentValidation.variablesPresence.forEach((validation,i) => {
                if(!editorContent.includes(validation)){
                    isValidContent = false;
                    listOfRules.push(validation);
                }
            });
        }
        if(!isValidContent){
            toast.error(`${listOfRules.join(',')} must be present in the content`, {duration: 7000});
            return;
        }
        this.setState({loading:true});
        this.props.saveEmailHook({content: editorContent, hook: this.state.emailHook.hook}, (data) => {
            if(data){
                toast.success("Email copy saved");
            }
            this.setState({loading:false});
        });
    }

    editorLoaded = (editor) => {
        this.quillEditorRef.current = editor;
        let emailHook = this.props.match.params.hook;
        this.setState({loading: true});
        if(this.quillEditorRef && this.quillEditorRef.current && this.state.emailHook){
            this.props.getEmailCopy({hook:emailHook}, (data) => {
                if(data){
                    this.quillEditorRef.current.setContent(data);
                } else {
                    this.quillEditorRef.current.setContent(this.state.emailHook.defaultContent);
                }
                this.setState({loading: false});
            })
            // setTimeout(() => {
            //     let content = "";
            //     if(this.state.emailHook.userContent){
            //         content = this.state.emailHook.userContent;
            //     } else {
            //         content = this.state.emailHook.defaultContent;
            //     }
            //     this.quillEditorRef.current.setContent(content);
            // }, 1000);
        } else {
            this.setState({loading: false});
        }
    }

    resetCopy = (e) => {
        if(this.quillEditorRef && this.quillEditorRef.current && this.state.emailHook && this.state.emailHook.defaultContent){
            this.quillEditorRef.current.setContent(this.state.emailHook.defaultContent);
        }
    }

    uploadImage = (e, callback) => {
        var myPromise = new Promise( (resolve, reject) => {
            const file = e.target.files[0];
            let formData = new FormData();
            formData.append('mediaType', 'inspectionAgreement');
            formData.append('image', file);
            this.props.addMedia(formData, (data)=> {
                if(data){
                    callback(data[0].originalImgUrl, { title: file.name });
                    resolve("Promise resolved successfully");
                }else{
                    // TODO:::
                    reject(Error("Promise rejected"));
                }
            });
         });
         toast.promise(myPromise, {
            loading: 'Image is being uploaded...',
            success: 'Image uploaded successfully.',
            error: 'Something went wrong, please try logging out and login again!',
          });
    }

    render = () => {
        // const { templates } = this.props;
        const { emailHook } = this.state;
        // console.log(this.state);
        return (
            <div style={{overflowX:'hidden'}}>
                {this.state.loading && <Spinner />}
                <div className="container">
                    <div className="row mt-3 mb-3 edit-template-head">
                        <div className="col-md-8">
                            <div className="scope-templetes-title">
                                Editing {emailHook.label} Email
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-8 col-sm-8">
                            <div className="row action_btn_row">
                                <div>
                                    <Button
                                        className="save-agreement"
                                        label={`Reset`}
                                        showLeftIcon={true}
                                        showRightIcon={false}
                                        size="small"
                                        state="default"
                                        variant="outlined"
                                        type="secondary"
                                        clickEvent={(e) => this.resetCopy(e)}
                                    />
                                </div>
                                <div>
                                    <Button
                                        className="save-agreement"
                                        label={`Save`}
                                        showLeftIcon={true}
                                        showRightIcon={false}
                                        size="small"
                                        state="default"
                                        variant="filled"
                                        type="primary"
                                        clickEvent={(e) => this.save(e)}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* <div className="row row-flex">
                    <div className="col-10">
                        <select name='templates' className="form-control" onChange={(e) => this.onChangeTemplate(e)}>
                            {templates.map((template, i)=> {
                                return <option value={template.id}>{template.templateName}</option>
                            })}
                        </select>
                    </div>
                    <div className="col-2">
                    <Button
                        className="save-agreement"
                        label={`Save`}
                        showLeftIcon={true}
                        showRightIcon={false}
                        size="small"
                        state="default"
                        variant="filled"
                        type="primary"
                        clickEvent={(e) => this.save(e)}
                    />
                    </div>
                </div> */}
                <div className="row">
                    <div className="col quileditor-container unlayer-editor-container ml-4 mr-4">
                        <BundledEditor
                            onInit={(evt, editor) => this.editorLoaded(editor)}
                            // initialValue='<p>This is the initial content of the editor.</p>'
                            init={{
                                height: 550,
                                min_height:500,
                                max_height:800,
                                menubar: false,
                                elementpath: false,
                                branding: false,
                                resize: false,
                                plugins: [
                                    'advlist', 'anchor', 'autolink', 'image', 'link', 'lists',
                                    'searchreplace', 'preview', 'table', 'wordcount', 'code', 'table', 'quickbars', 'pagebreak', 'save', 'preview'
                                ],
                                toolbar: 'blocks fontsize | ' +
                                    'bold italic underline strikethrough hr forecolor backcolor removeformat | alignleft aligncenter ' +
                                    'alignright alignjustify | lineheight | bullist numlist outdent indent | ' +
                                    'image link table refVars preview',
                                quickbars_selection_toolbar: 'bold italic underline fontsize | forecolor backcolor | quicklink',
                                quickbars_insert_toolbar: false,
                                quickbars_image_toolbar: 'alignleft aligncenter alignright | rotateleft rotateright | imageoptions',
                                toolbar_mode: 'wrap',
                                // toolbar_sticky: true,
                                // toolbar_sticky_offset: 100,
                                content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }',
                                advlist_bullet_styles: 'square',
                                advlist_number_styles: 'lower-alpha,lower-roman,upper-alpha,upper-roman',
                                file_picker_types: 'image',
                                file_picker_callback: (callback, value, meta) => {
                                    const input = document.createElement('input');
                                    input.setAttribute('type', 'file');
                                    input.setAttribute('accept', 'image/*');

                                    input.addEventListener('change', (e) => this.uploadImage(e, callback));
                                    // input.addEventListener('change', (e) => {
                                    //     const file = e.target.files[0];
                                    //     console.log(e);
                                    //     let formData = new FormData();
                                    //     formData.append('mediaType', 'inspectionAgreement');
                                    //     formData.append('image', file);
                                    //     this.props.addMedia(formData, (data)=> {
                                    //         if(data){
                                    //             callback(data[0].originalImgUrl, { title: file.name });
                                    //         }else{
                                    //             // TODO:::
                                    //         }
                                    //     });
                                    // });
                                    input.click();
                                },
                                setup: (editor) => {
                                    editor.ui.registry.addMenuButton('refVars', {
                                    text: 'Variables',
                                    fetch: (callback) => {
                                        callback(this.generateVars(editor));
                                    }
                                    });
                                }
                            }}
                        />
                    </div>
                </div>
            </div>
        );
    }
}
 
const mapStateToProps = (state) => {
    return {
        emailHooks: state.appData.emailHooks,
        user: state.authData.user
    };
};
const mapDispatchToProps = (dispatch) => {
    return {
        saveEmailHook: (object, cb) => dispatch(saveEmailHook(object, cb)),
        getEmailCopy: (object, cb) => dispatch(getEmailCopy(object, cb)),
        addMedia: (object, cb) => dispatch(addMedia(object, cb)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Email);