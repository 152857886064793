/* eslint-disable react/no-direct-mutation-state */
import React from "react";
import Modal from 'react-bootstrap/Modal';
import {Button as Btn} from "../../components/button/button";
import Constants from '../../../config/constants.react';


class DeleteWarningModal extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
        defaultBody: "Are you sure you want to remove?",
        modalSettings: {
            show: false,
            title: "",
            body: "",
            type: "",
            services: [],
            record: {},
            index: null,
            id: null,
            deletable: true,
            index: 0,
        }
    }
  }
  componentDidMount = async () => {
    this.state.modalSettings = this.props.modalSettings;
    this.setState( this.state );
  }
  componentWillReceiveProps(nextProps, prevProps) {
    this.state.modalSettings = nextProps.modalSettings;
    this.setState( this.state );
  }

  render = () => {
    const {modalSettings, defaultBody} = this.state;
    return (
        <>
        <Modal
            show={modalSettings.show}
            // onHide={() => this.props.close(item)}
            dialogClassName="modal-90w itemEdit"
            aria-labelledby="template-item-edit"
            backdrop="static"
            keyboard={false}
            >
            <Modal.Header>
            <Modal.Title id="template-item-edit">
                {modalSettings.title}
            </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className={`modal-body body-${modalSettings.type}`}>
                  {modalSettings.body.trim().length == 0 ? defaultBody : modalSettings.body }
                </div>
                { modalSettings.services.length > 0 && 
                <div>
                    <ul>
                    {modalSettings.services.map(service => {
                        return (
                            <li key={service.id}><a href={Constants.ADMIN_URL+"new-service/"+service.id} target="_blank">{service.title}</a></li>
                        )
                    })}
                    </ul>
                </div>}
            </Modal.Body>
            <Modal.Footer>
            { modalSettings.deletable ? 
                <><Btn className={`template-item-edit`}
                    label={`No`}
                    showLeftIcon={false}
                    showRightIcon={false}
                    size="large"
                    state="default"
                    variant="ghost"
                    type="primary"
                    clickEvent={(e) => this.props.close("No")} />
                <Btn className={`template-item-edit`}
                label={`Yes`}
                showLeftIcon={false}
                showRightIcon={false}
                size="large"
                state="default"
                variant="outlined"
                type="error"
                clickEvent={(e) => this.props.close("Yes", modalSettings)} /></>
                :
                <Btn className={`template-item-edit`}
                    label={`Okay`}
                    showLeftIcon={false}
                    showRightIcon={false}
                    size="large"
                    state="default"
                    variant="ghost"
                    type="primary"
                    clickEvent={(e) => this.props.close("No")} />}
            </Modal.Footer>
        </Modal>
        </>
    );
  }
}

export default DeleteWarningModal;