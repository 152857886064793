import _ from "lodash";
import React from 'react';
import { connect } from 'react-redux';
import { sessionService } from 'redux-react-session';
import { setRowsPerPage } from '../../../redux/actions/appAction';
import { addComment, deleteComment, getAllComments, updateComment } from '../../../redux/apiCalls/appData';
import CommentAddEditModal from '../../components/modal/commentAddEditModal';
import CustomTable from "../../components/tables/CustomTable";
import { PlusIcon, SearchIcon, SortIcon, AddIconMobile } from '../../icons/Icons';
import Spinner from '../../shared/Spinner';
// import { TrustedHTML } from 'trusted-types/lib';

class CommentsPage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            windowWidth: window.innerWidth,
            page: 0,
            input: '',
            sort: 'DESC',
            sortIcon: 'caret-down',
            comments: [],
            filterComments: [],
            loading: true,
            totalRecords: 0,
            recordsPerPage: 20,
            userType: "",
            activeType:'',
            search: {
                type: "all",
                field: "",
            },
            commentEditModalSettings: {
                show:false,
                description:{},
                status:'',
                action:'add',
                id: null
            },
            commentDialog: {
                label: "",
                maxWidth: "sm",
                value: "",
                title: "",
                description: "",
                field: "",
                parent: false,
                adminComments: [],
                comment: {
                    id: "",
                    status: "",
                    description: ""
                },
                fieldType: "text",
            }
        };
        this.load = true;
    }

    componentDidMount = () => {
        window.addEventListener('resize', this.handleResize);
        this.timer = null;
        let userType = this.props.user && this.props.user.userType ? this.props.user.userType : "";
        if(userType != ""){
            this.props.getAllComments({requester: "web", commenter: userType == "inspector" ? "dashboard" : userType, sort: this.state.sort, input: this.state.input, page: this.state.page, rowsPerPage: this.state.rowsPerPage}, (data) => {
                console.log("componentDidMount:: ", this.props.comments);
                this.setState({loading:false, comments: this.props.comments, filterComments: this.props.comments, totalRecords: data.totalRecords, recordsPerPage:this.state.rowsPerPage});
            });
            if(userType == "inspector"){
                // this.props.getAllComments({commenter: 'admin', NotsetToProps: true}, (data) => {
                //     this.state.commentDialog.adminComments = data;
                //     this.setState(this.state);
                // });
            }
        } else {
            sessionService.loadSession().then((currentSession) => {
                userType = currentSession.userType;
                this.props.getAllComments({requester: "web", commenter: userType == "inspector" ? "dashboard" : userType, sort: this.state.sort, input: this.state.input, page: this.state.page, rowsPerPage: this.state.rowsPerPage}, (data) => {
                    this.setState({loading:false, comments: this.props.comments, totalRecords: data.totalRecords, recordsPerPage:this.state.rowsPerPage});
                });
                if(userType == "inspector"){
                    // this.props.getAllComments({commenter: 'admin', NotsetToProps: true}, (data) => {
                    //     this.state.commentDialog.adminComments = data;
                    //     this.setState(this.state);
                    // });
                }
            });
        }
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.handleResize);
    }

    handleResize = () => {
        this.setState({ windowWidth: window.innerWidth });
    };

    handleChangeRowsPerPage = (event) => {
        this.setState({ page: 0, loading:true });
        let userType = this.props.user && this.props.user.userType ? this.props.user.userType : "";
        this.props.setRowsPerPage(event.target.value);
        if(userType != ""){
            this.props.getAllComments({requester: "admin", commenter: userType == "inspector" ? "user" : userType, sort: this.state.sort, input: this.state.input, page: this.state.page, rowsPerPage: event.target.value}, (data) => {
                this.setState({loading:false, comments: this.props.comments, totalRecords: data.totalRecords, recordsPerPage:event.target.value});
            });
        } else {
            sessionService.loadSession().then((currentSession) => {
                userType = currentSession.userType;
                this.props.getAllComments({requester: "admin", commenter: userType == "inspector" ? "user" : userType, sort: this.state.sort, input: this.state.input, page: this.state.page, rowsPerPage: event.target.value}, (data) => {
                    this.setState({loading:false, comments: this.props.comments, totalRecords: data.totalRecords, recordsPerPage:event.target.value});
                });
            });
        }
    }

    handleChangePage = (page) => {
        this.setState({page});
        let userType = this.props.user && this.props.user.userType ? this.props.user.userType : "";
        if(userType != ""){
            this.props.getAllComments({requester: "web", commenter: userType == "inspector" ? "dashboard" : userType, sort: this.state.sort, input: this.state.input, page: page, rowsPerPage: this.state.rowsPerPage}, (data) => {
                let appendNewRecords = [...this.state.comments, ...data.records]
                this.setState({comments: appendNewRecords,filterComments: appendNewRecords, totalRecords: data.totalRecords, page});
            });
        } else {
            sessionService.loadSession().then((currentSession) => {
                userType = currentSession.userType;
                this.props.getAllComments({requester: "web", commenter: userType == "inspector" ? "dashboard" : userType, sort: this.state.sort, input: this.state.input, page: page, rowsPerPage: this.state.rowsPerPage}, (data) => {
                    let appendNewRecords = [...this.state.comments, ...data.records]
                    this.setState({comments: appendNewRecords, filterComments: appendNewRecords, totalRecords: data.totalRecords, page});
                });
            });
        }
    }
    editCommentModalClose = (action, description, status, id, type) => {
        if(action === "Save" && type != "edit"){
            let commenterUser = this.props.user.userType == "admin" ? this.props.user.userType : "user";
            let comment={
                status:status,
                description:description,
                id:id,
                commenter:commenterUser,
                user:this.props.user.id
            };
            this.setState({commentEditModalSettings:{
                show:false,
            }}); 

            this.props.addComment(comment, (data) => {
                window.location.reload();
            });
        } else if(type === "edit" && action === "Save") {
            let commenterUser = this.props.user.userType == "admin" ? this.props.user.userType : "user";
            let comment={
                status:status,
                description:description,
                id:id,
                commenter:commenterUser,
                user:this.props.user.id
            };
            this.setState({commentEditModalSettings:{
                show:false,
            }}); 

            this.props.updateComment(comment, (data) => {
                window.location.reload();
            });
        }
        if(action === "Cancel"){
            this.setState({commentEditModalSettings:{
                show:false,
                description:{},
                status:'',
                action:'add',
                id: null
            }})
        }
    }

    openCommentModal = (settings, action) => {
        if(action == "edit"){
            this.state.commentEditModalSettings={
                show:true,
                id:settings.id,
                action:action,
                status:settings.status,
                description:settings.description
            }
        }
        if(action == "clone"){
            this.state.commentEditModalSettings={
                show:true,
                id:null,
                action:action,
                status:settings.status,
                description:settings.description
            }
        }
        if(action == undefined){
            this.state.commentEditModalSettings={
                show:true,
                status:'deficiency',
            }
        }
        this.setState( this.state );
    }

    deleteComment = (id) => {
        this.props.deleteComment({id}, (d)=> {
            window.location.reload();
        });
    }

    searchComment = () => {
        this.setState({ page: 0, loading:true });
        console.log("page searchComment", this.state.page);
        let userType = this.props.user && this.props.user.userType ? this.props.user.userType : "";
        if(userType != ""){
            this.props.getAllComments({status: this.state.search.type == "all" ? undefined : this.state.search.type, query: this.state.search.field, requester: "web", commenter: userType == "inspector" ? "dashboard" : userType, sort: this.state.sort, input: this.state.input, page: 0, rowsPerPage: this.state.rowsPerPage}, (data) => {
                this.setState({loading:false, comments: this.props.comments, filterComments: this.props.comments, totalRecords: data.totalRecords, page: this.state.page});
            });
        } else {
            sessionService.loadSession().then((currentSession) => {
                userType = currentSession.userType;
                this.props.getAllComments({status: this.state.search.type == "all" ? undefined : this.state.search.type, query: this.state.search.field, requester: "web", commenter: userType == "inspector" ? "dashboard" : userType, sort: this.state.sort, input: this.state.input, page: 0, rowsPerPage: this.state.rowsPerPage}, (data) => {
                    this.setState({loading:false, comments: this.props.comments, filterComments: this.props.comments, totalRecords: data.totalRecords, page: this.state.page});
                });
            });
        }
    }

    handleChangeFilter = (e) => {
        console.log(e.target.value);
        this.state.search[e.target.name] = e.target.value;
        this.setState(this.state);
        
    }
    handleSearchFilter = (e) => {
        // const filtered = this.state.comments.filter(comment => comment.description.toLowerCase().includes(e.target.value.toLowerCase()));
        // this.setState({filterComments:filtered});
        this.state.search.field = e.target.value.toLowerCase();
        this.setState(this.state);
        this.searchComment();
    }
    handleTypeFilter = (type) => {
        // const filtered = this.state.comments.filter(comment => comment.status.toLowerCase().includes(type.toLowerCase()));
        // this.setState({filterComments:filtered});
        this.state.search.type = type;
        this.setState(this.state);
        this.setState({activeType:type});
        this.searchComment();
    }
    cloneComment = (e) => {
        console.log("clone comment");
    }
    render = () => {
        const { classes, rowsPerPage, user } = this.props;
        const { loading, comments, page, totalRecords, commentEditModalSettings, filterComments, windowWidth } = this.state;
        return (
            <div>
                <div className="scope-templetes-heading">
                    <div className="scope-comment-title">Comments</div>
                    <div onClick={this.openCommentModal} className="large-medium-display-none">
                        {
                            windowWidth < 700 ? <AddIconMobile /> : <AddIconMobile height="50" width="40" />
                        }
                    </div>
                    <div className="row comment-search-filter-row">
                        <div className="col-md-5">
                            <div className="scope-comment-search">
                                <div className="input-group">
                                    <div className="input-group-append">
                                    <div className='search-icon-box'>
                                        <SearchIcon />
                                    </div>
                                    </div>
                                    <input className="form-control" 
                                    id="comment-search-input"
                                    type="text"
                                    placeholder="Search Comments" 
                                    onChange={this.handleSearchFilter}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="scope-comment-filters">
                                <div className="scope-templetes-filters-option">
                                    <div className={this.state.activeType == '' ? 'scope-templetes-filters-button active' : 'scope-templetes-filters-button' }  onClick={()=> this.handleTypeFilter('')}>
                                        <div className="scope-templetes-filters-field">All</div>
                                    </div>
                                    <div className={this.state.activeType == 'deficiency' ? 'scope-templetes-filters-button active' : 'scope-templetes-filters-button' } onClick={()=> this.handleTypeFilter('deficiency')}>
                                        <div className="scope-templetes-filters-field">Deficiency</div>
                                    </div>
                                    <div className={this.state.activeType == 'information' ? 'scope-templetes-filters-button active' : 'scope-templetes-filters-button' } onClick={()=> this.handleTypeFilter('information')} >
                                        <div className="scope-templetes-filters-field">Info</div>
                                    </div>
                                    <div className={this.state.activeType == 'notinspected' ? 'scope-templetes-filters-button active' : 'scope-templetes-filters-button' } onClick={()=> this.handleTypeFilter('notinspected')} >
                                        <div className="scope-templetes-filters-field">Not Inspected</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-1">
                            <div onClick={this.openCommentModal} className="large-medium-display-block">
                                {
                                    windowWidth < 700 ? <AddIconMobile /> : <AddIconMobile height="50" width="40" />
                                }
                            </div>
                        </div>
                    </div>
                </div>
                 {loading ? <Spinner/> :  !_.isEmpty(filterComments) ?
                                        <CustomTable
                                        tableHead={tableHead}
                                        tableData={filterComments}
                                        editRow={this.openCommentModal}
                                        clone={this.openCommentModal}
                                        remove={this.deleteComment}
                                        type="comments"
                                        page={page}
                                        rowsPerPage={rowsPerPage}
                                        totalNumber={totalRecords}
                                        currentUser={user}
                                        handleChangePage={this.handleChangePage}
                                        sorting={this.sortingTemplates}
                                        disableDrag={true}
                                    />

                    : <h4 className="scope-templetes-heading" >No Comment Found</h4> } 
        <CommentAddEditModal modalSettings={commentEditModalSettings} close={this.editCommentModalClose} />
        </div>
        );
    }
}

const tableHead = [
    // { id: 'templateName', label: 'Template Name', sortable: true, beforeIcon: <DragSortIcon className="header-col scope-svg-header-icon"/>,  afterIcon: <SortIcon className="scope-svg-header-after-icon"/>},
    { id: 'commentDescription', label: 'Description', sortable: false , width:"450px"},
    { id: 'status', label: 'Type', sortable: false, width:"190px" },
    { id: 'commenter', label: 'Commenter', sortable: false, width:"190px" },
    { id: 'commentEdit/delete', label: 'Action', sortable: false , width:"10px"},
];

const mapStateToProps = (state) => {
    return {
        comments: state.appData.comments,
        user: state.authData.user,
        rowsPerPage: state.appData.rowsPerPage,
    };
};
const mapDispatchToProps = (dispatch) => {
    return {
        getAllComments: (object, cb) => dispatch(getAllComments(object, cb)),
        setRowsPerPage: (rows) => dispatch(setRowsPerPage(rows)),
        updateComment: (object, cb) => dispatch(updateComment(object, cb)),
        addComment: (object, cb) => dispatch(addComment(object, cb)),
        deleteComment: (object, cb) => dispatch(deleteComment(object, cb)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(CommentsPage);