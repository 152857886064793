import React, { Component } from "react";


class DurationPicker extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isOpen: false,
            selectedOptions: [],
            title: this.props.title,
            value: this.props.value,
            hintText: this.props.hintText,
            doneType: null
        };

        this.uniqueId = `custom-dropdown-${Math.floor(Math.random() * 10000)}`;
    }

    componentDidMount() {
        window.addEventListener("click", this.handleWindowClick);
        this.setState({selectedOptions: this.props.selectedOptions});
    }

    componentWillReceiveProps(nextProps) {
        this.setState({ selectedOptions: nextProps.selectedOptions });
    }

    componentWillUnmount() {
        window.removeEventListener("click", this.handleWindowClick);
    }

    toggleDropdown = () => {
        this.setState((prevState) => ({
            isOpen: !prevState.isOpen,
        }));
    };


    handleWindowClick = (event) => {
        const dropdown = document.getElementById(this.uniqueId);
        if (dropdown && !dropdown.contains(event.target)) {
            this.setState({
                isOpen: false,
            });
        }
    };

    onColumn1Click = (value) => {
        console.log("value", value);
        const [hourString, minuteString] = value.split(/hr|min/);
        const hour = parseInt(hourString.trim());
        const minute = parseInt(minuteString.trim().replace(':', ''));
        const totalMinutes = hour * 60 + minute;
        this.props.onDurationChange(totalMinutes);
        this.toggleDropdown();
    }
    onColumn2Click = (value) => {
        console.log("value", value);
        const [hourString, minuteString] = value.split(/hr|min/);
        const hour = parseInt(hourString.trim());
        const minute = parseInt(minuteString.trim().replace(':', ''));
        const totalMinutes = hour * 60 + minute;
        this.props.onDurationChange(totalMinutes);
        this.toggleDropdown();
    }



    render() {
        const { isOpen, title, hintText } =
            this.state;
        const { options, value } = this.props;
        const formatMinutesToTimeString = minutes => `${String(Math.floor(minutes / 60)).padStart(2, '0')} hr : ${String(minutes % 60).padStart(2, '0')} min`;
        let displayText = value ? `${Math.floor(value / 60)} Hours and ${value % 60} Minutes` : hintText;

        return (
            <div>
                <p className="dropdown-title">{title}</p>
                <div className="custom-dropdown" id={this.uniqueId}>
                    <button
                        className={`dropdown-button ${isOpen ? "open" : ""}`}
                        onClick={this.toggleDropdown}
                    >
                        {displayText}
                    </button>
                    {isOpen && (
                        <div className="dropdown-options duration-picker-options">
                            {options && options.length > 0 ? (
                                options.map((option) => (
                                    // <span key={option.id} className="duration-picker-item">
                                        <div className="row m-0 p-2 justify-content-center">
                                            <div className={option.column1 === formatMinutesToTimeString(value) ? "duration-picker-item col duration-options custom-column-selected px-2 text-center" : "duration-picker-item col duration-options custom-column px-2 text-center"} onClick={() => this.onColumn1Click(option.column1)}>
                                                {option.column1}
                                            </div>
                                            <div className={option.column2 ? option.column2 === formatMinutesToTimeString(value) ? "duration-picker-item col duration-options custom-column-selected px-2 text-center": "duration-picker-item col duration-options custom-column px-2 text-center" : "duration-picker-item col duration-options custom-column-empty px-2 text-center"} onClick={option.column2 ? () => this.onColumn2Click(option.column2) : null}>
                                                {option.column2}
                                            </div>
                                        </div>
                                    // </span>
                                ))
                            ) : (
                                <>{`No option found`}</>
                            )}
                        </div>

                    )}
                </div>
            </div>

        );
    }
}

export default DurationPicker;