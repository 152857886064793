import { AgreementsIcon, CommentIcon, DiscountIcon, EmailsIcon, InspectionIcon, ModifierIcon, PaymentIcon, PlanIcon, ServiceIcon, TemplateIcon, UserIcon } from './icons/Icons';
import Login from './user-pages/Login';
import Reset from './user-pages/Reset';
import Signup from './user-pages/Signup';
import Verifycode from './user-pages/Verifycode';
import Agreement from './views/agreement/agreement';
import Agreements from './views/agreement/agreements';
import Comments from './views/comments/comments';
import ManageDiscounts from './views/discount/manage_discounts';
import Email from './views/email/email';
import EmailPage from './views/email/emails';
import Modifiers from './views/modifiers/modifiers';
import Payments from './views/payments/payments';
import Plans from './views/plans/plans';
import ManageServices from './views/services/services.js';
import NewService from './views/services/new-service';
import ViewService from './views/services/view-service';
import EditTemplate from './views/templates/editTemplate';
import Templates from './views/templates/templates';
import UserInspections from './views/users/UserInspections';
import Users from './views/users/users';
const routes = [
    {
        path: '/services',
        name: 'Services',
        icon: <ServiceIcon />,
        component: ManageServices,
        isMenuItem: true,
        allowedRoles: ['admin', 'inspector']
    },
    {
        path: '/modifiers',
        name: 'Modifiers',
        icon: <ModifierIcon />,
        component: Modifiers,
        isMenuItem: true,
        allowedRoles: ['admin', 'inspector']
    },
    {
        path: '/discount-details',
        name: 'Discount',
        icon: <DiscountIcon />,
        component: ManageDiscounts,
        isMenuItem: true,
        allowedRoles: ['admin', 'inspector']
    },
    {
        path: '/agreements',
        name: 'Agreements',
        component: Agreements,
        icon: <AgreementsIcon />,
        isMenuItem: true,
        allowedRoles: ['admin', 'inspector']
    },
    {
        path: '/templates',
        name: 'Templates',
        icon: <TemplateIcon />,
        component: Templates,
        isMenuItem: true,
        allowedRoles: ['admin', 'inspector']
    },
    {
        path: '/comments',
        name: 'Comments',
        component: Comments,
        icon: <CommentIcon />,
        isMenuItem: true,
        allowedRoles: ['admin', 'inspector']
    },
    {
        path: '/user/inspections',
        name: 'Inspections',
        component: UserInspections,
        icon: <InspectionIcon />,
        isMenuItem: true,
        allowedRoles: ['admin', 'inspector']
    },
    {
        path: '/users',
        name: 'Users',
        component: Users,
        icon: <UserIcon />,
        isMenuItem: true,
        allowedRoles: ['admin'],
    },
    {
        path: '/emails/',
        name: 'Emails',
        component: EmailPage,
        icon: <EmailsIcon />,
        isMenuItem: true,
        allowedRoles: ['admin', 'inspector']
    },
    {
        path: '/payments',
        name: 'Payments',
        component: Payments,
        icon: <PaymentIcon />,
        isMenuItem: true,
        allowedRoles: ['admin']
    },
    {
        path: '/plans',
        name: 'Plans',
        component: Plans,
        icon: <PlanIcon />,
        isMenuItem: true,
        allowedRoles: ['admin', 'inspector']
    },
    {
        path: '/new-service/:id?',
        name: 'EditService',
        icon: <TemplateIcon />,
        component: NewService,
        isMenuItem: false,
        allowedRoles: ['admin', 'inspector']
    },
    {
        path: '/service-details',
        name: 'Services',
        icon: <ServiceIcon />,
        component: ViewService,
        isMenuItem: false,
        allowedRoles: ['admin', 'inspector']
    },

    {
        path: '/template/:id?',
        name: 'EditTemplate',
        icon: <TemplateIcon />,
        component: EditTemplate,
        isMenuItem: false,
        allowedRoles: ['admin', 'inspector']
    },
    {
        path: '/login',
        name: 'login',
        icon: <TemplateIcon />,
        isMenuItem: false,
        component: Login,
    },
    {
        path: '/reset',
        name: 'reset',
        icon: <TemplateIcon />,
        isMenuItem: false,
        component: Reset,
    },
    {
        path: '/verification/:code',
        name: 'verification',
        icon: <TemplateIcon />,
        isMenuItem: false,
        component: Verifycode,
    },

    {
        path: '/agreement/:id',
        name: 'Agreement',
        component: Agreement,
        icon: <PaymentIcon />,
        isMenuItem: false,
        allowedRoles: ['admin', 'inspector']
    },

    {
        path: '/email/:hook',
        name: 'Email',
        component: Email,
        icon: <PaymentIcon />,
        isMenuItem: false,
        allowedRoles: ['admin', 'inspector']
    },
    {
        path: '/signup',
        name: 'signup',
        icon: <TemplateIcon />,
        isMenuItem: false,
        component: Signup,
    },

    // Default Template Routes
    // {
    //     path: '/form-Elements/buttons',
    //     name: 'Button',
    //     icon: <TemplateIcon />,
    //     component: Buttons,
    //     layout: 'admin'
    // },
    // {
    //     path: '/dashboard',
    //     name: 'Dashboard',
    //     icon: <TemplateIcon />,
    //     component: Dashboard,
    //     layout: 'admin'
    // },
    // {
    //     path: '/form-Elements/basic-d',
    //     name: 'Drop Downs',
    //     icon: <TemplateIcon />,
    //     component: Dropdowns,
    //     layout: 'admin'
    // },
    // {
    //     path: '/form-Elements/forms',
    //     name: 'Form',
    //     icon: <TemplateIcon />,
    //     component: BasicElementsForms,
    //     layout: 'admin'
    // },
    // {
    //     path: '/form-Elements/basic-t',
    //     name: 'Typographic',
    //     icon: <TemplateIcon />,
    //     component: Typography,
    //     layout: 'admin'
    // },
    // {
    //     path: '/form-Elements/basic-tt',
    //     name: 'Tables',
    //     icon: <TemplateIcon />,
    //     component: BasicTable,
    //     layout: 'admin'
    // },
    // {
    //     path: '/form-Elements/basic-m',
    //     name: 'MDI',
    //     icon: <TemplateIcon />,
    //     component: Mdi,
    //     layout: 'admin'
    // },
    // {
    //     path: '/form-Elements/basic-ee',
    //     name: 'ERROR400',
    //     icon: <TemplateIcon />,
    //     component: Error404,
    //     layout: 'admin'
    // },
    // {
    //     path: '/form-Elements/basic-eee',
    //     name: 'Error500',
    //     icon: <TemplateIcon />,
    //     component: Error500,
    //     layout: 'admin'
    // },
    // {
    //     path: '/form-Elements/basic-ll',
    //     name: 'Lock',
    //     icon: <TemplateIcon />,
    //     component: Lockscreen,
    //     layout: 'admin'
    // },
    // {
    //     path: '/form-Elements/basic-bb',
    //     name: 'Blank',
    //     icon: <TemplateIcon />,
    //     component: BlankPage,
    //     layout: 'admin'
    // },
    // {
    //     path: '/form-Elements/basic-r',
    //     name: 'Register',
    //     icon: <TemplateIcon />,
    //     component: Register,
    //     layout: 'admin'
    // },
    // {
    //     path: '/form-Elements/Chartjs',
    //     name: 'Chartjs',
    //     icon: <TemplateIcon />,
    //     component: ChartJs,
    //     layout: 'admin'
    // },
];
export default routes