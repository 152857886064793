/* eslint-disable react/no-direct-mutation-state */
import React from "react";
import Modal from 'react-bootstrap/Modal';
import {Button as Btn} from "../../components/button/button";
import ListGroup from 'react-bootstrap/ListGroup';

class AgreementImportModal extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
        modalSettings: {
            show: false,
            agreements: []
          }
    }
  }
  componentDidMount = async () => {
    this.state.modalSettings = this.props.modalSettings;
    this.setState( this.state );
  }
  componentWillReceiveProps(nextProps) {
    this.state.modalSettings = nextProps.modalSettings;
    // this.state.saveBtnDisabled = true;
    this.setState( this.state );
  }

  // onChange = (e) => {
  //   this.state.modalSettings.value = e.target.value;
  //   if(this.state.modalSettings.value.trim().length === 0){
  //     this.state.saveBtnDisabled = true;
  //   } else {
  //     this.state.saveBtnDisabled = false;
  //   }
  //   this.setState(this.state);
  // }

  render = () => {
    const {modalSettings} = this.state;
    return (
        <>
        <Modal
            show={modalSettings.show}
            // onHide={() => this.props.close(item)}
            dialogClassName="modal-60w itemEdit modal-scope"
            aria-labelledby="template-item-edit"
            backdrop="static"
            >
            <Modal.Header>
            <Modal.Title id="template-item-edit">
                Choose the Agreement to import the content
            </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <ListGroup key={"refs"}>
                  <ListGroup.Item key={`ref-master`} action onClick={(e) => this.props.close('master')} >
                          {"Default Agreement Copy"}
                  </ListGroup.Item>
                  {modalSettings.agreements.length > 0 && modalSettings.agreements.map((ref, i) => {
                    return (
                      <ListGroup.Item key={`ref-${i}`} action onClick={(e) => this.props.close(ref.id)} >
                        {ref.title}
                      </ListGroup.Item>
                    )
                  })}
                </ListGroup>
            </Modal.Body>
            <Modal.Footer>
            <Btn className={`template-item-edit`}
                label={`Cancel`}
                showLeftIcon={false}
                showRightIcon={false}
                size="large"
                state="default"
                variant="ghost"
                type="primary"
                clickEvent={(e) => this.props.close("Cancel")} />
            </Modal.Footer>
        </Modal>
        </>
    );
  }
}

export default AgreementImportModal;