/* eslint-disable react/no-direct-mutation-state */
import { Component, React } from 'react';
import CustomTable from "../../components/tables/CustomTable";
import {EmptyScreenIcon, AddIconMobile, ServiceLinkIcon } from '../../icons/Icons';
import { connect } from 'react-redux';
import { deleteService, getAllServices, updateMultipleServices } from '../../../redux/apiCalls/appData';
import { setRowsPerPage } from '../../../redux/actions/appAction';
import Spinner from '../../shared/Spinner';
import Constants, { BASE_URL } from '../../../config/constants.react';
import toast from 'react-hot-toast';
import DeleteWarningModal from '../../components/modal/deleteWarningModal';
class ManageServices extends Component {
    constructor(props) {
        super(props);
        this.state = {
            page: 0,
            input: '',
            sort: 'ASC',
            sortIcon: 'caret-down',
            services: [],
            loading: true,
            rowsPerPage: 20,
            type: '',
            activeType: '',
            windowWidth: window.innerWidth,
            deleteWarningModal: {
                show: false,
                title: "",
                body: "",
                services: [],
                type: "",
                id: "",
                deletable:true
            }
        };
    }

    componentDidMount = async () => {
        window.addEventListener('resize', this.handleResize);
        this.props.getAllServices("", (data) => {
            if (data.services.length > 0) {
                this.state.services = data.services;
            }
            this.state.loading = false;
            this.setState(this.state);
        });
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.handleResize);
    }


    handleResize = () => {
        this.setState({ windowWidth: window.innerWidth });
    };

    editService = (service) => {
        this.props.history.push(`/new-service/${service.id}`);
    }

    removeService = (id) => {
        this.props.deleteService(id, {}, (data) => {
            if (data.status && data.status === 200) {
                this.props.getAllServices("", (data) => {
                    if (data.services.length > 0) {
                        this.state.services = data.services;
                    }
                    this.state.loading = false;
                    this.setState(this.state);
                });    
                // this.setState({ services: data.data, loading: false, totalRecords: data.data.length });
                toast.success("Service deleted successfully");
            } else {
                if(data && data.data && data.data.message && data.data.services){
                    this.state.deleteWarningModal = {
                        show: true,
                        title: "Warning!",
                        body: data.data.message,
                        services: data.data.services || [],
                        type: 'danger',
                        id: id,
                        deletable: true,
                    }
                    this.setState(this.state);
                }
            }
        });
    }
    deleteWarningModalClose = (action, object) => {
        this.state.deleteWarningModal = {
            show: false,
            title: "",
            body: "",
            services: [],
            type: "",
            id: null,
            deletable: true,
        };
        this.setState(this.state);
        if (action === "Yes") {
            this.props.deleteService(object.id, {confirm: true}, (data) => {
                this.props.getAllServices("", (data) => {
                    if (data.services.length > 0) {
                        this.state.services = data.services;
                    }
                    this.state.loading = false;
                    this.setState(this.state);
                });    
                // this.setState({ services: data.data, loading: false, totalRecords: data.data.length });
                toast.success("Service deleted successfully");
            });

            //   this.props.deleteSection(this.state.index, this.state.type);
        }
    }

    cloneService = (service) => {
        delete service.id;
        service.title = service.title + " (Copy)"
        this.props.history.push({
            pathname: '/new-service',
            state: { service }
        });
    }

    newService = () => {
        this.props.history.push(`/new-service`);
    }

    viewService = (id) => {
        const service = this.state.services.find(service => service.id === id);
        this.props.history.push({
            pathname: '/service-details',
            state: { service }
        });
    }

    sortingServices = (services) => {
        const servicesList = services.map((service) => {
            return {
                id: service.id,
                sortNo: service.sortNo + (this.state.page * this.state.rowsPerPage),
            }
        });
        this.props.updateMultipleServices(servicesList);
    }

    copyLink = () => {
        const url = Constants.BOOKING_SITE_URL+this.props.inspectorId;
        navigator.clipboard.writeText(url);
        toast.success('Booking Url Copied');
    }

    render() {
        const { loading, rowsPerPage, services, page, windowWidth, } = this.state;
        let tableHeader = [
            { id: 'serviceTitle', label: 'Title', sortable: false },
            { id: 'description', label: 'Description', sortable: false, width: "150px" },
            { id: 'price', label: 'Price', sortable: false },
            { id: 'serviceEdit/delete', label: 'Action', sortable: false },
        ];

        return (
            <div>
                <div className="scope-services-heading">
                    <div className="scope-services-title">Manage Services</div>
                        {
                        windowWidth < 700 ? 
                        <div onClick={this.copyLink} className='mobile_link_icon'>
                            <ServiceLinkIcon className="col-2 link_icon"/>
                            <div className="mobile_add_service_btn" onClick={this.newService}><AddIconMobile/></div>
                        </div>
                        : 
                        <div className="row booking_url_row">
                            <div className="col booking_url_col">
                                <div className='booking_url_element row' onClick={this.copyLink}>
                                    <div className='col'>Copy Booking URL </div>
                                    <ServiceLinkIcon className="col-2 link_icon"/>
                                </div>
                            </div>
                            <div className="col-1 add_service_btn" onClick={this.newService}>
                                <AddIconMobile height="50" width="40"/>
                            </div>
                        </div>
                    }
                </div>
                {
                    loading ? <Spinner /> : services.length > 0 ?
                        <CustomTable
                            tableHead={tableHeader}
                            tableData={services}
                            editRow={this.editService}
                            clone={this.cloneService}
                            remove={this.removeService}
                            type="services"
                            page={page}
                            loading={loading}
                            sorting={this.sortingServices}
                            rowsPerPage={rowsPerPage}
                            disableDrag={false}
                            onRowClick={this.viewService}
                        />
                        : <div className="empty-modifier-container">
                            <div className="empty-modifier-content">
                                <EmptyScreenIcon />
                                <h2>Oops...!</h2>
                                <h3>No Service Found</h3>
                            </div>
                        </div>
                }
            <DeleteWarningModal modalSettings={this.state.deleteWarningModal} close={this.deleteWarningModalClose} />
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        rowsPerPage: state.appData.rowsPerPage,
        services: state.appData.services,
        inspectorId: state.authData.user.id,
    };
};
const mapDispatchToProps = (dispatch) => {
    return {
        setRowsPerPage: (rows) => dispatch(setRowsPerPage(rows)),
        getAllServices: (inspectorId, cb) => dispatch(getAllServices(inspectorId, cb)),
        deleteService: (id, service, cb) => dispatch(deleteService(id, service, cb)),
        updateMultipleServices: (object, cb) => dispatch(updateMultipleServices(object, cb)),
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(ManageServices);
