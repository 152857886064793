import _ from 'lodash';
import moment from 'moment';
import PropTypes from "prop-types";
import React from 'react';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import { Table } from 'react-bootstrap';
import InfiniteScroll from "react-infinite-scroll-component";
import Constants from '../../../config/constants.react';
import { BlockUserIcon, CopyIcon, DeficiencyIcon, DragIcon, EditIcon, EyeIcon, InformationIcon, NotInspectedIcon, TrashIcon, UnBlockUserIcon, WrongIcon, RightIcon, SendIcon2 } from '../../icons/Icons';
import Spinner from '../../shared/Spinner';
import ReportSent from '../Dialog/SendReportDialog';
import { Button } from "../button/button";
import { Switch } from '../common/Switch.js';
import DeleteConfirmationModal from '../modal/deleteConfirmationModal.jsx';

const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list)
    const [removed] = result.splice(startIndex, 1)
    result.splice(endIndex, 0, removed)
    return result
}

const getItemStyle = (isDragging, draggableStyle) => ({
    ...draggableStyle,
    ...(isDragging && {
        background: "rgba(235,235,235, 0.7)",
        display: "flex",
        alignItems: 'center',
        justifyContent: 'space-between'
    })
})

class CustomTable extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            order: 'asc',
            orderBy: 'sortNo',
            page: 0,
            newRecords: 20,
            rowsPerPage: 100,
            defaultRowsPerPage: 100,
            items: this.props.tableData,
            onRowClick: this.props.onRowClick,
            deleteConfirmationModal: {
                show: false,
                title: "",
                body: "",
                type: "",
            }
        };
        this.load = true;
    }
    componentDidMount = () => {
        // const el = document.querySelector(".main-panel");
        // el.addEventListener('scroll', this.handleScroll);
    }

    componentWillReceiveProps(nextProps) {
        this.state.items = nextProps.tableData;
        this.state.page = nextProps.page;
        this.setState(this.state);
    }
    componentWillUnmount = () => {
        // const el = document.querySelector(".main-panel");
        // el.removeEventListener('scroll', this.handleScroll);
    }
    onDragEnd = (result) => {
        if (!result.destination) {
            return
        }
        var items = reorder(
            this.state.items,
            result.source.index,
            result.destination.index
        )

        items = sortNumberSorting(items);
        if (_.isFunction(this.props.sorting)) {
            this.props.sorting(items);
        }
        this.setState({
            items
        })
    }
    // handleChangePage = (event, page) => {
    //     this.setState({ page });
    //     this.props.handleChangePage(page);
    // }
    handleChangeRowsPerPage = event => {
        this.setState({ page: 0, rowsPerPage: event.target.value });
        this.props.handleChangeRowsPerPage(event);
    }
    handleRequestSort = (event, property) => {
        if (this.props.type == "templates" || this.props.type == "users") {
            this.props.handleSorting(property);
        } else {
            const orderBy = property;
            let order = 'desc';
            if (this.state.orderBy === property && this.state.order === 'desc') {
                order = 'asc';
            }
            this.setState({ order, orderBy });
        }
    }
    editTextChange = event => {
        this.props.editCountryTextChange(event.target.value);
    }
    onRowClick = id => {
        if (_.isFunction(this.props.onRowClick)) return this.props.onRowClick(id)
    }
    accessRoleHandle = (row, currentUser) => {
        let isAllow = false;
        if (currentUser.userType == 'developer') {
            isAllow = true;
        } else if (currentUser.userType == 'admin' && row.templateUser == 'admin') {
            isAllow = true;
        } else if (currentUser.userType == row.templateUser && currentUser.id == row.user) {
            isAllow = true;
        }
        return isAllow;
    }
    infiniteScroll = () => {
        setTimeout(() => {
            let pageNo = (this.props.page + 1);
            this.props.handleChangePage(pageNo);
        }, 500);
    }
    handleScroll = (event) => {
        const box = document.querySelector(".footer");
        let rect = box.getBoundingClientRect();
        console.log((rect.bottom) < 750, "handleScroll", rect.bottom);
        // if ((rect.bottom) < 750) {
        if ((this.props.type == "templates" || this.props.type == "coupons" || this.props.type == "services" || this.props.type == "inspections" || this.props.type == "users") && this.props.totalNumber > this.props.tableData.length) {
            let pageNo = (this.props.page + 1);
            this.props.handleChangePage(pageNo);
        } else {
            const { defaultRowsPerPage, rowsPerPage, items, newRecords } = this.state;
            let newRows = (rowsPerPage + defaultRowsPerPage);
            if (items.length > newRows && items.length != newRows) {
                this.setState({ rowsPerPage: newRows });
            }
        }
        // }
    }

    renderTableCell = (id, key, type, page, rowsPerPage, index, row, currentUser) => {
        let isEditable;
        if (id === 'id') {
            return (
                <td className="" key={key}>{(index + 1) - (page * rowsPerPage)}</td>
            )
        }
        else if (id === 'templateName') {
            return (
                <td className={`${rowsPerPage} column-${id}`} key={key}>
                    {((type === "templates" && currentUser.userType !== "inspector") || (row.templateUser === "inspector")) ?
                        <div className="dragIcon">
                            <DragIcon className="header-col scope-svg-menu-icon" />
                        </div>
                     : 
                     <div className="dragIcon" style={{visibility:"hidden"}}>
                        <DragIcon className="header-col scope-svg-menu-icon" />
                    </div>
                     }
                    <div className="template-name">
                        {row.templateName}
                    </div>
                </td>
            )
        }
        else if (id === 'title') {
            return (
                <td className={`${rowsPerPage} column-${id}`} key={key} onClick={this.props.type == "services" ? () => this.onRowClick(row.id) : null} style={{ display: "flex" }}>
                    <div className='table-title-item'>
                        {type === "services" &&
                            <div className="dragIcon">
                                <DragIcon className="header-col scope-svg-menu-icon" />
                            </div>
                        }
                        <div className="template-name">
                            {row.title}
                        </div>
                    </div>
                </td>
            )
        }
        else if (id === 'serviceTitle') {
            return (
                <td className={`${rowsPerPage} column-${id}`} key={key} onClick={this.props.type == "services" ? () => this.onRowClick(row.id) : null} style={{ display: "flex" }}>
                    <div className='table-title-item'>
                        {type === "services" &&
                            <div className="dragIcon">
                                <DragIcon className="header-col scope-svg-menu-icon" />
                            </div>
                        }
                        <div className="template-name" style={{color: '#2775FF'}}>
                            {row.title}
                        </div>
                    </div>
                </td>
            )
        }
        else if (id === 'modifier_name') {
            return (
                <td className={`${rowsPerPage} column-${id}`} key={key} style={{ width: "300px", display: "flex" }}>
                    <div className='table-title-item'>
                        <div className="dragIcon">
                            <DragIcon className="header-col scope-svg-menu-icon" />
                        </div>
                        <div className="template-name">
                            {row.modifier_name}
                        </div>
                    </div>
                </td>
            )
        }
        else if (id === 'DiscountTitle') {
            return (
                <td className={`${rowsPerPage} column-${id}`} key={key}>
                    <div className='table-title-item'>
                        <div className="dragIcon">
                            <DragIcon className="header-col scope-svg-menu-icon" />
                        </div>
                        <div className="template-name">
                            {row.title}
                        </div>
                    </div>
                </td>
            )
        }
        else if (id === 'DiscountCode') {
            return (
                <td className={`${rowsPerPage} column-${id}`} key={key} style={{ width: "130px" }}>
                    <div className="template-name">
                        {row.code == '' ? "-" : row.code}
                    </div>
                </td>
            )
        }
        else if (id === 'DiscountExpiry') {
            let dateRow = "";
            if (row.expiry === 0) {
                dateRow = "Lifetime";
                return (
                    <td className={`${rowsPerPage} column-${id}`} key={key} style={{ width: "190px" }}>
                        <div className="template-name">{dateRow}</div>
                    </td>
                )
            }
            const date = new Date(row.expiry);
            const day = String(date.getDate()).padStart(2, '0');
            const month = String(date.getMonth() + 1).padStart(2, '0');
            const year = date.getFullYear();
            dateRow = `${year}-${month}-${day}`;
            return (
                <td className={`${rowsPerPage} column-${id}`} key={key} style={{ width: "190px" }}>
                    <div className="template-name">{dateRow}</div>
                </td>
            )
        }
        else if (id === 'DiscountLimit') {
            return (
                <td className={`${rowsPerPage} column-${id}`} key={key} style={{ width: "120px" }}>
                    <div className="template-name">
                        {row.limit == '' ? "Unlimited" : `${row.limit} Times`}
                    </div>
                </td>
            )
        }
        else if (id === 'DiscountUsedIn') {
            const allServices = this.props.services;
            return (
                <td className={`${rowsPerPage} column-${id}`} key={key} style={{ width: "230px" }}>
                    <div className="template-name">
                        {row.services.length === 0 ? "-" :
                            row.services.map(serviceId => {
                                const service = allServices.find(service => service.id === serviceId);
                                return service ? service.title : '';
                            }).filter(Boolean).join(', ')}
                    </div>
                </td>
            )
        }
        else if (id === 'DiscountType') {
            return (
                <td className={`${rowsPerPage} column-${id}`} key={key} style={{ width: "140px" }}>
                    <div className="template-name">
                        {row.type == '' ? "-" : row.type}
                    </div>
                </td>
            )
        }
        else if (id === 'templateDescription') {
            return (
                <td
                    className="template-description"
                    key={key}
                    onClick={this.props.type === "services" ? () => this.onRowClick(row.id) : null}
                    dangerouslySetInnerHTML={{ __html: row.templateDescription }}
                ></td>
            );
        }
        else if (id === 'description') {
            function stripHtmlTags(html) {
                let temp = document.createElement("div");
                temp.innerHTML = html;
                let plainText = temp.textContent || temp.innerText || "";
                temp = null;
                if (plainText.length > 100) {
                    plainText = plainText.substring(0, 100) + "...";
                }
                return plainText;
            }
            return (
                <td className="template-description" key={key} onClick={this.props.type == "services" ? () => this.onRowClick(row.id) : null}>
                    {stripHtmlTags(row.description)}
                </td>
            )
        }
        else if (id === 'price') {
            return (
                <td className="template-description" key={key}>
                    {row.price ? `$${parseFloat(row.price).toFixed(2)}` : "-"}
                </td>
            );
        }
        else if (id === 'adminPublished') {
            return (
                <td className="" key={key}>
                    <Switch switchOnTitle="Switching Template On " switchOffTitle="Switching Template Off" index={index} field={row.adminPublished} id={row.id} uid={row.id + "adminPublished"} onSwitchAction={this.props.handleStatus} />
                </td>
            )
        }
        else if (id === 'DiscountApply') {
            return (
                <td key={key}>
                    <Switch switchOnTitle="Switching Discount On " switchOffTitle="Switching Discount Off" index={index} field={row.enabled} id={row.id} uid={row.id + "DiscountApply"} onSwitchAction={this.props.handleStatus} />
                </td>
            )
        }
        else if (id === 'listWithUserTemplates') {
            return (
                <td className="" key={key}>
                    <Switch switchOnTitle="" switchOffTitle="" index={index} field={row.listWithUserTemplates} id={row.id} uid={row.id + "listWithUserTemplates"} onSwitchAction={this.props.listToUserTemplate} />
                </td>
            )
        }
        else if (id === 'templateEdit/delete') {
            return (
                <td className={`row-${index}`} key={key} style={{ margin: "0px" }} >
                    <div style={{ width: "149px" }}>
                        {isEditable = this.accessRoleHandle(row, currentUser) //(currentUser.userType == row.templateUser && currentUser.id == row.user) ? true : false 
                        }
                        {
                            // console.log(currentUser.userType, 'role based -- ',  row.templateUser, isEditable, row.templateName)
                        }
                        {isEditable ? <span className="template-action-button" onClick={() => this.props.editRow(row.id)}> <EditIcon title="Edit Template" /></span> : ''}
                        <span className="template-action-button" onClick={() => this.props.clone(row.id)}> <CopyIcon title="Clone Template" /></span>
                        {isEditable ? <span className="template-action-button" onClick={() => this.openDeleteConfirmationModal(row, index)}> <TrashIcon title="Delete Template" /></span> : ''}
                    </div>
                </td>
            )
        }
        else if (id === 'agreementEdit/delete') {
            return (
                <td className={`row-${index}`} key={key} style={{ margin: "0px" }} >
                    <div style={{ width: "149px" }}>
                        <span className="template-action-button" onClick={() => this.props.editRow(row.id)}> <EditIcon title="Edit Agreement" /></span>
                        <span className="template-action-button" onClick={() => this.props.clone(row.id)}> <CopyIcon title="Clone Agreement" /></span>
                        <span className="template-action-button" onClick={() => this.openDeleteConfirmationModal(row, index)}> <TrashIcon title="Delete Agreement" /></span>
                        {/* <span className="template-action-button" onClick={() => this.props.deleteRow(row.id)}> <TrashIcon title="Delete Agreement" /></span> */}
                    </div>
                </td>
            )
        }
        else if (id === 'commentDescription') {
            return (
                <td className="comment-description" key={key}>
                    <p>
                        {row.description.length > 250 ?
                            `${row.description.substring(0, 250)}...` : row.description}
                    </p>
                </td>
            )
        }
        else if (id === 'commenter') {
            return (
                <td className="comment-commenter" key={key}>
                    {
                        row.commenter.charAt(0).toUpperCase() + row.commenter.slice(1)}
                </td>
            )
        }
        else if (id === 'templateUser') {
            return (
                <td className="template-user" key={key}>
                    {row.templateUser == 'developer' ? 'Admin' : row.templateUser.charAt(0).toUpperCase() + row.templateUser.slice(1)
                    }
                </td>
            )
        }
        else if (id === 'status') {
            return (
                <td key={key}>
                    <div className="comment-status-col">
                        {
                            row.status == 'deficiency' ? <span > <DeficiencyIcon className='template-action-button' /> Deficiency </span> :
                                row.status == 'information' ? <span ><InformationIcon className='template-action-button' />  Information </span> :
                                    row.status == 'notinspected' ? <span > <NotInspectedIcon className='template-action-button' /> Not Inspected </span> : <></>
                        }
                    </div>
                </td>
            )
        }
        else if (id === 'DiscountActions') {
            return (
                <td className={`row-${row.id}`} key={key} style={{ margin: "0px" }} >
                    <div style={{ width: "149px" }}>
                        {isEditable = true}
                        {isEditable ? <span className="template-action-button" onClick={() => this.props.editRow(row, "edit")}> <EditIcon title="Edit Discount" /></span> : ''}
                        <span className="template-action-button" onClick={() => this.props.clone(row, "clone")}> <CopyIcon title="Clone Discount" /></span>
                        {isEditable ? <span className="template-action-button" onClick={() => this.openDeleteConfirmationModal(row, index)}> <TrashIcon title="Delete Discount" /></span> : ''}
                    </div>
                </td>
            )
        }
        else if (id === 'commentEdit/delete') {
            return (
                <td className={`row-${row.id}`} key={key} style={{ margin: "0px" }} >
                    <div style={{ width: "149px" }}>
                        {isEditable = (currentUser.userType == 'inspector' && row.commenter == 'admin') ? false : true}
                        {isEditable ? <span className="template-action-button" onClick={() => this.props.editRow(row, "edit")}> <EditIcon title="Edit Comment" /></span> : ''}
                        <span className="template-action-button" onClick={() => this.props.clone(row, "clone")}> <CopyIcon title="Clone Comment" /></span>
                        {isEditable ? <span className="template-action-button" onClick={() => this.openDeleteConfirmationModal(row, index)}> <TrashIcon title="Delete Comment" /></span> : ''}
                    </div>
                </td>
            )
        }
        else if (id === 'serviceEdit/delete') {
            return (
                <td className={`row-${row.id}`} key={key} style={{ margin: "0px" }} >
                    <div style={{ width: "149px" }}>
                        {isEditable = true}
                        {isEditable ? <span className="template-action-button" onClick={() => this.props.editRow(row, "edit")}> <EditIcon title="Edit Service" /></span> : ''}
                        <span className="template-action-button" onClick={() => this.props.clone(row, "clone")}> <CopyIcon title="Clone Service" /></span>
                        {isEditable ? <span className="template-action-button" onClick={() => this.openDeleteConfirmationModal(row, index)}> <TrashIcon title="Delete Service" /></span> : ''}
                    </div>
                </td>
            )
        }
        else if (id === 'modifierActions') {
            return (
                <td className={`row-${row.id}`} key={key} style={{ margin: "0px" }} >
                    <div style={{ width: "149px" }}>
                        {isEditable = true}
                        {isEditable ? <span className="template-action-button" onClick={() => this.props.editRow(row, "edit")}> <EditIcon title="Edit Modifier" /></span> : ''}
                        <span className="template-action-button" onClick={() => this.props.clone(row, "clone")}> <CopyIcon title="Clone Modifier" /></span>
                        {isEditable ? <span className="template-action-button" onClick={() => this.openDeleteConfirmationModal(row, index)}> <TrashIcon title="Delete Modifier" /></span> : ''}
                    </div>
                </td>
            )
        }
        else if (id === 'payment/sr') {
            return (
                <td className={`row-${row.id}`} key={key}>
                    {row.sr}
                </td>
            )
        }
        else if (id === 'title') {
            return (
                <td className={`row-${row.id}`} key={key}>
                    {row.title}
                </td>
            )
        }
        else if (id === 'description') {
            return (
                <td className={`row-${row.id}`} key={key}>
                    {row.description}
                </td>
            )
        }
        else if (id === 'payment/date') {
            return (
                <td className={`row-${row.id}`} key={key}>
                    {row.createdAt}
                </td>
            )
        }
        else if (id === 'payment/customer') {
            return (
                <td className={`row-${row.id}`} key={key}>
                    {row.customer}
                </td>
            )
        }
        else if (id === 'payment/plan') {
            return (
                <td className={`row-${row.id}`} key={key}>
                    {row.plan}
                </td>
            )
        }
        else if (id === 'payment/amount') {
            return (
                <td className={`row-${row.id}`} key={key}>
                    {row.amount ? `$${parseFloat(row.amount).toFixed(2)}` : "-"}
                </td>
            )
        }
        else if (id === 'firstname') {
            return (
                <td className={`row-${row.id}`} key={key}>
                    {row.firstname}
                </td>
            )
        }
        else if (id === 'lastname') {
            return (
                <td className={`row-${row.id}`} key={key}>
                    {row.lastname}
                </td>
            )
        }
        else if (id === 'email') {
            return (
                <td className={`row-${row.id}`} key={key}>
                    {row.email}
                </td>
            )
        }
        else if (id === 'phone') {
            return (
                <td className={`row-${row.id}`} key={key}>
                    {row.phone}
                </td>
            )
        }
        else if (id === 'userType') {
            return (
                <td className={`row-${row.id}`} key={key}>
                    {row.userType}
                </td>
            )
        }
        else if (id === 'createdAt') {
            return (
                <td className={`row-${row.id}`} key={key}>
                    {moment(row.createdAt).format('DD MMM YYYY, hh:mm a')}
                </td>
            )
        }
        else if (id === 'updatedAt') {
            return (
                <td className={`row-${row.id}`} key={key}>
                    {moment(row.updatedAt).format('DD MMM YYYY, hh:mm a')}
                </td>
            )
        }
        else if (id === 'user/signup') {
            return (
                <td className={`row-${row.id}`} key={key}>
                    {row.type}
                </td>
            )
        }
        else if (id === 'user/password') {
            return (
                <td className={`row-${row.id}`} key={key}>
                    <Button
                        className="button-instance"
                        label="Set password"
                        size="small"
                        state="pressed"
                        style="outlined"
                        type="primary"
                        showLeftIcon={true}
                        showRightIcon={false}
                        leftIcon={<EditIcon title="Change Password" />}
                    />
                </td>
            )
        }
        else if (id === 'reportLimits') {
            return (
                <td className={`row-${row.id}`} key={key}>
                    {row.reportLimits}
                </td>
            )
        }
        else if (id === 'planMode') {
            return (
                <td className={`row-${row.id}`} key={key}>
                    {row.planMode}
                </td>
            )
        }
        else if (id === 'user/action') {
            return (
                <td className={`row-${row.id}`} key={key}>
                    <div style={{ width: "110px" }}>
                        <span className="template-action-button" onClick={() => this.props.openUserEditModal(row)}>  <EditIcon title="Edit User" /> </span>
                        <span className="template-action-button" onClick={() => this.props.block(row)}> {row.userStatus == 0 ? <BlockUserIcon title="Block User" /> : <UnBlockUserIcon title="Unblock User" />}</span>
                    </div>
                </td>
            )
        }
        else if (id === "viewReport/sendReport") {
            return (
                <td className={`row-${row.id}`} key={key}>
                    <div className="user-inspection-action">
                        <span className="template-action-button" onClick={() => {
                            handleViewReport(row);
                        }}>
                            <EyeIcon title="View report" />
                        </span>
                        <ReportSent row={row} contacts={this.props.contacts} />
                    </div>
                </td>
            )
        } else if (id === "viewReport-clientName") {
            return (
                <td className={`row-${row.id}`} key={key}>
                    {handleClientName(row, this.props.contacts)}

                </td>
            )
        } else if (id === "inspection-status") {
            return (
                <td className={`row-${row.id}`} key={key}>
                    {row.status}
                </td>
            )
        } else if (id === "hasSubmittedAgreement") {
            return (
                <td className={`row-${row.id}`} key={key}>
                    {row.hasSubmittedAgreement ? <RightIcon /> : <WrongIcon />}
                </td>
            )
        } else if (id === "hasPaidInvoice") {
            return (
                <td className={`row-${row.id}`} key={key}>
                    {row.hasPaidInvoice ? <RightIcon /> : <WrongIcon />}
                </td>
            )
        } else if (id === "hasSubmittedReport") {
            return (
                <td className={`row-${row.id}`} key={key}>
                    {row.hasSubmittedReport ? <RightIcon /> : <WrongIcon />}
                </td>
            )
        } else if (id === "viewReport-inspectionAddress") {
            return (
                <td className={`row-${row.id}`} key={key}>
                    {row.address.street} - {row.address.city} - {row.address.state}
                </td>
            )

        } else if (id === "viewReport-inspectionCreatedAt") {
            return (
                <td className={`row-${row.id}`} key={key}>
                    {moment(row.startDate).utc().format('DD MMM YYYY, hh:mm a')}
                </td>
            )
        }

        // else if (id === 'viewReport-clientName') {
        //     return (
        //         <td className={`row-${row.id}`} key={key}>
        //             <div style={{width:"70px"}}>
        //                 <span className="template-action-button" onClick={()=> this.props.makeAdmin(row)}> <MakeAdminIcon  /></span>
        //                 <span className="template-action-button" onClick={()=> this.props.block(row)}> <BlockUserIcon  /></span>
        //             </div>
        //         </td>
        //     )
        // }
        // else if (id === 'viewReport-inspectionAddress') {
        //     return (
        //         <td className={`row-${row.id}`} key={key}>
        //             <div style={{width:"70px"}}>
        //                 <span className="template-action-button" onClick={()=> this.props.makeAdmin(row)}> <MakeAdminIcon  /></span>
        //                 <span className="template-action-button" onClick={()=> this.props.block(row)}> <BlockUserIcon  /></span>
        //             </div>
        //         </td>
        //     )
        // }
        // else if (id === 'viewReport-inspectionCreatedAt') {
        //     return (
        //         <td className={`row-${row.id}`} key={key}>
        //             <div style={{width:"70px"}}>
        //                 <span className="template-action-button" onClick={()=> this.props.makeAdmin(row)}> <MakeAdminIcon  /></span>
        //                 <span className="template-action-button" onClick={()=> this.props.block(row)}> <BlockUserIcon  /></span>
        //             </div>
        //         </td>
        //     )
        // }
        // else if (id === 'viewReport/sendReport') {
        //     return (
        //         <td className={`row-${row.id}`} key={key}>
        //             <div style={{width:"70px"}}>
        //                 <span className="template-action-button" onClick={()=> this.props.makeAdmin(row)}> <MakeAdminIcon  /></span>
        //                 <span className="template-action-button" onClick={()=> this.props.block(row)}> <BlockUserIcon  /></span>
        //             </div>
        //         </td>
        //     )
        // }


        // else if (id === 'admin/active') {
        //     return (
        //         <TableCell className={classes.tableCell} key={key}>
        //             <Tooltip
        //                 id="tooltip-top"
        //                 title={row.userType == "admin" ? "Make Inspector" : "Make Admin"}
        //                 placement="top"
        //             >
        //                 <IconButton onClick={() => this.props.setAdminRights(row || false, i)} aria-label="Make Admin" className={classes.iconButton}>
        //                     {row.userType == "admin" ? 
        //                         <SupervisorAccount className={classes.icon + ' ' + classes.clone} />
        //                         :
        //                         <Person className={classes.icon + ' ' + classes.clone} />
        //                     }
        //                 </IconButton>
        //             </Tooltip>
        //             <Tooltip
        //                 id="tooltip-top"
        //                 title={row.userStatus == 0 ? "Block" : "Active"}
        //                 placement="top"
        //             >
        //                 <IconButton onClick={() => this.props.setUserStatus(row || false)} aria-label={row.userStatus == 0 ? "Block" : "Active"} className={classes.iconButton}>
        //                     { row.userStatus == 0 ?
        //                         <Block className={classes.icon + ' ' + classes.edit} />
        //                         :
        //                         <CheckCircleOutline className={classes.icon + ' ' + classes.edit} />
        //                     }
        //                 </IconButton>
        //             </Tooltip>
        //         </TableCell>
        //     )
        // }
        // else if (id === 'update') {
        //     const syncing = _.includes(this.props.syncingCourses, row.id);
        //     return (
        //         <TableCell className={classes.tableCell} key={key}>
        //             <Tooltip
        //                 id="tooltip-top"
        //                 title="Update"
        //                 placement="top"
        //             >
        //             {syncing 
        //                 ?
        //                 <IconButton disabled={true} aria-label="Update" className={classes.iconButton + ' rotate'}>
        //                     <Sync className={classes.icon + ' ' + classes.update} />
        //                 </IconButton>
        //                 :
        //                 <IconButton onClick={() => this.props.update(row.id)} aria-label="Update" className={classes.iconButton}>
        //                     <Sync className={classes.icon + ' ' + classes.update} />
        //                 </IconButton>
        //             }
        //             </Tooltip>
        //         </TableCell>
        //     )
        // } else if (id === 'time') {
        //     return(
        //         <TableCell className={classes.tableCell} key={key}>
        //             {moment(row[id]).format('DD MMM YYYY, hh:mm a')}
        //         </TableCell>
        //     )
        // } else if(id === 'sortNo'){
        //     return (
        //         <TableCell className={classes.tableCell} style={additionalStyle} key={key}>
        //             {this.props.tableData[0].sortNo != row[id] ? 
        //             <IconButton onClick={() => this.props.moveUp(row.id)} aria-label="Update" className={classes.iconButton}>
        //                 <ExpandLess className={classes.icon + ' ' + classes.update} />
        //             </IconButton>
        //             : 
        //             <IconButton aria-label="Update" className={classes.iconButton+" "+classes.disabled}>
        //                 <ExpandLess className={classes.icon + ' ' + classes.update} />
        //             </IconButton>
        //             }
        //             {_.isObject(row[id]) 
        //                 ? row[id].name 
        //                     ? row[id].name 
        //                     : row[id].answer && row[id].answer
        //                 : row[id]}
        //                 {this.props.tableData[this.props.tableData.length-1].sortNo != row[id] ? 
        //                 <IconButton onClick={() => this.props.moveDown(row.id)} aria-label="Update" className={classes.iconButton}>
        //                     <ExpandMore className={classes.icon + ' ' + classes.update} />
        //                 </IconButton>
        //                  :
        //                  <IconButton aria-label="Update" className={classes.iconButton+" "+classes.disabled}>
        //                     <ExpandMore className={classes.icon + ' ' + classes.update} />
        //                 </IconButton>
        //                  }
        //         </TableCell>
        //     )
        // } else if( id === "adminPublished"){
        //     return (
        //         <TableCell className={classes.tableCell} key={key}>
        //             <Switch
        //             checked={row[id]}
        //             onChange={() => this.props.handleStatus(row.id, i)}
        //             name={id}
        //             color="primary"
        //             />
        //         </TableCell>
        //     )
        // } else if( id === "viewReport/sendReport"){
        //     return (
        //         <TableCell className={classes.tableCell} key={key}>
        //         <div className={classes.displayAction}>
        //             <IconButton color="primary" onClick={() => {
        //                 handleViewReport(row);
        //             }}>
        //                 <Visibility />
        //             </IconButton>
        //             <ReportSent row={row} contacts={this.props.contacts}/>
        //         </div>
        //         </TableCell>
        //     )
        // } else if( id === "viewReport-clientName"){
        //     return (
        //         <TableCell className={[classes.tableCell, classes.userInspectionName].join(" ")} key={key}>
        //             {handleClientName(row, this.props.contacts, classes)}

        //         </TableCell>
        //     )
        // } else if( id === "viewReport-inspectionAddress"){
        //     return (
        //         <TableCell className={[classes.tableCell, classes.userInspectionAddress].join(" ")}  key={key}>
        //        {row.address.street} - {row.address.city} - {row.address.state}
        //         </TableCell>
        //     )

        // } else if( id === "viewReport-inspectionCreatedAt"){
        //     return (
        //         <TableCell className={[classes.tableCell, classes.userInspectionDate].join(" ")} key={key}>
        //             {moment(row.createdAt).format('DD MMM YYYY, hh:mm a')}
        //         </TableCell>
        //     )    
        // } else {
        //     var additionalStyle = {};
        //     if (id === 'accountType') {
        //         additionalStyle['text-transform'] = 'capitalize';
        //     }
        //     return (
        //         <TableCell className={classes.tableCell} style={additionalStyle} key={key}>
        //             {_.isObject(row[id]) 
        //                 ? row[id].name 
        //                     ? row[id].name 
        //                     : row[id].answer && row[id].answer
        //                 : row[id]}
        //         </TableCell>
        //     )
        // }
    }

    deleteConfirmationModalClose = (action, object) => {
        this.state.deleteConfirmationModal = {
            show: false,
            title: "",
            body: "",
            type: "",
        };
        this.setState(this.state);
        if (action === "Yes") {
            this.props.remove(object.record.id, object.index)
            //   this.props.deleteSection(this.state.index, this.state.type);
        }
    }

    openDeleteConfirmationModal = (record, index) => {
        this.state.deleteConfirmationModal = {
            show: true,
            title: `Delete record`,
            body: `Are you sure, you want to remove this record?`,
            type: 'danger',
            record: record,
            index: index
        }
        if (record.hasOwnProperty("templateName")) {
            this.state.deleteConfirmationModal.title = `Delete Template`;
            this.state.deleteConfirmationModal.body = ``;
        }
        if (record.hasOwnProperty("commenter")) {
            this.state.deleteConfirmationModal.title = `Delete Comment`;
            this.state.deleteConfirmationModal.body = '';
        }
        if (record.hasOwnProperty("agreementType")) {
            this.state.deleteConfirmationModal.title = `Delete Agreement`;
            this.state.deleteConfirmationModal.body = '';
        }
        this.setState(this.state);
    }

    render() {
        const { tableHead, tableData, tableHeaderColor, type, page, totalNumber, currentUser } = this.props;
        const { order, orderBy, items } = this.state;
        return (
            <div >
                <div className="table-responsive">
                    <InfiniteScroll
                        dataLength={items.length}
                        next={this.infiniteScroll}
                        hasMore={items.length < totalNumber}
                        loader={<Spinner className="table-loading" />}
                        scrollableTarget="scrollableDiv"
                    >
                        <Table className="table table-striped">
                            <EnhancedTableHeadWrapped
                                order={order}
                                orderBy={orderBy}
                                onRequestSort={this.handleRequestSort}
                                headRow={tableHead}
                            // tableHeaderColor={tableHeaderColor}
                            />
                            {this.props.inspectorTemplatesDraggeble ? 
                             <DragDropContext onDragEnd={this.onDragEnd}>
                                <Droppable droppableId="droppable">
                                    {provided => (
                                        <tbody {...provided.droppableProps} ref={provided.innerRef}>
                                            {stableSort(items, order, orderBy, this.props.sorted).map((row, index) => {
                                                if(row.templateUser === "inspector"){
                                                    return (
                                                        <Draggable
                                                            key={row.id}
                                                            draggableId={row.id}
                                                            index={index}
                                                        >
                                                            {(provided, snapshot) => (
                                                                <tr
                                                                    ref={provided.innerRef}
                                                                    {...provided.draggableProps}
                                                                    {...provided.dragHandleProps}
                                                                    style={getItemStyle(snapshot.isDragging, provided.draggableProps.style)}
                                                                >
                                                                    {_.map(tableHead, (heading, key) => this.renderTableCell(heading.id, key, type, page, this.state.rowsPerPage, index + 1, row, currentUser))}
                                                                </tr>
                                                            )}
                                                        </Draggable>
                                                    )
                                                } else {
                                                    return (
                                                        <tr key={index}>
                                                            {_.map(tableHead, (heading, key) => this.renderTableCell(heading.id, key, type, page, this.state.rowsPerPage, index + 1, row, currentUser))}
                                                        </tr>
                                                    )
                                                }
                                            })}
                                            {provided.placeholder}
                                        </tbody>
                                    )}
                                </Droppable>
                            </DragDropContext>
                            :
                            !this.props.disableDrag ?
                                <DragDropContext onDragEnd={this.onDragEnd}>
                                    <Droppable droppableId="droppable">
                                        {provided => (
                                            <tbody {...provided.droppableProps} ref={provided.innerRef}>
                                                {stableSort(items, order, orderBy, this.props.sorted).map((row, index) => (
                                                    <Draggable
                                                        key={row.id}
                                                        draggableId={row.id}
                                                        index={index}
                                                    >
                                                        {(provided, snapshot) => (
                                                            <tr
                                                                ref={provided.innerRef}
                                                                {...provided.draggableProps}
                                                                {...provided.dragHandleProps}
                                                                style={getItemStyle(snapshot.isDragging, provided.draggableProps.style)}
                                                            >
                                                                {_.map(tableHead, (heading, key) => this.renderTableCell(heading.id, key, type, page, this.state.rowsPerPage, index + 1, row, currentUser))}
                                                            </tr>
                                                        )}
                                                    </Draggable>
                                                ))}
                                                {provided.placeholder}
                                            </tbody>
                                        )}
                                    </Droppable>
                                </DragDropContext>
                                : <tbody>
                                    {
                                        stableSort(items, order, orderBy, this.props.sorted).map((row, index) => {
                                            return (
                                                <tr key={index}>
                                                    {_.map(tableHead, (heading, key) => this.renderTableCell(heading.id, key, type, page, this.state.rowsPerPage, index + 1, row, currentUser))}
                                                </tr>
                                            );
                                        })}
                                </tbody>
                            }
                        </Table>
                        <DeleteConfirmationModal modalSettings={this.state.deleteConfirmationModal} close={this.deleteConfirmationModalClose} />
                    </InfiniteScroll>
                </div>
            </div>
        );
    }
}

export default (CustomTable);

class EnhancedTableHead extends React.Component {
    createSortHandler = (property) => event => {
        this.props.onRequestSort(event, property);
    };

    render() {
        const { order, orderBy, headRow } = this.props;

        return (
            <thead className='TableHeader'>
                <tr className="">
                    {_.map(headRow, row => (
                        <th
                            key={row.id}
                            className="scope-templates-table-heading"
                            style={row.width ? { width: row.width } : { width: "50px" }}
                        >
                            <span>
                                <label
                                    className={row.sortable ? "cursor" : ""}
                                    direction={order}
                                    onClick={row.sortable ? this.createSortHandler(row.id) : null}
                                    disabled={!row.sortable} >
                                    {row.beforeIcon}
                                    {row.label}
                                    {row.afterIcon}
                                </label>
                            </span>
                        </th>
                    ))}
                </tr>
            </thead>
        );
    }
}

EnhancedTableHead.propTypes = {
    onRequestSort: PropTypes.func.isRequired,
    order: PropTypes.string.isRequired,
    orderBy: PropTypes.string.isRequired,
    headRow: PropTypes.array.isRequired
};

const EnhancedTableHeadWrapped = (
    EnhancedTableHead
);

function stableSort(array, order, orderBy, sortedArray) {
    if(sortedArray){
        return array;
    }
    const sorted = _.orderBy(array, [obj => _.isString(obj[orderBy]) ? obj[orderBy].toLowerCase() : obj[orderBy]], order)
    return sorted;
}

const sortNumberSorting = (items) => {
    return items.map((item, index) => {
        item.sortNo = index + 1;
        return item;
    })
}
const handleViewReport = (row) => {
    window.open(`${Constants.REVIEW_BASE}${row.id}`, '_blank', 'noreferrer');
}

const handleClientName = (row, client) => {
    var name = <span className='not-specified' >Not Specified </span>;
    client.map(item => {
        if (row.client == item.id) {
            name = <span> {item.firstname + ' ' + item.lastname} </span>;
        }
    });
    return name;
}