import React from "react";
import Form from 'react-bootstrap/Form';
import Modal from 'react-bootstrap/Modal';
import { Button as Btn } from "../../components/button/button";
import Nav from 'react-bootstrap/Nav';
import { DeficiencyIcon, InformationIcon, NotInspectedIcon, DeficiencyIconWhite, InformationIconWhite, NotInspectedIconWhite } from "../../icons/Icons";
import { CrossIcon } from "../../icons/Icons";
class CommentAddEditModal extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
        modalSettings: {
            show: false,
            description: "",
            status: "",
            action:"",
            id: null
        },
        availableTypes: [{
                value: "deficiency",
                text: "Deficiency",
                icon: <DeficiencyIcon className="comment-action-tab" />,
                iconActive: <DeficiencyIconWhite className="comment-action-tab-active" />
              },
              {
                value: "information",
                text: "Information",
                icon: <InformationIcon className="comment-action-tab" />,
                iconActive: <InformationIconWhite className="comment-action-tab-active"/>
              },
              // {
              //   value: "safety",
              //   text: "Safety",
              // },
              // {
              //   value: "acceptable",
              //   text: "Acceptable",
              // },
              {
                value: "notinspected",
                text: "Not Inspected",
                icon: <NotInspectedIcon className="comment-action-tab"/>,
                iconActive: <NotInspectedIconWhite className="comment-action-tab-active" />
              }]
    }
  }
  componentDidMount = async () => {
    this.state.modalSettings = this.props.modalSettings;
    this.setState( this.state );
  }
  componentWillReceiveProps(nextProps) {
    this.state.modalSettings = nextProps.modalSettings;
    this.setState( this.state );
  }

  onChange = (e, field) => {
    this.state.modalSettings[field] = e.target.value;
    this.setState( this.state );
  }

  onSelect = (e, field) =>{
    this.state.modalSettings[field] =  e;
    this.setState( this.state );
  }
  handleClose = () =>{
    this.state.modalSettings.show = false;
    this.setState( this.state );
  }

  render = () => {
    const {modalSettings} = this.state;
    return (
        <>
        <Modal
            show={modalSettings.show}
            dialogClassName="modal-50w itemEdit modal-scope"
            aria-labelledby="template-item-edit"
            backdrop="static"
            keyboard={false}
            onHide={this.handleClose} 
            >
            <Modal.Header>
            <Modal.Title id="template-item-edit">
                {this.state.action == "edit" ? "Edit Comment" :  this.state.action == "clone" ? "Clone Comment" : "Add Comment"}
                <span onClick={(e) => this.props.close("Cancel", modalSettings.description, modalSettings.status, modalSettings.id, modalSettings.action )}><CrossIcon /></span>
            </Modal.Title>
            </Modal.Header>
            <Modal.Body>
            
            <div className="comment-main-tabs" >
              {this.state.availableTypes.map((status, index) => (
                <div onClick={()=>this.onSelect(status.value, "status")} value={status.value} key={index} className={this.state.modalSettings.status == status.value ? status.value+" active" : status.value }>
                    <div className="comment-tabs-link"> {this.state.modalSettings.status == status.value ? status.iconActive: status.icon} <span className="text"> {status.text}</span></div>
                </div>
              ))}
            </div>
              <br />
            <label>Description </label>
            <Form.Control className="form-control border-radius16"  onChange={(e) => this.onChange(e, "description")} value={this.state.modalSettings.description} as="textarea" rows={20} />
            </Modal.Body>
            <Modal.Footer>
            <Btn className={`template-item-edit`}
                label={`Cancel`}
                showLeftIcon={false}
                showRightIcon={false}
                size="large"
                state="default"
                variant="ghost"
                type="primary"
                clickEvent={(e) => this.props.close("Cancel", modalSettings.description, modalSettings.status, modalSettings.id, modalSettings.action )} />
                <Btn className={`template-item-edit`}
                label={`Save`}
                showLeftIcon={false}
                showRightIcon={false}
                disabled= {!this.state.modalSettings.status || !this.state.modalSettings.description}
                size="large"
                state="default"
                variant="filled"
                type="primary"
                clickEvent={(e) => this.props.close("Save", modalSettings.description, modalSettings.status,  modalSettings.id, modalSettings.action)} />
            </Modal.Footer>
        </Modal>
        </>
    );
  }
}

export default CommentAddEditModal;