/* eslint-disable no-unused-vars */
/* eslint-disable callback-return */
import axios from 'axios';
import _ from 'lodash';
import Constants, { BASE_URL } from '../../config/constants.react';
import {
    setAllTemplates, setAllModifiers, deleteTemplates, setEmailHooks, alertPopupAction, setAllItems, setAllUsers, setUser, setEntries, setPlans, setComments, setUserAllInspections, setContact, sendInspectionReport , setAgreements
 ,setAllCoupons} from '../actions/appAction';
import { setUser as SU } from '../actions/authAction';
import toast from 'react-hot-toast';
import { sessionService } from 'redux-react-session';
import {apiWithCallbacks} from "./../../helpers/apiManager";



export const getAllTemplates = (params, callback = null) => {
    return (dispatch, getState) => {
        let data = {
            method: "GET",
            url: Constants.SERVER_URL + Constants.CALLS.GET_ALL_TEMPLATES,
            params,
            callback
        }
        dispatch(apiWithCallbacks(data, onSuccessgetAllTemplates, onFailuregetAllTemplates));
    }
}
const onSuccessgetAllTemplates = (response, params) => {
    return (dispatch) => {
        // console.log("onSuccessgetAllTemplates", response);
        dispatch(setAllTemplates(response.data.records));
        if(_.isFunction(params.callback)) params.callback(response.data);
    }
}
const onFailuregetAllTemplates = (response, params) => {
    return (dispatch) => {
        // console.log("onFailuregetAllTemplates", response);
        if(response) dispatch(alertPopupAction(true, response, "danger"));
        else dispatch(alertPopupAction(true, "Something went wrong", "danger"));
        if(_.isFunction(params.callback)) params.callback(false);
    }
}

export const getServiceResource = (params, callback = null) => {
    return (dispatch, getState) => {
        let data = {
            method: "GET",
            url: Constants.SERVER_URL + Constants.CALLS.GET_SERVICE_RESOURCES,
            params,
            callback
        }
        dispatch(apiWithCallbacks(data, onSuccessgetServiceResource, onFailuregetServiceResource));
    }
}

const onSuccessgetServiceResource = (response, params) => {
    return (dispatch) => {
        // console.log("onSuccessgetServiceResource", response);
        dispatch(setAllModifiers(response.data));
        if(_.isFunction(params.callback)) params.callback(response.data);
    }
}
const onFailuregetServiceResource = (response, params) => {
    return (dispatch) => {
        // console.log("onFailuregetServiceResource", response);
        if(response) dispatch(alertPopupAction(true, response, "danger"));
        else dispatch(alertPopupAction(true, "Something went wrong", "danger"));
        if(_.isFunction(params.callback)) params.callback(false);
    }
}

export const getAllModifiers = (params, callback = null) => {
    return (dispatch, getState) => {
        let data = {
            method: "GET",
            url: Constants.SERVER_URL + Constants.CALLS.GET_ALL_MODIFIERS,
            params,
            callback
        }
        dispatch(apiWithCallbacks(data, onSuccessgetAllModifiers, onFailuregetAllModifiers));
    }
}

const onSuccessgetAllModifiers = (response, params) => {
    return (dispatch) => {
        // console.log("onSuccessgetAllModifiers", response);
        dispatch(setAllModifiers(response.data));
        if(_.isFunction(params.callback)) params.callback(response.data);
    }
}
const onFailuregetAllModifiers = (response, params) => {
    return (dispatch) => {
        // console.log("onFailuregetAllModifiers", response);
        if(response) dispatch(alertPopupAction(true, response, "danger"));
        else dispatch(alertPopupAction(true, "Something went wrong", "danger"));
        if(_.isFunction(params.callback)) params.callback(false);
    }
}

export const getAllCoupons = (params, callback = null) => {
    return (dispatch, getState) => {
        let data = {
            method: "GET",
            url: `${Constants.SERVER_URL}${Constants.CALLS.GET_ALL_COUPONS}`,
            params,
            callback
        }
        dispatch(apiWithCallbacks(data, onSuccessgetAllCoupons, onFailuregetAllCoupons));
    }
}

const onSuccessgetAllCoupons = (response, params) => {
    return (dispatch) => {
        console.log("onSuccessgetAllCoupons", response.data);
        dispatch(setAllCoupons(response.data));
        if(_.isFunction(params.callback)) params.callback(response.data);
    }
}
const onFailuregetAllCoupons = (response, params) => {
    return (dispatch) => {
        console.log("onFailuregetAllCoupons", response);
        if(response) dispatch(alertPopupAction(true, response, "danger"));
        else dispatch(alertPopupAction(true, "Something went wrong", "danger"));
        if(_.isFunction(params.callback)) params.callback(false);
    }
}

export const getAllServices = (id, callback = null) => {
    return (dispatch, getState) => {
        let data = {
            method: "GET",
            url: `${Constants.SERVER_URL}${Constants.CALLS.GET_ALL_SERVICES}`,
            callback
        }
        dispatch(apiWithCallbacks(data, onSuccessgetAllServices, onFailuregetAllServices));
    }
}

const onSuccessgetAllServices = (response, params) => {
    return (dispatch) => {
        console.log("onSuccessgetAllServices", response);
        dispatch(setAllModifiers(response.data));
        if(_.isFunction(params.callback)) params.callback(response.data);
    }
}
const onFailuregetAllServices = (response, params) => {
    return (dispatch) => {
        console.log("onFailuregetAllServices", response);
        if(response) dispatch(alertPopupAction(true, response, "danger"));
        else dispatch(alertPopupAction(true, "Something went wrong", "danger"));
        if(_.isFunction(params.callback)) params.callback(false);
    }
}

export const getTemplateByID = (params, callback = null) => {
    return (dispatch, getState) => {
        let data = {
            method: "GET",
            url: `${Constants.SERVER_URL}${Constants.CALLS.GET_TEMPLATE_BY_ID}/${params}`,
            data:{},
            callback
        }
        dispatch(apiWithCallbacks(data, onSuccessgetTemplateByID, onFailuregetTemplateByID));
    }
}
const onSuccessgetTemplateByID = (response, params) => {
    return (dispatch) => {
        // console.log("onSuccessgetTemplateByID", response);
        // dispatch(setAllTemplates(response.data.records));
        if(_.isFunction(params.callback)) params.callback(response.data);
    }
}
const onFailuregetTemplateByID = (response, params) => {
    return (dispatch) => {
        // console.log("onFailuregetTemplateByID", response);
        if(response) dispatch(alertPopupAction(true, response, "danger"));
        else dispatch(alertPopupAction(true, "Something went wrong", "danger"));
        if(_.isFunction(params.callback)) params.callback(false);
    }
}

export const updateMultTemplates = (params, callback = null) => {
    return (dispatch, getState) => {
        let data = {
            // method: "post",
            url: Constants.SERVER_URL + Constants.CALLS.UPDATE_MULT_TEMPLATES,
            data: params,
            callback
        }
        dispatch(apiWithCallbacks(data, onSuccessupdateMultTemplates, onFailureupdateMultTemplates));
    }
}
const onSuccessupdateMultTemplates = (response, params) => {
    return (dispatch) => {
        // console.log("onSuccessupdateMultTemplates", response);
        deleteTemplates();
        dispatch(setAllTemplates(response.data));
        if(_.isFunction(params.callback)) params.callback(response.data);
    }
}
const onFailureupdateMultTemplates = (response, params) => {
    return (dispatch) => {
        // console.log("onFailureupdateMultTemplates", response);
        if(response) dispatch(alertPopupAction(true, response, "danger"));
        else dispatch(alertPopupAction(true, "Something went wrong", "danger"));
        if(_.isFunction(params.callback)) params.callback(false);
    }
}


export const updateMultipleCoupons = (params, callback = null) => {
    return (dispatch, getState) => {
        let data = {
            method: "POST",
            url: Constants.SERVER_URL + Constants.CALLS.UPDATE_MULT_COUPONS,
            data: params,
            callback
        }
        dispatch(apiWithCallbacks(data, onSuccessupdateMultipleCoupons, onFailureupdateMultipleCoupons));
    }
}
const onSuccessupdateMultipleCoupons = (response, params) => {
    return (dispatch) => {
        // console.log("onSuccessupdateMultipleCoupons", response);
        deleteTemplates();
        dispatch(setAllTemplates(response.data));
        if(_.isFunction(params.callback)) params.callback(response.data);
    }
}
const onFailureupdateMultipleCoupons = (response, params) => {
    return (dispatch) => {
        // console.log("onFailureupdateMultipleCoupons", response);
        if(response) dispatch(alertPopupAction(true, response, "danger"));
        else dispatch(alertPopupAction(true, "Something went wrong", "danger"));
        if(_.isFunction(params.callback)) params.callback(false);
    }
}

export const updateMultipleModifiers = (params, callback = null) => {
    return (dispatch, getState) => {
        let data = {
            method: "POST",
            url: Constants.SERVER_URL + Constants.CALLS.UPDATE_MULT_MODIFIERS,
            data: params,
            callback
        }
        dispatch(apiWithCallbacks(data, onSuccessupdateMultipleModifiers, onFailureupdateMultipleModifiers));
    }
}
const onSuccessupdateMultipleModifiers = (response, params) => {
    return (dispatch) => {
        // console.log("onSuccessupdateMultipleModifiers", response);
        deleteTemplates();
        dispatch(setAllTemplates(response.data));
        if(_.isFunction(params.callback)) params.callback(response.data);
    }
}
const onFailureupdateMultipleModifiers = (response, params) => {
    return (dispatch) => {
        // console.log("onFailureupdateMultipleModifiers", response);
        if(response) dispatch(alertPopupAction(true, response, "danger"));
        else dispatch(alertPopupAction(true, "Something went wrong", "danger"));
        if(_.isFunction(params.callback)) params.callback(false);
    }
}

export const updateMultipleServices = (params, callback = null) => {
    return (dispatch, getState) => {
        let data = {
            method: "POST",
            url: Constants.SERVER_URL + Constants.CALLS.UPDATE_MULT_SERVICES,
            data: params,
            callback
        }
        dispatch(apiWithCallbacks(data, onSuccessupdateMultipleServices, onFailureupdateMultipleServices));
    }
}
const onSuccessupdateMultipleServices = (response, params) => {
    return (dispatch) => {
        // console.log("onSuccessupdateMultipleServices", response);
        deleteTemplates();
        dispatch(setAllTemplates(response.data));
        if(_.isFunction(params.callback)) params.callback(response.data);
    }
}
const onFailureupdateMultipleServices = (response, params) => {
    return (dispatch) => {
        // console.log("onFailureupdateMultipleServices", response);
        if(response) dispatch(alertPopupAction(true, response, "danger"));
        else dispatch(alertPopupAction(true, "Something went wrong", "danger"));
        if(_.isFunction(params.callback)) params.callback(false);
    }
}

export const updateTemplate = (params, callback = null) => {
    return (dispatch, getState) => {
        let data = {
            // method: "post",
            url: Constants.SERVER_URL + Constants.CALLS.CREATE_OR_UPDATE_TEMPLATE,
            data: params,
            callback
        }
        dispatch(apiWithCallbacks(data, onSuccessupdateTemplate, onFailureupdateTemplate));
    }
}
const onSuccessupdateTemplate = (response, params) => {
    return (dispatch) => {
        // console.log("onSuccessupdateTemplate", response);
        dispatch(alertPopupAction(true, "Template updated Successfully", "success"));
        if(_.isFunction(params.callback)) params.callback(response.data);
    }
}
const onFailureupdateTemplate = (response, params) => {
    return (dispatch) => {
        // console.log("onFailureupdateTemplate", response);
        if(response) dispatch(alertPopupAction(true, response, "danger"));
        else dispatch(alertPopupAction(true, "Something went wrong", "danger"));
        if(_.isFunction(params.callback)) params.callback({status:false, message:response});
    }
}

export const destroyTemplate = (params, callback = null) => {
    return (dispatch, getState) => {
        let data = {
            // method: "post",
            url: Constants.SERVER_URL + Constants.CALLS.DELETE_TEMPLATE,
            data: params,
            callback
        }
        dispatch(apiWithCallbacks(data, onSuccessdestroyTemplate, onFailuredestroyTemplate));
    }
}
const onSuccessdestroyTemplate = (response, params) => {
    return (dispatch) => {
        // console.log("onSuccessdestroyTemplate", response);
        // dispatch(alertPopupAction(true, response.data.msg, "success"));
        // dispatch(alertPopupAction(true, "Template updated Successfully", "success"));
        if(_.isFunction(params.callback)) params.callback(response);
    }
}
const onFailuredestroyTemplate = (response, params) => {
    return (dispatch) => {
        // console.log("onFailuredestroyTemplate", response);
        // if(response) dispatch(alertPopupAction(true, response, "danger"));
        // else dispatch(alertPopupAction(true, "Something went wrong", "danger"));
        if(_.isFunction(params.callback)) params.callback(response);
    }
}

export const getItems = (params, callback = null) => {
    return (dispatch, getState) => {
        let data = {
            method: "GET",
            url: `${Constants.SERVER_URL}${Constants.CALLS.GET_ITEMS}`,
            params,
            callback
        }
        dispatch(apiWithCallbacks(data, onSuccessgetItems, onFailuregetItems));
    }
}
const onSuccessgetItems = (response, params) => {
    return (dispatch) => {
        // console.log("onSuccessgetItems", response);
        dispatch(setAllItems(response.data.records));
        // dispatch(alertPopupAction(true, response.data.msg, "success"));
        // dispatch(alertPopupAction(true, "Template updated Successfully", "success"));
        if(_.isFunction(params.callback)) params.callback(response.data);
    }
}
const onFailuregetItems = (response, params) => {
    return (dispatch) => {
        // console.log("onFailuregetItems", response);
        if(response) dispatch(alertPopupAction(true, response, "danger"));
        else dispatch(alertPopupAction(true, "Something went wrong", "danger"));
        if(_.isFunction(params.callback)) params.callback(false);
    }
}

export const getUsers = (params, callback = null) => {
    return (dispatch, getState) => {
        let data = {
            method: "GET",
            url: `${Constants.SERVER_URL}${Constants.CALLS.GET_USERS}`,
            params,
            callback
        }
        dispatch(apiWithCallbacks(data, onSuccessgetUsers, onFailuregetUsers));
    }
}
const onSuccessgetUsers = (response, params) => {
    return (dispatch) => {
        console.log("onSuccessgetUsers", response);
        dispatch(setAllUsers(response.data.records));
        if(_.isFunction(params.callback)) params.callback(response.data);
    }
}
const onFailuregetUsers = (response, params) => {
    return (dispatch) => {
        console.log("onFailuregetUsers", response);
        if (response) dispatch(alertPopupAction(true, response, "danger"));
        else dispatch(alertPopupAction(true, "Something went wrong", "danger"));
        if (_.isFunction(params.callback)) params.callback(false);
    }
}

export const getCurrentUser = (params, callback = null) => {
    return (dispatch, getState) => {
        let data = {
            method: "GET",
            url: `${Constants.SERVER_URL}${Constants.CALLS.GET_CURRENT_USER}`,
            params,
            callback
        }
        dispatch(apiWithCallbacks(data, onSuccessgetCurrentUser, onFailuregetCurrentUser));
    }
}
const onSuccessgetCurrentUser = (response, params) => {
    return (dispatch) => {
        console.log("onSuccessgetCurrentUser", response);
        dispatch(SU(response.data));
        if(_.isFunction(params.callback)) params.callback(response.data);
    }
}
const onFailuregetCurrentUser = (response, params) => {
    return (dispatch) => {
        console.log("onFailuregetCurrentUser", response);
        if (response) toast.error(response);
        else toast.error("Something went wrong");
        if (_.isFunction(params.callback)) params.callback(false);
    }
}

export const setUserType = (params, callback = null) => {
    return (dispatch, getState) => {
        let data = {
            method: "POST",
            url: `${Constants.SERVER_URL}${Constants.CALLS.SET_USER_TYPE}`,
            data: params,
            callback
        }
        dispatch(apiWithCallbacks(data, onSuccesssetUserType, onFailuresetUserType));
    }
}
const onSuccesssetUserType = (response, params) => {
    return (dispatch) => {
        console.log("onSuccesssetUserType", response);
        dispatch(setUser(response.data));
        if(_.isFunction(params.callback)) params.callback(response.data);
    }
}
const onFailuresetUserType = (response, params) => {
    return (dispatch) => {
        console.log("onFailuresetUserType", response);
        if (response) dispatch(alertPopupAction(true, response, "danger"));
        else dispatch(alertPopupAction(true, "Something went wrong", "danger"));
        if (_.isFunction(params.callback)) params.callback(false);
    }
}

export const setUserStatus = (params, callback = null) => {
    return (dispatch, getState) => {
        let data = {
            method: "POST",
            url: `${Constants.SERVER_URL}${Constants.CALLS.SET_USER_STATUS}`,
            data: params,
            callback
        }
        dispatch(apiWithCallbacks(data, onSuccesssetUserStatus, onFailuresetUserStatus));
    }
}
const onSuccesssetUserStatus = (response, params) => {
    return (dispatch) => {
        // dispatch(setUser(response.data));
        if(_.isFunction(params.callback)) params.callback(response.data);
    }
}
const onFailuresetUserStatus = (response, params) => {
    return (dispatch) => {
        console.log("onFailuresetUserStatus", response);
        if (response) toast.error(response);
        else toast.error("Something went wrong");
        if (_.isFunction(params.callback)) params.callback(false);
    }
}

export const addMedia = (params, callback = null) => {
    console.log("params");
    console.log(params);
    return (dispatch, getState) => {
        let data = {
            method: "POST",
            url: `${Constants.SERVER_URL}${Constants.CALLS.ADD_MEDIA}`,
            data: params,
            callback
        }
        dispatch(apiWithCallbacks(data, onSuccessaddMedia, onFailureaddMedia));
    }
}
const onSuccessaddMedia = (response, params) => {
    return (dispatch) => {
        // dispatch(setUser(response.data));
        if(_.isFunction(params.callback)) params.callback(response.data);
    }
}
const onFailureaddMedia = (response, params) => {
    return (dispatch) => {
        console.log("onFailureaddMedia", response);
        if (response) toast.error(response);
        else toast.error("Something went wrong");
        if (_.isFunction(params.callback)) params.callback(false);
    }
}

export const deleteMedia = (params, callback = null) => {
    console.log("params");
    console.log(params);
    return (dispatch, getState) => {
        let data = {
            method: "POST",
            url: `${Constants.SERVER_URL}${Constants.CALLS.DELETE_MEDIA}`,
            data: params,
            callback
        }
        dispatch(apiWithCallbacks(data, onSuccessdeleteMedia, onFailuredeleteMedia));
    }
}
const onSuccessdeleteMedia = (response, params) => {
    return (dispatch) => {
        // dispatch(setUser(response.data));
        if(_.isFunction(params.callback)) params.callback(response.data);
    }
}
const onFailuredeleteMedia = (response, params) => {
    return (dispatch) => {
        console.log("onFailureaddMedia", response);
        if (response) toast.error(response);
        else toast.error("Something went wrong");
        if (_.isFunction(params.callback)) params.callback(false);
    }
}

export const deleteService = (id , params, callback = null) => {
    return (dispatch, getState) => {
        let data = {
            method: "DELETE",
            url: `${Constants.SERVER_URL}${Constants.CALLS.DELETE_SERVICE}/${id}`,
            data:params,
            callback
        }
        dispatch(apiWithCallbacks(data, onSuccessdeleteService, onFailuredeleteService));
    }
}
const onSuccessdeleteService = (response, params) => {
    return (dispatch) => {
        console.log("onSuccessdeleteService", response);
        // dispatch(alertPopupAction(true, response.data.msg, "danger"));
        if(_.isFunction(params.callback)) params.callback(response);
    }
}
const onFailuredeleteService = (response, params) => {
    return (dispatch) => {
        // if(response) dispatch(alertPopupAction(true, response, "danger"));
        // else dispatch(alertPopupAction(true, "Something went wrong", "danger"));
        if(_.isFunction(params.callback)) params.callback(response);
    }
}

export const deleteModifier = (id , params, callback = null) => {
    return (dispatch, getState) => {
        let data = {
            method: "DELETE",
            url: `${Constants.SERVER_URL}${Constants.CALLS.MODIFIER}/${id}`,
            data:params,
            callback
        }
        dispatch(apiWithCallbacks(data, onSuccessdeleteModifier, onFailuredeleteModifier));
    }
}
const onSuccessdeleteModifier = (response, params) => {
    return (dispatch) => {
        console.log("onSuccessdeleteModifier", response);
        dispatch(alertPopupAction(true, response.data.msg, "danger"));
        if(_.isFunction(params.callback)) params.callback(response);
    }
}
const onFailuredeleteModifier = (response, params) => {
    return (dispatch) => {
        // if(response){
        //     dispatch(alertPopupAction(true, response, "danger"));
        // } else {
        //     dispatch(alertPopupAction(true, "Something went wrong", "danger"));
        // }
        if(_.isFunction(params.callback)) params.callback(response);
    }
}

export const deleteCoupon = (id , params, callback = null) => {
    return (dispatch, getState) => {
        let data = {
            method: "DELETE",
            url: `${Constants.SERVER_URL}${Constants.CALLS.DELETE_COUPON}/${id}`,
            data:params,
            callback
        }
        dispatch(apiWithCallbacks(data, onSuccessdeleteCoupon, onFailuredeleteCoupon));
    }
}
const onSuccessdeleteCoupon = (response, params) => {
    return (dispatch) => {
        console.log("onSuccessdeleteCoupon", response);
        dispatch(alertPopupAction(true, response.data.msg, "danger"));
        if(_.isFunction(params.callback)) params.callback(response.data);
    }
}
const onFailuredeleteCoupon = (response, params) => {
    return (dispatch) => {
        if(response) dispatch(alertPopupAction(true, response, "danger"));
        else dispatch(alertPopupAction(true, "Something went wrong", "danger"));
        if(_.isFunction(params.callback)) params.callback(false);
    }
}

export const updateCoupon = (params, callback = null) => {
    return (dispatch, getState) => {
        let data = {
            method: "POST",
            url: `${Constants.SERVER_URL}${Constants.CALLS.ADD_COUPON}`,
            data:params,
            callback
        }
        dispatch(apiWithCallbacks(data, onSuccessUpdateCoupon, onFailureUpdateCoupon));
    }
}
const onSuccessUpdateCoupon = (response, params) => {
    return (dispatch) => {
        console.log("onSuccessUpdateCoupon", response);
        dispatch(alertPopupAction(true, response.data.msg, "danger"));
        if(_.isFunction(params.callback)) params.callback(response.data);
    }
}
const onFailureUpdateCoupon = (response, params) => {
    return (dispatch) => {
        if(response) dispatch(alertPopupAction(true, response, "danger"));
        else dispatch(alertPopupAction(true, "Something went wrong", "danger"));
        if(_.isFunction(params.callback)) params.callback(false);
    }
}

export const updateModifier = (id , params, callback = null) => {
    return (dispatch, getState) => {
        let data = {
            method: "PUT",
            url: `${Constants.SERVER_URL}${Constants.CALLS.MODIFIER}/${id}`,
            data:params,
            callback
        }
        dispatch(apiWithCallbacks(data, onSuccessUpdateModifier, onFailureUpdateModifier));
    }
}
const onSuccessUpdateModifier = (response, params) => {
    return (dispatch) => {
        console.log("onSuccessdeleteModifier", response);
        dispatch(alertPopupAction(true, response.data.msg, "danger"));
        if(_.isFunction(params.callback)) params.callback(response.data);
    }
}
const onFailureUpdateModifier = (response, params) => {
    return (dispatch) => {
        if(response) dispatch(alertPopupAction(true, response, "danger"));
        else dispatch(alertPopupAction(true, "Something went wrong", "danger"));
        if(_.isFunction(params.callback)) params.callback(false);
    }
}

export const addService = (params, callback = null) => {
    console.log("params");
    console.log(params);
    return (dispatch, getState) => {
        let data = {
            method: "POST",
            url: `${Constants.SERVER_URL}${Constants.CALLS.CREATE_OR_UPDATE_SERVICE}`,
            data: params,
            callback
        }
        dispatch(apiWithCallbacks(data, onSuccessaddService, onFailureaddService));
    }
}
const onSuccessaddService = (response, params) => {
    return (dispatch) => {
        dispatch(setUser(response.data));
        if(_.isFunction(params.callback)) params.callback(response.data);
    }
}
const onFailureaddService = (response, params) => {
    return (dispatch) => {
        console.log("onFailureaddMedia", response);
        if (response) toast.error(response);
        else toast.error("Something went wrong");
        if (_.isFunction(params.callback)) params.callback(false);
    }
}

export const addCoupon = (params, callback = null) => {
    console.log("params");
    console.log(params);
    return (dispatch, getState) => {
        let data = {
            method: "POST",
            url: `${Constants.SERVER_URL}${Constants.CALLS.ADD_COUPON}`,
            data: params,
            callback
        }
        dispatch(apiWithCallbacks(data, onSuccessAddCoupon, onFailureAddCoupon));
    }
}
const onSuccessAddCoupon = (response, params) => {
    return (dispatch) => {
        if(_.isFunction(params.callback)) params.callback(response.data);
    }
}
const onFailureAddCoupon = (response, params) => {
    return (dispatch) => {
        console.log(response);
        console.log("params",params);
        if (response) toast.error(response);
        else toast.error("Something went wrong");
        if (_.isFunction(params.callback)) params.callback(false);
    }
}

export const addModifier = (params, callback = null) => {
    console.log("params");
    console.log(params);
    return (dispatch, getState) => {
        let data = {
            method: "POST",
            url: `${Constants.SERVER_URL}${Constants.CALLS.MODIFIER}`,
            data: params,
            callback
        }
        dispatch(apiWithCallbacks(data, onSuccessAddModifier, onFailureAddModifier));
    }
}
const onSuccessAddModifier = (response, params) => {
    return (dispatch) => {
        // dispatch(setUser(response.data));
        if(_.isFunction(params.callback)) params.callback(response.data);
    }
}
const onFailureAddModifier = (response, params) => {
    return (dispatch) => {
        console.log("onFailureaddMedia", response);
        if (response) toast.error(response);
        else toast.error("Something went wrong");
        if (_.isFunction(params.callback)) params.callback(false);
    }
}

export const saveAgreement = (params, callback = null) => {
    return (dispatch, getState) => {
        let data = {
            method: "POST",
            url: `${Constants.SERVER_URL}${Constants.CALLS.SAVE_AGREEMENT}`,
            data: params,
            callback
        }
        dispatch(apiWithCallbacks(data, onSuccesssaveAgreement, onFailuresaveAgreement));
    }
}
const onSuccesssaveAgreement = (response, params) => {
    return (dispatch) => {
        // dispatch(setUser(response.data));
        if(_.isFunction(params.callback)) params.callback(response.data);
    }
}
const onFailuresaveAgreement = (response, params) => {
    return (dispatch) => {
        console.log("onFailuresaveAgreement", response);
        if (response) toast.error(response);
        else toast.error("Something went wrong");
        if (_.isFunction(params.callback)) params.callback(false);
    }
}
export const deleteAgreement = (params, callback = null) => {
    return (dispatch, getState) => {
        let data = {
            method: "POST",
            url: `${Constants.SERVER_URL}${Constants.CALLS.DELETE_AGREEMENT}`,
            data: params,
            callback
        }
        dispatch(apiWithCallbacks(data, onSuccessdeleteAgreement, onFailuredeleteAgreement));
    }
}
const onSuccessdeleteAgreement = (response, params) => {
    return (dispatch) => {
        // dispatch(setUser(response.data));
        if(_.isFunction(params.callback)) params.callback(response);
    }
}
const onFailuredeleteAgreement = (response, params) => {
    return (dispatch) => {
        console.log("onFailuredeleteAgreement", response);
        // if (response) toast.error(response);
        // else toast.error("Something went wrong");
        if (_.isFunction(params.callback)) params.callback(response);
    }
}

export const getAgreement = (params, callback = null) => {
    return (dispatch, getState) => {
        let data = {
            method: "POST",
            url: `${Constants.SERVER_URL}${Constants.CALLS.GET_AGREEMENT}`,
            data: params,
            callback
        }
        dispatch(apiWithCallbacks(data, onSuccessgetAgreement, onFailuregetAgreement));
    }
}
const onSuccessgetAgreement = (response, params) => {
    return (dispatch) => {
        // dispatch(setUser(response.data));
        if(_.isFunction(params.callback)) params.callback(response.data);
    }
}
const onFailuregetAgreement = (response, params) => {
    return (dispatch) => {
        console.log("onFailuregetAgreement", response);
        if (response) toast.error(response);
        else toast.error("Something went wrong");
        if (_.isFunction(params.callback)) params.callback(false);
    }
}

export const listAgreements = (params, callback = null) => {
    return (dispatch, getState) => {
        let data = {
            method: "GET",
            url: `${Constants.SERVER_URL}${Constants.CALLS.LIST_AGREEMENTS}`,
            data: params,
            callback
        }
        dispatch(apiWithCallbacks(data, onSuccesslistAgreements, onFailurelistAgreements));
    }
}
const onSuccesslistAgreements = (response, params) => {
    return (dispatch) => {
        dispatch(setAgreements(response.data));
        if(_.isFunction(params.callback)) params.callback(response.data);
    }
}
const onFailurelistAgreements = (response, params) => {
    return (dispatch) => {
        console.log("onFailurelistAgreements", response);
        if (response) toast.error(response);
        else toast.error("Something went wrong");
        if (_.isFunction(params.callback)) params.callback(false);
    }
}

export const getEmailHooks = (params, callback = null) => {
    return (dispatch, getState) => {
        let data = {
            method: "GET",
            url: `${Constants.SERVER_URL}${Constants.CALLS.GET_EMAIL_HOOKS}`,
            data: params,
            callback
        }
        dispatch(apiWithCallbacks(data, onSuccessgetEmailHooks, onFailuregetEmailHooks));
    }
}
const onSuccessgetEmailHooks = (response, params) => {
    return (dispatch) => {
        dispatch(setEmailHooks(response.data));
        if(_.isFunction(params.callback)) params.callback(response.data);
    }
}
const onFailuregetEmailHooks = (response, params) => {
    return (dispatch) => {
        console.log("onFailuregetEmailHooks", response);
        if (response) toast.error(response);
        else toast.error("Something went wrong");
        if (_.isFunction(params.callback)) params.callback(false);
    }
}

export const saveEmailHook = (params, callback = null) => {
    return (dispatch, getState) => {
        let data = {
            method: "POST",
            url: `${Constants.SERVER_URL}${Constants.CALLS.SAVE_EMAIL_HOOK}`,
            data: params,
            callback
        }
        dispatch(apiWithCallbacks(data, onSuccesssaveEmailHook, onFailuresaveEmailHook));
    }
}
const onSuccesssaveEmailHook = (response, params) => {
    return (dispatch) => {
        // dispatch(setEmailHooks(response.data));
        if(_.isFunction(params.callback)) params.callback(response.data);
    }
}
const onFailuresaveEmailHook = (response, params) => {
    return (dispatch) => {
        console.log("onFailuresaveEmailHook", response);
        if (response) toast.error(response);
        else toast.error("Something went wrong");
        if (_.isFunction(params.callback)) params.callback(false);
    }
}

export const getEmailCopy = (params, callback = null) => {
    return (dispatch, getState) => {
        let data = {
            method: "GET",
            url: `${Constants.SERVER_URL}${Constants.CALLS.GET_EMAIL_COPY}/${params.hook}`,
            data: params,
            callback
        }
        dispatch(apiWithCallbacks(data, onSuccessgetEmailCopy, onFailuregetEmailCopy));
    }
}
const onSuccessgetEmailCopy = (response, params) => {
    return (dispatch) => {
        // dispatch(setEmailHooks(response.data));
        if(_.isFunction(params.callback)) params.callback(response.data);
    }
}
const onFailuregetEmailCopy = (response, params) => {
    return (dispatch) => {
        console.log("onFailuregetEmailCopy", response);
        if (response) toast.error(response);
        else toast.error("Something went wrong");
        if (_.isFunction(params.callback)) params.callback(false);
    }
}

export const updateUserData = (params, callback = null) => {
    return (dispatch, getState) => {
        let data = {
            method: "POST",
            url: `${Constants.SERVER_URL}${Constants.CALLS.UPDATE_USER}`,
            data: params,
            callback
        }
        dispatch(apiWithCallbacks(data, onSuccessupdateUserData, onFailureupdateUserData));
    }
}
const onSuccessupdateUserData = (response, params) => {
    return (dispatch) => {
        if(_.isFunction(params.callback)) params.callback(response.data);
    }
}
const onFailureupdateUserData = (response, params) => {
    return (dispatch) => {
        if (response) toast.error(response);
        else toast.error("Something went wrong");
        if (_.isFunction(params.callback)) params.callback(false);
    }
}


export const stripePayment = (params, callback = null) => {
    return (dispatch, getState) => {
        let data = {
            method: "POST",
            url: `${Constants.SERVER_URL}${Constants.CALLS.STRIPE_PAYMENT}`,
            data: params,
            callback
        }
        dispatch(apiWithCallbacks(data, onSuccessstripePayment, onFailurestripePayment));
    }
}
const onSuccessstripePayment = (response, params) => {
    return (dispatch) => {
        console.log("onSuccessstripePayment", response);
        dispatch(setUser(response.data));
        if(_.isFunction(params.callback)) params.callback(response.data);
    }
}
const onFailurestripePayment = (response, params) => {
    return (dispatch) => {
        console.log("onFailurestripePayment", response);
        if (response) dispatch(alertPopupAction(true, response, "danger"));
        else dispatch(alertPopupAction(true, "Something went wrong", "danger"));
        if (_.isFunction(params.callback)) params.callback(false);
    }
}

export const stripeGetPaymentLink = (params, callback = null) => {
    return (dispatch, getState) => {
        let data = {
            method: "POST",
            url: `${Constants.SERVER_URL}${Constants.CALLS.STRIPE_GET_PAYMENT_LINK}`,
            data: params,
            callback
        }
        dispatch(apiWithCallbacks(data, onSuccessstripeGetPaymentLink, onFailurestripeGetPaymentLink));
    }
}
const onSuccessstripeGetPaymentLink = (response, params) => {
    return (dispatch) => {
        console.log("onSuccessstripeGetPaymentLink", response);
        dispatch(setUser(response.data));
        if(_.isFunction(params.callback)) params.callback(response.data);
    }
}
const onFailurestripeGetPaymentLink = (response, params) => {
    return (dispatch) => {
        console.log("onFailurestripeGetPaymentLink", response);
        if (response) toast.error(response);
        else toast.error("Something went wrong")
        if (_.isFunction(params.callback)) params.callback(false);
    }
}

export const getUserPlan = (params, callback = null) => {
    return (dispatch, getState) => {
        let data = {
            method: "GET",
            url: `${Constants.SERVER_URL}${Constants.CALLS.GET_USER_PLAN}`,
            params,
            callback
        }
        dispatch(apiWithCallbacks(data, onSuccessgetUserPlan, onFailuregetUserPlan));
    }
}
const onSuccessgetUserPlan = (response, params) => {
    return (dispatch) => {
        console.log("onSuccessgetUserPlan", response);
        // dispatch(setUserPlan(response.data.records));
        // dispatch(response.data.records);
        if(_.isFunction(params.callback)) params.callback(response.data);
    }
}
const onFailuregetUserPlan = (response, params) => {
    return (dispatch) => {
        console.log("onFailurestripePayment", response);
        if (response) dispatch(alertPopupAction(true, response, "danger"));
        else dispatch(alertPopupAction(true, "Something went wrong", "danger"));
        if (_.isFunction(params.callback)) params.callback(false);
    }
};

export const getUserInspections = (params, callback = null) => {
    return (dispatch, getState) => {
        let data = {
            method: "GET",
            url: `${Constants.SERVER_URL}${Constants.CALLS.GET_USER_INSPECTIONS}`,
            params,
            callback
        }
        dispatch(apiWithCallbacks(data, onSuccessgetUserInspections, onFailuregetUserInspections));
    }
}
const onSuccessgetUserInspections = (response, params) => {
    return (dispatch) => {
        console.log("onSuccessgetUserInspections", response);
        dispatch(setUserAllInspections(response.data.records));
        if(_.isFunction(params.callback)) params.callback(response.data);
    }
}
const onFailuregetUserInspections = (response, params) => {
    return (dispatch) => {
        console.log("onFailuregetUserInspections", response);
        if (response) dispatch(alertPopupAction(true, response, "danger"));
        else dispatch(alertPopupAction(true, "Something went wrong", "danger"));
        if (_.isFunction(params.callback)) params.callback(false);
    }
}

export const getContact = (params, callback = null) => {
    return (dispatch, getState) => {
        let data = {
            method: "GET",
            url: `${Constants.SERVER_URL}${Constants.CALLS.GET_CONTACT}`,
            params,
            callback
        }
        dispatch(apiWithCallbacks(data, onSuccessgetContact, onFailuregetContact));
    }
}
const onSuccessgetContact = (response, params) => {
    return (dispatch) => {
        console.log("onSuccessgetContact", response);
        dispatch(setContact(response.data.records));
        if(_.isFunction(params.callback)) params.callback(response.data);
    }
}
const onFailuregetContact = (response, params) => {
    return (dispatch) => {
        console.log("onFailuregetContact", response);
        if (response) dispatch(alertPopupAction(true, response, "danger"));
        else dispatch(alertPopupAction(true, "Something went wrong", "danger"));
        if (_.isFunction(params.callback)) params.callback(false);
    }
}

export const sendReport = (params, callback = null) => {
    return (dispatch, getState) => {
        let data = {
            method: "POST",
            url: `${Constants.SERVER_URL}${Constants.CALLS.SEND_REPORT}`,
            params,
            callback
        }
        dispatch(apiWithCallbacks(data, onSuccesssendReport, onFailuresendReport));
    }
}
const onSuccesssendReport = (response, params) => {
    return (dispatch) => {
        console.log("onSuccesssendReport", response);
        dispatch(sendInspectionReport(response.data.records));
        if(_.isFunction(params.callback)) params.callback(response.data);
    }
}
const onFailuresendReport = (response, params) => {
    return (dispatch) => {
        console.log("onFailuresendReport", response);
        if (response) dispatch(alertPopupAction(true, response, "danger"));
        else dispatch(alertPopupAction(true, "Something went wrong", "danger"));
        if (_.isFunction(params.callback)) params.callback(false);
    }
}

export const cancelSubscription = (params, callback = null) => {
    return (dispatch, getState) => {
        let data = {
            method: "POST",
            url: `${Constants.SERVER_URL}${Constants.CALLS.CANCEL_SUBSCRIPTION}`,
            data: params,
            callback
        }
        dispatch(apiWithCallbacks(data, onSuccesscancelSubscription, onFailurecancelSubscription));
    }
}
const onSuccesscancelSubscription = (response, params) => {
    return (dispatch) => {
        console.log("onSuccesscancelSubscription", response);
        dispatch(alertPopupAction(true, "Your subscription will be terminated at the before next billing date.", "danger"));
        if(_.isFunction(params.callback)) params.callback(response.data);
    }
}
const onFailurecancelSubscription = (response, params) => {
    return (dispatch) => {
        console.log("onFailurecancelSubscription", response);
        if (response) dispatch(alertPopupAction(true, response, "danger"));
        else dispatch(alertPopupAction(true, "Something went wrong", "danger"));
        if (_.isFunction(params.callback)) params.callback(false);
    }
}

export const getEntries = (params, callback = null) => {
    return (dispatch, getState) => {
        let data = {
            method: "GET",
            url: `${Constants.SERVER_URL}${Constants.CALLS.GET_ENTRIES}`,
            params,
            callback
        }
        dispatch(apiWithCallbacks(data, onSuccessgetEntries, onFailuregetEntries));
    }
}
const onSuccessgetEntries = (response, params) => {
    return (dispatch) => {
        console.log("onSuccessgetEntries", response);
        dispatch(setEntries(response.data.records));
        // dispatch(response.data.records);
        if(_.isFunction(params.callback)) params.callback(response.data);
    }
}
const onFailuregetEntries = (response, params) => {
    return (dispatch) => {
        console.log("onFailuregetEntries", response);
        if (response) dispatch(alertPopupAction(true, response, "danger"));
        else dispatch(alertPopupAction(true, "Something went wrong", "danger"));
        if (_.isFunction(params.callback)) params.callback(false);
    }
}


export const getPlans = (params, callback = null) => {
    return (dispatch, getState) => {
        let data = {
            method: "GET",
            url: `${Constants.SERVER_URL}${Constants.CALLS.GET_PLANS}`,
            params,
            callback
        }
        dispatch(apiWithCallbacks(data, onSuccessgetPlans, onFailuregetPlans));
    }
}
const onSuccessgetPlans = (response, params) => {
    return (dispatch) => {
        console.log("onSuccessgetPlans", response);
        dispatch(setPlans(response.data.records));
        // dispatch(response.data.records);
        if(_.isFunction(params.callback)) params.callback(response.data);
    }
}
const onFailuregetPlans = (response, params) => {
    return (dispatch) => {
        console.log("onFailuregetPlans", response);
        if (response) dispatch(alertPopupAction(true, response, "danger"));
        else dispatch(alertPopupAction(true, "Something went wrong", "danger"));
        if (_.isFunction(params.callback)) params.callback(false);
    }
}

export const stripeConnect = (params, callback = null) => {
    return (dispatch, getState) => {
        let data = {
            method: "POST",
            url: `${Constants.SERVER_URL}${Constants.CALLS.STRIPE_CONNECT}`,
            params,
            callback
        }
        dispatch(apiWithCallbacks(data, onSuccessstripeConnect, onFailurestripeConnect));
    }
}
const onSuccessstripeConnect = (response, params) => {
    return (dispatch) => {
        console.log("onSuccessstripeConnect", response);
        // dispatch(setPlans(response.data.records));
        // dispatch(response.data.records);
        if(_.isFunction(params.callback)) params.callback(response.data);
    }
}
const onFailurestripeConnect = (response, params) => {
    return (dispatch) => {
        console.log("onFailurestripeConnect", response);
        if (response) dispatch(alertPopupAction(true, response, "danger"));
        else dispatch(alertPopupAction(true, "Something went wrong", "danger"));
        if (_.isFunction(params.callback)) params.callback(false);
    }
}


export const stripeAccountRetrieve = (params, callback = null) => {
    return (dispatch, getState) => {
        let data = {
            method: "GET",
            url: `${Constants.SERVER_URL}${Constants.CALLS.STRIPE_ACCOUNT_RETRIEVE}`,
            params,
            callback
        }
        dispatch(apiWithCallbacks(data, onSuccessstripeAccountRetrieve, onFailurestripeAccountRetrieve));
    }
}
const onSuccessstripeAccountRetrieve = (response, params) => {
    return (dispatch) => {
        console.log("onSuccessstripeAccountRetrieve", response);
        // dispatch(setPlans(response.data.records));
        // dispatch(response.data.records);
        if(_.isFunction(params.callback)) params.callback(response.data);
    }
}
const onFailurestripeAccountRetrieve = (response, params) => {
    return (dispatch) => {
        console.log("onFailurestripeAccountRetrieve", response);
        if (response) dispatch(alertPopupAction(true, response, "danger"));
        else dispatch(alertPopupAction(true, "Something went wrong", "danger"));
        if (_.isFunction(params.callback)) params.callback(false);
    }
}

export const getAllComments = (params, callback = null) => {
    return (dispatch, getState) => {
        let data = {
            method: "GET",
            url: `${Constants.SERVER_URL}${Constants.CALLS.GET_COMMENTS}`,
            params,
            callback
        }
        dispatch(apiWithCallbacks(data, onSuccessgetAllComments, onFailuregetAllComments));
    }
}
const onSuccessgetAllComments = (response, params) => {
    return (dispatch) => {
        console.log("onSuccessgetAllComments", response);
        // dispatch(setPlans(response.data.records));
        if(!params.params.NotsetToProps){
            console.log(params);
            dispatch(setComments(response.data.records));
        }
        if(_.isFunction(params.callback)) params.callback(response.data);
    }
}
const onFailuregetAllComments = (response, params) => {
    return (dispatch) => {
        console.log("onFailuregetAllComments", response);
        if (response) dispatch(alertPopupAction(true, response, "danger"));
        else dispatch(alertPopupAction(true, "Something went wrong", "danger"));
        if (_.isFunction(params.callback)) params.callback(false);
    }
}

export const updateComment = (params, callback = null) => {
    return (dispatch, getState) => {
        let data = {
            // method: "POST",
            url: `${Constants.SERVER_URL}${Constants.CALLS.UPDATE_COMMENT}`,
            params,
            callback
        }
        dispatch(apiWithCallbacks(data, onSuccessupdateComment, onFailureupdateComment));
    }
}
const onSuccessupdateComment = (response, params) => {
    return (dispatch) => {
        console.log("onSuccessupdateComment", response);
        if(_.isFunction(params.callback)) params.callback(response.data);
    }
}
const onFailureupdateComment = (response, params) => {
    return (dispatch) => {
        if(response) dispatch(alertPopupAction(true, response, "danger"));
        else dispatch(alertPopupAction(true, "Something went wrong", "danger"));
        if(_.isFunction(params.callback)) params.callback(false);
    }
}

export const addComment = (params, callback = null) => {
    return (dispatch, getState) => {
        let data = {
            // method: "POST",
            url: `${Constants.SERVER_URL}${Constants.CALLS.ADD_COMMENT}`,
            data:params,
            callback
        }
        dispatch(apiWithCallbacks(data, onSuccessaddComment, onFailureaddComment));
    }
}
const onSuccessaddComment = (response, params) => {
    return (dispatch) => {
        console.log("onSuccessaddComment", response);
        if(_.isFunction(params.callback)) params.callback(response.data);
    }
}
const onFailureaddComment = (response, params) => {
    return (dispatch) => {
        if(response) dispatch(alertPopupAction(true, response, "danger"));
        else dispatch(alertPopupAction(true, "Something went wrong", "danger"));
        if(_.isFunction(params.callback)) params.callback(false);
    }
}

export const deleteComment = (params, callback = null) => {
    return (dispatch, getState) => {
        let data = {
            // method: "POST",
            url: `${Constants.SERVER_URL}${Constants.CALLS.DELETE_COMMENT}`,
            data:params,
            callback
        }
        dispatch(apiWithCallbacks(data, onSuccessdeleteComment, onFailuredeleteComment));
    }
}
const onSuccessdeleteComment = (response, params) => {
    return (dispatch) => {
        console.log("onSuccessdeleteComment", response);
        dispatch(alertPopupAction(true, response.data.msg, "danger"));
        if(_.isFunction(params.callback)) params.callback(response.data);
    }
}
const onFailuredeleteComment = (response, params) => {
    return (dispatch) => {
        if(response) dispatch(alertPopupAction(true, response, "danger"));
        else dispatch(alertPopupAction(true, "Something went wrong", "danger"));
        if(_.isFunction(params.callback)) params.callback(false);
    }
}