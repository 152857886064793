/* eslint-disable react/no-direct-mutation-state */
import _ from "lodash";
import React from 'react';
import { connect } from 'react-redux';
import { sessionService } from 'redux-react-session';
import { deleteTemplates, setRowsPerPage } from '../../../redux/actions/appAction';
import { getAllTemplates, listAgreements, deleteAgreement } from '../../../redux/apiCalls/appData';
import CustomTable from "../../components/tables/CustomTable";
import Spinner from '../../shared/Spinner';
import toast from "react-hot-toast";
import {AddIconMobile } from '../../icons/Icons';
import DeleteWarningModal from '../../components/modal/deleteWarningModal';

const sortNumberSorting = (items) => {
    return items.map((item, index) => {
        item.sortNo = index + 1;
        return item;
    })
}

class AgreementsPage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            page: 0,
            input: '',
            sort: 'DESC',
            sortIcon: 'caret-down',
            templates: [],
            agreements: [],
            loading: true,
            totalRecords: 0,
            rowsPerPage: 20,
            user: {},
            windowWidth: window.innerWidth,
            deleteWarningModal: {
                show: false,
                title: "",
                body: "",
                services: [],
                type: "",
                id: "",
                deletable:true
            }
        };
        this.load = true;
    }
    componentDidMount = async () => {
        window.addEventListener('resize', this.handleResize);
        this.timer = null;
        this.props.listAgreements({}, (data) => {
            // console.log(data);
            if (data) {
                this.setState({ agreements: data, loading: false, totalRecords: data.length });
            }
        })
        // this.props.deleteTemplates();
        // this.props.getAllTemplates({sort: this.state.sort, input: this.state.input, page: this.state.page, rowsPerPage:this.state.rowsPerPage, 
        //     defaultTemplate: true, baseTemplate: true}, (data) => {
        //     this.setState({loading:false, templates: this.props.templates, totalRecords: data.totalRecords, recordsPerPage: this.state.rowsPerPage
        //     });
        // });
        try {
            let session = await sessionService.loadSession();
            this.setState({ user: session });
        } catch (error) {
            return null;
        }
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.handleResize);
    }


    handleResize = () => {
        this.setState({ windowWidth: window.innerWidth });
    };
    // handleChangePage = (page) => {
    //     this.setState({page});
    //     this.props.getAllTemplates({sort: this.state.sort, input: this.state.input, page: page, rowsPerPage:this.state.rowsPerPage, 
    //         defaultTemplate: true, baseTemplate: true}, (data) => {
    //         let appendNewRecords = [...this.state.templates, ...data.records]
    //         this.setState({templates: appendNewRecords, totalRecords: data.totalRecords, page});
    //     });
    // }
    // handleSorting = (orderBy) => {
    //     let sort = this.state.sort === 'DESC' ? 'ASC' : this.state.sort;
    //     this.setState({sort: sort, loading:true});
    //     let currentPageRecords = (this.state.page+1 * 3);
    //     console.log("handleSorting ", sort, currentPageRecords);
    //     this.props.getAllTemplates({sortKey: orderBy, sortValue:sort, input: this.state.input, page: 0, rowsPerPage: currentPageRecords,//this.state.rowsPerPage, 
    //         defaultTemplate: true, baseTemplate: true}, (data) => {
    //         this.setState({loading:false, templates: data.records, totalRecords: data.totalRecords});
    //     });
    // }
    // handleChangeRowsPerPage = (newRowSet) => {
    //     this.setState({ page: 0, loading:true });
    //     this.props.setRowsPerPage(newRowSet);
    //     this.props.getAllTemplates({sort: this.state.sort, input: this.state.input, page: this.state.page, rowsPerPage: newRowSet, defaultTemplate: true, baseTemplate: true}, (data) => {
    //         this.props.deleteTemplates();
    //         this.setState({loading:false, templates: data.records, totalRecords: data.totalRecords});
    //     });
    // }

    editAgreement = (id) => {
        this.props.history.push(`/agreement/${id}`);
    }

    cloneAgreement = (id) => {
        this.props.history.push(`/agreement/clone=${id}`);
    }

    deleteAgreement = (id) => {
        this.props.deleteAgreement({ id: id }, (data) => {
            if (data.status && data.status === 200) {
                this.setState({ agreements: data.data, loading: false, totalRecords: data.data.length });
                toast.success("Agreement deleted successfully");
            } else {
                if(data && data.data && data.data.message && data.data.services){
                    this.state.deleteWarningModal = {
                        show: true,
                        title: "Warning!",
                        body: data.data.message,
                        services: data.data.services || [],
                        type: 'danger',
                        id: id,
                        deletable: true,
                    }
                    this.setState(this.state);
                }
            }
            
        });
    }

    deleteWarningModalClose = (action, object) => {
        this.state.deleteWarningModal = {
            show: false,
            title: "",
            body: "",
            services: [],
            type: "",
            id: null,
            deletable: true,
        };
        this.setState(this.state);
        if (action === "Yes") {
            this.props.deleteAgreement({ id: object.id, confirm: true }, (data) => {
                if (data) {
                    this.setState({ agreements: data.data, loading: false, totalRecords: data.data.length });
                    toast.success("Agreement deleted");
                }
            });

            //   this.props.deleteSection(this.state.index, this.state.type);
        }
    }

    // handleDrop = (e, targetIndex) => {
    //     const sourceIndex = e.dataTransfer.getData('index');
    //     let reorderedItems = [...this.state.templates];
    //     const [movedItem] = reorderedItems.splice(sourceIndex, 1);
    //     reorderedItems.splice(targetIndex, 0, movedItem);
    //     console.log("handleDrop",reorderedItems);
    //     this.setState({
    //         templates: reorderedItems
    //     })
    // };

    render = () => {
        const { loading, rowsPerPage, templates, page, totalRecords, agreements, windowWidth } = this.state;
        const { user } = this.props;
        // const agreementTemplatesData = !_.isEmpty(templates) ? templates.map((t) => {
        //     let a = {};
        //     a.id = t.id;
        //     a.templateName = t.templateName;
        //     // a.templateDescription = t.templateDescription;
        //     return a;
        // }) : [];
        return (
            <div>
                <div className="scope-templetes-heading">
                    <div className="scope-templetes-title">Agreements</div>
                    <div className="scope-templetes-filters">
                    </div>
                    {/* { user && user.userType === "admin" && 
                    <Button
                        className="master-agreement"
                        label={`Master Copy`}
                        showLeftIcon={false}
                        showRightIcon={false}
                        size="large"
                        state="default"
                        variant="filled"
                        type="primary"
                        clickEvent={(e) => this.editAgreement("master")}
                    />
                    } */}
                    <div onClick={(e) => this.editAgreement("new")}>
                        {
                            windowWidth < 700 ? <AddIconMobile /> : <AddIconMobile height="50" width="40" />
                        }
                    </div>
                </div>
                {loading ? <Spinner /> : !_.isEmpty(agreements) ?
                    <CustomTable
                        tableHead={tableHead}
                        tableData={agreements}
                        editRow={this.editAgreement}
                        clone={this.cloneAgreement}
                        remove={this.deleteAgreement}
                        type="agreements"
                        page={page}
                        loading={loading}
                        rowsPerPage={rowsPerPage}
                        // handleChangePage={this.handleChangePage}
                        // handleSorting={this.handleSorting}
                        // handleChangeRowsPerPage={this.handleChangeRowsPerPage}
                        totalNumber={totalRecords}
                        // sorting={this.sortingTemplates}
                        disableDrag={true}
                    />

                    : <h4 className="scope-templetes-heading">No Agreements Found</h4>}
                <DeleteWarningModal modalSettings={this.state.deleteWarningModal} close={this.deleteWarningModalClose} />
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        templates: state.appData.templates,
        rowsPerPage: state.appData.rowsPerPage,
        user: state.authData.user
    };
};
const mapDispatchToProps = (dispatch) => {
    return {
        getAllTemplates: (object, cb) => dispatch(getAllTemplates(object, cb)),
        listAgreements: (object, cb) => dispatch(listAgreements(object, cb)),
        deleteTemplates: () => dispatch(deleteTemplates()),
        deleteAgreement: (object, cb) => dispatch(deleteAgreement(object, cb)),
        setRowsPerPage: (rows) => dispatch(setRowsPerPage(rows))
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(AgreementsPage);
const tableHead = [
    { id: 'title', label: 'Title', sortable: false, width: "30%" },
    { id: 'description', label: 'Description', sortable: false, width: "60%" },
    { id: 'agreementEdit/delete', label: 'Action', sortable: false },
];