/* eslint-disable react/no-direct-mutation-state */
import React, { Component } from 'react';
import { AddServiceDefaultIcon, ModifierIcon, PlusIcon, CrossIcon, EditBlueIcon } from '../../icons/Icons';
import ServiceDropDown from './components/service_dropdown';
import ExpandableList from './components/expandable_list';
import BundledEditor from '../../components/editor/tinyEditor';
import { connect } from 'react-redux';
import { Button } from "../../components/button/button.js";
import SelectModifierModal from '../../components/modal/selectModifierModel';
import { addService, getServiceResource, addMedia, deleteMedia } from '../../../redux/apiCalls/appData';
import Spinner from '../../shared/Spinner';
import Form from 'react-bootstrap/Form';
import toast from 'react-hot-toast';
import _ from 'lodash';
import DurationPicker from './components/duration_picker';



class NewService extends Component {
    constructor(props) {
        super(props);
        this.textfieldRef = React.createRef();
        this.state = {
            service: {
                title: "Service Name",
                description: "<p>This is the initial description of the new service.</p>",
                templates: [],
                agreements: [],
                upsells: [],
                modifiers: [],
                duration: 0,
                price: 0,
                extraTime: 0,
                signPreInpsectionAgreement: false,
                showOnWebCheckout: false,
                showPrices: false,
                icon: null,
            },
            show: false,
            templates: [],
            agreements: [],
            action_save: false,
            upsells: [],
            allModifiers: [],
            loading: false,
            imageUrl: null,
            imageFile: null,
            min: 0,
            hour: 0,
            validationErrors: {
                title: false,
                description: false,
                templates: false,
                duration: false,
                price: false,
                extraTime: false,
            },
            windowWidth: window.innerWidth,
            serviceNameEditState: false,
            deleteConfirmationModal: {
                show: false,
                title: "",
                body: "",
                type: "",
            },
            originalServiceCopy: null,
            checkRoute: true,
        };
        this.quillEditorRef = React.createRef();
    }

    handleBeforeUnload = (e) => {
        const newService = {
            ...this.state.service,
            description: this.quillEditorRef.current.getContent()
        };
        const isEqual = _.isEqual(newService, this.state.originalServiceCopy);
        if (!isEqual) {
            const confirmationMessage = "You have unsaved changes. Are you sure you want to leave?";
            e.returnValue = confirmationMessage;
            return confirmationMessage;
        }
    };

    onRouteChange = (location, action) => {
        const newService = {
            ...this.state.service,
            description: this.quillEditorRef.current.getContent()
        };
        const isEqual = _.isEqual(newService, this.state.originalServiceCopy);
        if (!isEqual && this.state.checkRoute) {
            const status = window.confirm("You have unsaved changes. Do you want to discard them?");
            if (!status) {
                return false;
            } else {
                return true;
            }
        } else {
            return true;
        }
    }

    async componentDidMount() {
        let service_id = this.props.match.params.id;
        console.log("service:", service_id, this.props.match.params);
        window.addEventListener("beforeunload", this.handleBeforeUnload);
        this.blockHistory = this.props.history.block(this.onRouteChange);
        window.addEventListener('resize', this.handleResize);
        this.setState({ loading: true });
        this.props.getServiceResource("", (data) => {
            try {
                let tempts = data.templates.map((t) => {
                    return {
                        ...t,
                        fees: 0,
                    }
                });
                this.setState({ templates: tempts, allModifiers: data.modifiers, upsells: data.services, agreements: data.agreements });
                let filteredService = null;
                if (service_id) {
                    filteredService = data.services.find(service => service.id === service_id)
                }
                if(filteredService === null && (this.props.location.state !== undefined || this.props.location.state !== null)) {
                    this.state.service = this.props.location.state ? this.props.location.state.service : this.state.service;
                } else if(filteredService !== null) {
                    this.state.service = filteredService;
                } else {
                    // this.state.service = this.state.service;
                }
                this.setState(this.state);
                this.state.originalServiceCopy = _.merge({}, this.state.service);
                if (this.state.service.id) {
                    this.setState(prevState => ({
                        upsells: prevState.upsells.filter(upsell => upsell.id !== prevState.service.id)
                    }));
                }
                this.state.imageUrl = this.state.service.icon ? this.state.service.icon.url : null;
                this.convertMinutesToHoursAndMinutes(this.state.service.duration)
                this.setState(this.state);
                this.prePareServiceTemplates();
                this.state.loading = false;
                this.setState(this.state);
            } catch (error) {
                console.log("error in new service", error);
                this.setState({ loading: false });
            }
        });
    }

    prePareServiceTemplates = () => {
        let { templates, service } = this.state;
        let { templates: selectedTemplates } = service;
        templates = templates.filter(
            (template) =>
                !selectedTemplates.some((selected) => selected.id === template.id)
        );
        templates = [...selectedTemplates, ...templates];
        this.setState({ templates });
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.handleResize);
        window.removeEventListener("beforeunload", this.handleBeforeUnload);
        if (this.blockHistory) {
            this.blockHistory();
        }
    }

    handleResize = () => {
        this.setState({ windowWidth: window.innerWidth });
    };

    openDeleteConfirmationModal = (e) => {
        const newService = {
            ...this.state.service,
            description: this.quillEditorRef.current.getContent()
        };
        this.setState({ checkRoute: false });
        const isEqual = _.isEqual(newService, this.state.originalServiceCopy);
        if (isEqual) {
            this.props.history.replace('/services');
        } else {
            const status = window.confirm("You have unsaved changes. Do you want to discard them?");
            if (!status) {
                return false;
            } else {
                this.props.history.replace('/services');
            }
        }
    }

    deleteConfirmationModalClose = (action, object) => {
        this.state.deleteConfirmationModal = {
            show: false,
            title: "",
            body: "",
            type: "",
        };
        this.setState(this.state);
        if (action === "Yes") {
            this.props.history.replace('/services');
        }
    }

    convertMinutesToHoursAndMinutes(totalMinutes) {
        var hours = Math.floor(totalMinutes / 60);
        var minutes = totalMinutes % 60;
        this.state.min = minutes;
        this.state.hour = hours;
        this.setState(this.state);
    }


    editorLoaded = (editor) => {
        this.quillEditorRef.current = editor;
        if (this.quillEditorRef && this.quillEditorRef.current && this.state.service.description) {
            setTimeout(() => {
                this.quillEditorRef.current.setContent(this.state.service.description);
            }, 500);
        }
    }

    handleRemoveImage = () => {
        this.setState({
            imageUrl: null,
            imageFile: null
        });
    };

    handleImageChange = (event) => {
        const selectedFile = event.target.files[0];
        if (selectedFile) {
            const imageUrl = URL.createObjectURL(selectedFile);
            this.state.imageUrl = imageUrl;
            this.state.imageFile = selectedFile;
            this.setState(this.state)
        }
        event.target.value = null;
    };

    tooglePreInspectionCheck(value) {
        if (!_.isEmpty(this.state.service.agreements)) {
            this.state.service.signPreInpsectionAgreement = !value;
            this.setState(this.state)
        } else if (_.isEmpty(this.state.service.agreements) && value === true) {
            this.state.service.signPreInpsectionAgreement = !value;
            this.setState(this.state)
        }
        else {
            toast.error("Please choose agreement first", { position: "bottom-right" });
        }
    }

    toogleShowServiceOnCheckout(value) {
        this.state.service.showOnWebCheckout = !value;
        this.setState(this.state)
    }

    toogleShowPrizeToClient(value) {
        this.state.service.showPrices = !value;
        this.setState(this.state)
    }


    onAddModifier = () => {
        this.state.show = true;
        this.setState(this.state);
    }

    validateService = () => {
        const { service } = this.state;
        const { title, description, templates, duration, extraTime } = service;
        const titleValid = title.trim() !== "";
        const descriptionValid = description.trim() !== "";
        const templatesValid = templates.length > 0;
        const durationValid = duration > 0;
        const extraTimeValid = (duration + (extraTime || 0)) <= 1440;

        const validationErrors = {
            title: !titleValid,
            description: !descriptionValid,
            templates: !templatesValid,
            duration: !durationValid,
            extraTime: !extraTimeValid,
        };
        this.setState({ validationErrors });
        return titleValid && descriptionValid && templatesValid && durationValid && extraTimeValid;
    }

    onAddService = async () => {
        this.state.action_save = true;
        this.state.checkRoute = false;
        this.setState(this.state)
        const { imageUrl, service, imageFile } = this.state;
        service.templates = service.templates.map(t => ({
            fees: t.fees,
            templateName: t.templateName,
            id: t.id
        }));
        service.description = this.quillEditorRef.current.getContent();
        service.price = parseFloat(service.price);
        this.setState({ service });
        const isValid = this.validateService();

        if (isValid) {
            if (!imageFile && service.icon && imageUrl !== service.icon.url) {
                // Old image is removed because the image URL has changed
                // Delete the old image
                this.props.deleteMedia({ id: service.icon.id }, (data) => {
                    service.icon = null; // Remove the reference to the old image
                    this.setState({ service }); // Update the state
                    this.continueWithAddService(); // Continue with adding the service
                    // this.setState({ loading: false });
                });
            } else if (imageUrl && imageUrl !== service.icon?.url) {
                // New image is added or existing image is changed
                // Upload the new image
                let formData = new FormData();
                formData.append('mediaType', 'serviceIcon');
                formData.append('image', imageFile);
                this.props.addMedia(formData, (data) => {
                    // Delete the old image if it exists
                    if (service.icon && service.icon.id) {
                        this.props.deleteMedia({ id: service.icon.id }, () => {
                            service.icon = data[0].id; // Update the service with the ID of the new image
                            this.setState({ service });
                            this.continueWithAddService();
                            // this.setState({ loading: false });
                        });
                    } else if(_.has(service, 'icon') && !_.has(service, 'icon.id') && service.icon){
                        this.props.deleteMedia({ id: service.icon }, () => {
                            service.icon = data[0].id; // Update the service with the ID of the new image
                            this.setState({ service });
                            this.continueWithAddService();
                        });
                    } else {
                        service.icon = data[0].id;
                        this.setState({ service });
                        this.continueWithAddService(); // Continue with adding the service
                        // this.setState({ loading: false });
                    }
                });
            } else {
                //if the service has already image and he didnt change the image
                if (this.state.service.icon != null && (this.state.service.icon).hasOwnProperty("id")) {
                    this.state.service.icon = this.state.service.icon.id;
                    this.setState(this.state);
                }
                this.continueWithAddService();
                // this.setState({ loading: false });
            }
        } else {
            this.setState({ action_save: false });
        }
    };



    continueWithAddService = async () => {
        this.props.addService(this.state.service, (data) => {
            if (data) {
                this.state.active_save = false;
                if(data.id){
                    this.state.service.id = data.id;
                }
                toast.success("service saved successfully");
                this.props.history.push('/services');
                this.setState({ action_save: false });
            }
        });
    }

    onTitleChange = (e) => {
        this.state.service.title = e.target.value;
        this.setState(this.state);
    }

    onDescriptionChange = (e) => {
        this.state.service.description = e.target.value;
        this.setState(this.state);
    }

    onTemplateChange = (templates) => {
        
        let totalPrice = 0;
        templates.forEach((t, i) => {
            t.fees = parseFloat(t.fees);
            totalPrice = totalPrice + t.fees;
        });
        this.state.service.templates = templates;
        this.state.service.price = totalPrice;
        // this.state.service.price = templates.reduce((total, template) => total + parseFloat(template.fees), 0);
        this.setState(this.state);
        this.prePareServiceTemplates();
    }

    onAgreementChange = (agreements) => {
        this.state.service.agreements = agreements;
        this.setState(this.state);
    }

    onUpsellChange = (upsells) => {
        this.state.service.upsells = upsells;
        this.setState(this.state);
    }

    onDurationChange = (timeInMinutes) => {
        this.state.service.duration = timeInMinutes;
        this.setState(this.state);
    }

    onExtraTimeChange = (timeInMinutes) => {
        this.state.service.extraTime = timeInMinutes;
        this.setState(this.state);
    }

    onSelectModifierDone = (modifiers) => {
        this.state.service.modifiers = modifiers;
        this.setState(this.state);
        this.setState({ show: false });
    }

    closeModifierModal = () => {
        console.log("CLOSE");
        this.setState({ show: false });
    }

    onDeleteItem = (modifiers) => {
        this.state.service.modifiers = modifiers;
        this.setState(this.state);
    }

    onModifierChange = (modifier) => {
        const index = this.state.service.modifiers.findIndex(item => item.id === modifier.id);
        if (index > -1) {
            this.state.service.modifiers[index] = modifier;
            this.setState(this.state)
        }
    }

    editServiceNameState = () => {
        this.setState({ serviceNameEditState: !this.state.serviceNameEditState })
        if (this.state.service.title.length === 0) {
            this.state.service.title = "Service Name";
        }
        setTimeout(() => {
            if (this.textfieldRef.current) {
                this.textfieldRef.current.focus();
            }
        }, 500);
    }


    render() {
        const { show, service, templates, agreements, upsells, allModifiers, loading, imageUrl, validationErrors } = this.state;
        const selectedTemplates = service.templates && service.templates.length > 0 ? service.templates : [];
        const durationHalfHourSlots = [
            { id: 1, column1: "00 hr : 00 min", column2: "00 hr : 30 min" },
            { id: 2, column1: "01 hr : 00 min", column2: "01 hr : 30 min" },
            { id: 3, column1: "02 hr : 00 min", column2: "02 hr : 30 min" },
            { id: 4, column1: "03 hr : 00 min", column2: "03 hr : 30 min" },
            { id: 5, column1: "04 hr : 00 min", column2: "04 hr : 30 min" },
            { id: 6, column1: "05 hr : 00 min", column2: "05 hr : 30 min" },
            { id: 7, column1: "06 hr : 00 min", column2: "06 hr : 30 min" },
            { id: 8, column1: "07 hr : 00 min", column2: "07 hr : 30 min" },
            { id: 9, column1: "08 hr : 00 min", column2: "08 hr : 30 min" },
            { id: 10, column1: "09 hr : 00 min", column2: "09 hr : 30 min" },
            { id: 11, column1: "10 hr : 00 min", column2: "10 hr : 30 min" },
            { id: 12, column1: "11 hr : 00 min", column2: "11 hr : 30 min" },
            { id: 13, column1: "12 hr : 00 min", column2: "12 hr : 30 min" },
            { id: 14, column1: "13 hr : 00 min", column2: "13 hr : 30 min" },
            { id: 15, column1: "14 hr : 00 min", column2: "14 hr : 30 min" },
            { id: 16, column1: "15 hr : 00 min", column2: "15 hr : 30 min" },
            { id: 17, column1: "16 hr : 00 min", column2: "16 hr : 30 min" },
            { id: 18, column1: "17 hr : 00 min", column2: "17 hr : 30 min" },
            { id: 19, column1: "18 hr : 00 min", column2: "18 hr : 30 min" },
            { id: 20, column1: "19 hr : 00 min", column2: "19 hr : 30 min" },
            { id: 21, column1: "20 hr : 00 min", column2: "20 hr : 30 min" },
            { id: 22, column1: "21 hr : 00 min", column2: "21 hr : 30 min" },
            { id: 23, column1: "22 hr : 00 min", column2: "22 hr : 30 min" },
            { id: 24, column1: "23 hr : 00 min", column2: "23 hr : 30 min" },
            { id: 25, column1: "24 hr : 00 min" }
        ];

        const extraTimeSlots = [
            { id: 1, column1: "00 hr : 00 min", column2: "00 hr : 30 min" },
            { id: 2, column1: "01 hr : 00 min", column2: "01 hr : 30 min" },
            { id: 3, column1: "02 hr : 00 min", column2: "02 hr : 30 min" },
            { id: 4, column1: "03 hr : 00 min", column2: "03 hr : 30 min" },
            { id: 5, column1: "04 hr : 00 min", column2: "04 hr : 30 min" },
            { id: 6, column1: "05 hr : 00 min" },
        ];

        return loading ? <Spinner /> : (
            <div className='service-container'>
                <div className="scope-services-heading">
                    <div className="scope-templetes-title">
                        {this.state.serviceNameEditState ?
                            <Form.Control
                                type="text"
                                value={service.title}
                                ref={this.textfieldRef}
                                onChange={this.onTitleChange}
                                className="templateName-edit" />
                            : service.title}
                        <div className="editIcon" onClick={(e) => this.editServiceNameState()}>
                            {this.state.serviceNameEditState ? <></>
                                :
                                <EditBlueIcon title="Edit Service Title" />
                            }
                        </div>
                    </div>
                    <div className='new-service-actions'>
                        {
                            this.state.serviceNameEditState ? <Button
                                label={`Done`}
                                showLeftIcon={true}
                                showRightIcon={false}
                                size="small"
                                state="default"
                                variant="filled"
                                type="primary"
                                clickEvent={this.editServiceNameState}
                            /> : <>
                                <Button
                                    label={`Cancel`}
                                    showLeftIcon={true}
                                    showRightIcon={false}
                                    size="small"
                                    state="default"
                                    variant="outlined"
                                    type="secondary"
                                    disabled={this.state.action_save}
                                    clickEvent={(e)=> this.openDeleteConfirmationModal(e)}
                                />
                                <Button
                                    label={`Save`}
                                    showLeftIcon={true}
                                    showRightIcon={false}
                                    size="small"
                                    state="default"
                                    variant="filled"
                                    type="primary"
                                    disabled={this.state.action_save}
                                    clickEvent={this.onAddService}
                                /></>
                        }
                    </div>
                </div>
                <div className='new-service-body'>
                    <div class="row d-flex flex-column flex-lg-row align-items-center align-items-lg-stretch">
                        <div class="col-auto text-center">
                            <input
                                type="file"
                                accept="image/*"
                                style={{ display: 'none' }}
                                onChange={this.handleImageChange}
                                id="fileInput"
                            />
                            {imageUrl ? (
                                <>
                                    <div className="image-container">
                                        <img src={imageUrl} alt="Selected" className="selected-image" />
                                        {imageUrl && (
                                            <span className="remove-icon" onClick={this.handleRemoveImage}>
                                                <CrossIcon />
                                            </span>
                                        )}
                                    </div>
                                </>
                            ) :
                                <div className="image-picker-icon" onClick={() => document.getElementById('fileInput').click()}>
                                    <AddServiceDefaultIcon />
                                </div>
                            }
                            <div className='picker-description'>
                                <p className='service-icon-primary-text' >Add Service Icon</p>
                                <p className='service-icon-secondary-text' >Recommended Size: 150x150 px</p>
                            </div>
                        </div>
                        <div class="col description-container">
                            <p className='textfeildlabel'>Description</p>
                            <BundledEditor
                                onInit={(evt, editor) => this.editorLoaded(editor)}
                                className="full-width-editor" init={{
                                    // height: 550,
                                    min_height: 141,
                                    max_height: 250,
                                    menubar: false,
                                    elementpath: false,
                                    branding: false,
                                    resize: false,
                                    plugins: [
                                        'advlist', 'anchor', 'autolink', 'image', 'link', 'lists',
                                        'searchreplace', 'preview', 'table', 'wordcount', 'code', 'table', 'quickbars', 'pagebreak', 'save', 'preview'
                                    ],
                                    toolbar:
                                        'bold italic underline strikethrough hr forecolor backcolor removeformat | alignleft aligncenter ' +
                                        'alignright alignjustify | lineheight | bullist numlist outdent indent | ' +
                                        'image link table refVars preview',
                                    quickbars_selection_toolbar: 'bold italic underline fontsize | forecolor backcolor | quicklink',
                                    // quickbars_insert_toolbar: false,
                                    quickbars_image_toolbar: 'alignleft aligncenter alignright | rotateleft rotateright | imageoptions',
                                    toolbar_mode: 'wrap',
                                    content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }',
                                    advlist_bullet_styles: 'square',
                                    advlist_number_styles: 'lower-alpha,lower-roman,upper-alpha,upper-roman',
                                }} />
                            {validationErrors.description && (
                                <div className="error-message">Please add description</div>
                            )}
                            <div class="row py-3">
                                <div class="col-lg mb-3 mb-lg-0">
                                    <ServiceDropDown
                                        title="Templates"
                                        hintText="Select Template(s)"
                                        options={templates}
                                        selectedOptions={selectedTemplates}
                                        onOptionsChange={this.onTemplateChange}
                                        itemEditable={true}
                                        type={"template"}
                                        user={this.props.user}
                                    />
                                    {validationErrors.templates && (
                                        <div className="error-message">Please select at least one Template</div>
                                    )}
                                </div>
                                <div class="col-lg mb-3 mb-lg-0">
                                    <ServiceDropDown
                                        title="Agreements"
                                        hintText="Select Agreement(s)"
                                        options={agreements}
                                        selectedOptions={service.agreements}
                                        onOptionsChange={this.onAgreementChange}
                                        type={"agreements"}
                                        user={this.props.user}
                                    />
                                    <div className='pre-inspection-check'>
                                        <input
                                            type="checkbox"
                                            className="custom-checkbox"
                                            checked={service.signPreInpsectionAgreement}
                                            onChange={() => this.tooglePreInspectionCheck(service.signPreInpsectionAgreement)}
                                        />
                                        <label>Client to sign during booking?</label>
                                    </div>
                                </div>
                                <div class="col-lg mb-3 mb-lg-0">
                                    <ServiceDropDown
                                        title="Upsells"
                                        hintText="Select Upsell(s)"
                                        options={upsells}
                                        selectedOptions={service.upsells === null ? [] : service.upsells}
                                        onOptionsChange={this.onUpsellChange}
                                        type={"upsell"}
                                        user={this.props.user}
                                    />
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-lg mb-3 mb-lg-0">
                                    <DurationPicker
                                        title="Duration"
                                        hintText="Select Duration"
                                        options={durationHalfHourSlots}
                                        value={service.duration}
                                        onDurationChange={this.onDurationChange}
                                        type={"duration"}
                                    />
                                    {validationErrors.duration && (
                                        <div className="error-message">Please select the duration of Service</div>
                                    )}
                                </div>
                                <div class="col-lg mb-3 mb-lg-0">
                                    <div className='textfeild-component'>
                                        <p className='textfeildlabel'>Price ($)</p>
                                        <input
                                            value={service.price.toFixed(2)}
                                            className="text-field"
                                            min="0"
                                            readOnly={true}
                                        />
                                    </div>
                                </div>
                                <div class="col-lg mb-3 mb-lg-0">
                                    <DurationPicker
                                        title="Block extra time after appointment"
                                        hintText="Set time in minutes"
                                        options={extraTimeSlots}
                                        value={service.extraTime}
                                        onDurationChange={this.onExtraTimeChange}
                                        type={"extraTime"}
                                    />
                                    {validationErrors.extraTime && (
                                        <div className="error-message">Service duration should be less or equal to 24 hours</div>
                                    )}
                                </div>
                            </div>
                            <div className='row'>
                                <div className='col-12'>
                                    <div className='show-service-check'>
                                        <input
                                            type="checkbox"
                                            className="custom-checkbox"
                                            checked={service.showOnWebCheckout}
                                            onChange={() => this.toogleShowServiceOnCheckout(service.showOnWebCheckout)}
                                        />
                                        <label>Show this service on Client Booking Page</label>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="divider"></div>
                    <div class="row modifier-header">
                        <div class="col-md-6 col-sm-6 col-6 modifier-icon-col px-3">
                            <div class='modifiers-component'>
                                <ModifierIcon color="#0E2036" />
                                <p>Modifiers</p>
                            </div>
                        </div>
                        <div class="col-md-6 col-sm-6 col-6">
                            <div class='choose-modifier'>
                                <div onClick={this.onAddModifier}>
                                    <PlusIcon width="30" height="30"/>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='row'>
                        <div className='col px-2'>
                            <div class='pre-inspection-check'>
                                <input
                                    type="checkbox"
                                    className="custom-checkbox"
                                    checked={service.showPrices}
                                    onChange={() => this.toogleShowPrizeToClient(service.showPrices)}
                                />
                                <label>Show Modifier Prices on Client Booking Page</label>
                            </div>
                        </div>
                    </div>
                    <ExpandableList selectedModifiers={service.modifiers} onDeleteItem={this.onDeleteItem} onModifierChange={this.onModifierChange} />
                </div>
                <SelectModifierModal show={show} selectedModifiers={service.modifiers} allModifiers={allModifiers} onSelectModifierDone={this.onSelectModifierDone} close={this.closeModifierModal} device={"desktop"} />
            </div>
        );
    }
}


const mapStateToProps = (state) => {
    return {
        services: state.appData.services,
        user: state.authData.user
    };
};
const mapDispatchToProps = (dispatch) => {
    return {
        addMedia: (object, cb) => dispatch(addMedia(object, cb)),
        deleteMedia: (object, cb) => dispatch(deleteMedia(object, cb)),
        addService: (service, cb) => dispatch(addService(service, cb)),
        getServiceResource: (params, cb) => dispatch(getServiceResource(params, cb)),
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(NewService);

// const TextFeild = ({ initialValue, label, type, placeholder, onChange, readOnly, isValid, errorMessage, onMinChange, onHourChange, hour, min }) => {
//     const numberInputOnWheelPreventChange = (e) => {
//         e.target.blur()
//         e.stopPropagation()
//         setTimeout(() => {
//             e.target.focus()
//         }, 0)
//     }

//     return (
//         <div className='textfeild-component'>
//             <p className='textfeildlabel'>{label}</p>
//             <input
//                 type={type}
//                 value={initialValue}
//                 className="text-field"
//                 placeholder={placeholder}
//                 min="0"
//                 onChange={onChange}
//                 onWheel={numberInputOnWheelPreventChange}
//                 readOnly={readOnly}
//             />
//             {isValid && <div className="error-message">{errorMessage}</div>}
//         </div>
//     );
// };



