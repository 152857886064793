import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import Modal from 'react-bootstrap/Modal';
import { Button as Btn } from "../../components/button/button";
import { ExpandTileIcon, UnExpandTileIcon, EditIcon, CrossIcon } from "../../icons/Icons";

class SelectModifierModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      show: false,
      saveBtnDisabled: true,
      allModifiers: [],
      selectedModifiers: [],
      expandedModifiers: {}
    };
  }

  componentDidMount() {
    const { show, allModifiers, selectedModifiers } = this.props;
    this.setState({ show, allModifiers, selectedModifiers });
  }

  componentWillReceiveProps(nextProps) {
    const { show, allModifiers, selectedModifiers } = nextProps;
    this.setState({ show, allModifiers, selectedModifiers });
  }

  handleTileClick = (modifierId) => {
    const { expandedModifiers } = this.state;
    this.setState(prevState => ({
      expandedModifiers: {
        ...prevState.expandedModifiers,
        [modifierId]: !prevState.expandedModifiers[modifierId]
      }
    }));
  };

  handleCheckboxClick = (modifier) => {
    const { selectedModifiers } = this.state;
    const { id } = modifier;
    const index = selectedModifiers.findIndex(selectedModifier => selectedModifier.id === id);
    if (index !== -1) {
      this.setState({
        selectedModifiers: selectedModifiers.filter(selectedModifier => selectedModifier.id !== id)
      });
    } else {
      this.setState({
        selectedModifiers: [...selectedModifiers, modifier]
      });
    }
  };


  handleDoneClick = () => {
    const { allModifiers, selectedModifiers } = this.state;
    // const selectedModifiersList = allModifiers.filter(modifier => selectedModifiers.includes(modifier.id));
    this.props.onSelectModifierDone(selectedModifiers);
    this.setState({ show: false });
  };

  close = () => {
    this.setState({ show: false });
    this.props.close();
  }

  render() {
    const { show, allModifiers, selectedModifiers, expandedModifiers } = this.state;
    const modalClassName = `select-modifier-${this.props.device === "mobile" ? "mobile" : "desktop"}`;
    const containerClassName = this.props.device === "mobile" ? "column-container" : "grid-container";
    return (
      <>
        <Modal
          show={show}
          onHide={() => this.close()}
          dialogClassName="modal-80w itemEdit select-modifier-modal"
          aria-labelledby="template-item-edit"
          backdrop="static"
          keyboard={false}
        >
          <Modal.Header>
            <Modal.Title id="template-item-edit" className="modal-title">
              <h4>Select Modifiers</h4>
              <span onClick={this.close}><CrossIcon /></span>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body className="select-modifier-modal-body">
            {
              allModifiers.length > 0 ?
                <div>
                  {allModifiers.map((modifier, index) => (
                    <Row key={modifier.id}>
                      <Col>
                        <div className="tile">
                          <div className='select-mod-list-item' onClick={() => this.handleTileClick(modifier.id)}>
                            <input
                              type="checkbox"
                              className="custom-checkbox"
                              checked={selectedModifiers.some(selectedModifier => selectedModifier.id === modifier.id)}
                              onClick={(e) => e.stopPropagation()}
                              onChange={() => this.handleCheckboxClick(allModifiers[index])}
                            />
                            <div className='tile-actions'>
                              <span className="item-name">{modifier.modifier_name}</span>
                              <div className="icons-container">
                                {expandedModifiers[modifier.id] ? <UnExpandTileIcon /> : <ExpandTileIcon />}
                              </div>
                            </div>
                          </div>
                          {expandedModifiers[modifier.id] && (
                            <div className='select-mod-sub-items'>
                              {modifier.options.map((option, subIndex) => (
                                <div key={subIndex} className='sub-item'>
                                  <p className='option-name'>{`${option.option_name}`}
                                  </p>
                                  <p className="option-value">{`+ $${option.option_value}`}</p>
                                </div>
                              ))}
                            </div>
                          )}
                        </div>
                      </Col>
                    </Row>
                  ))}
                </div> :
                <div className='Empty-Section'>
                  Zero Listed Modifiers
                </div>
            }
          </Modal.Body>
          <Modal.Footer>
            <Btn className={`template-item-edit`}
              label={`Done`}
              showLeftIcon={false}
              showRightIcon={false}
              size="large"
              state="default"
              variant="filled"
              type="primary"
              clickEvent={(e) => this.handleDoneClick()}
            />
          </Modal.Footer>
        </Modal>
      </>
    );
  }
}

export default SelectModifierModal;
