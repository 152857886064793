/* eslint-disable react/no-direct-mutation-state */
import React, { Component } from "react";
import { Button as Btn } from "../../../components/button/button";
import { EditIconWithoutBackdrop, DisabledEditIconWithoutBackdrop, AdminTemplateIcon } from "../../../icons/Icons";
import _ from "lodash";


class ServiceDropDown extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isOpen: false,
      selectedOptions: [],
      title: this.props.title,
      hintText: this.props.hintText,
      itemEditable: false,
      expandedTemplateItem: null,
      expandedTemplateItemId: null,
      templateFeesValidation: false,
      doneType: null
    };

    this.uniqueId = `custom-dropdown-${Math.floor(Math.random() * 10000)}`;
  }

  componentDidMount() {
    window.addEventListener("click", this.handleWindowClick);
    this.state.selectedOptions = this.props.selectedOptions;
    this.setState(this.state);
  }

  componentWillReceiveProps(nextProps) {
    this.setState({ selectedOptions: nextProps.selectedOptions });
  }

  componentWillUnmount() {
    window.removeEventListener("click", this.handleWindowClick);
  }

  toggleDropdown = () => {
    this.setState((prevState) => ({
      isOpen: !prevState.isOpen,
    }));
  };


  handleOptionClick = (option, checkType) => {
    const { selectedOptions } = this.state;
    let updatedOptions;
    // If the option is already selected, remove it; otherwise, add it
    if (selectedOptions.some((selectedOption) => selectedOption.id === option.id) && checkType != "save") {
      updatedOptions = selectedOptions.filter((selectedOption) => selectedOption.id !== option.id);
    } else {
      const isThere = selectedOptions.some((selectedOption) => selectedOption.id === option.id);
      if (isThere) {
        //if the option is already selected, and user is just updating the fees then find the index and replace the object in the list
        const index = selectedOptions.findIndex(item => item.id === option.id);
        selectedOptions[index] = option;
        updatedOptions = selectedOptions;
      } else {
        updatedOptions = [...selectedOptions, option];
      }
    }
    this.state.selectedOptions = updatedOptions;
    this.setState(this.state);
    this.props.onOptionsChange(updatedOptions);
  };


  handleOptionChangeTemplate = (option, type) => {
    if (this.state.selectedOptions.some((selectedOption) => selectedOption.id === option.id) || option.fees > 0) {
      this.handleOptionClick(option, type);
      this.setState({
        itemEditable: false,
        expandedTemplateItemId: null,
        doneType: null,
      });
    } else {
      this.onEdit(option);
    }
  }

  onfeesChange = (event, template) => {
    const updatedTemplate = _.merge({}, template);
    updatedTemplate.fees = parseFloat(event.target.value);
    this.setState(prevState => ({
      expandedTemplateItem: updatedTemplate
    }));
  }


  onEdit = (template, type, event) => {
    if (type === "edit") event.stopPropagation();
    const { expandedTemplateItemId } = this.state;
    if (expandedTemplateItemId === template.id) {
      this.setState({
        itemEditable: false,
        expandedTemplateItemId: null,
        doneType: null,
      });
    } else {
      this.state.itemEditable = true;
      this.state.expandedTemplateItemId = template.id;
      this.state.templateFeesValidation = false;
      this.state.doneType = type;
      this.state.expandedTemplateItem = _.merge({}, template);
      this.setState(this.state)
      this.handleOptionClick(this.state.expandedTemplateItem, "save");
    }
  };

  onSave = (event, option, type) => {
    event.stopPropagation();
    if (this.state.expandedTemplateItem && this.state.expandedTemplateItem.fees >= 0) {
      this.handleOptionClick(this.state.expandedTemplateItem, "save");
      this.state.expandedTemplateItem.fees = this.state.expandedTemplateItem.fees.toFixed(2);
      this.replaceObjects();
      this.setState({
        itemEditable: false,
        expandedTemplateItemId: null,
        templateFeesValidation: false,
        expandedTemplateItem: this.state.expandedTemplateItem
      });
    } else {
      this.setState({ templateFeesValidation: true });
    }
  }


  handleWindowClick = (event) => {
    const dropdown = document.getElementById(this.uniqueId);
    if (dropdown && !dropdown.contains(event.target)) {
      this.setState({
        isOpen: false,
      });
    }
  };

  numberInputOnWheelPreventChange = (e) => {
    e.target.blur()
    e.stopPropagation()
    setTimeout(() => {
      e.target.focus()
    }, 0)
  }


  replaceObjects = () => {
    this.state.selectedOptions.forEach(selectedObj => {
      let index = this.props.options.findIndex(obj => obj.id === selectedObj.id);
      if (index !== -1) {
        this.props.options[index] = selectedObj;
      }
    });
    this.setState(this.state);
  }

  render() {
    const { isOpen, selectedOptions, title, hintText, itemEditable, expandedTemplateItemId, templateFeesValidation } =
      this.state;
    const { options, type, user } = this.props;
    let displayText;
    if (selectedOptions.length === 0) {
      displayText = hintText !== null ? hintText : "Select options";
    } else if (type === "template") {
      const selectedText = selectedOptions.map((t) => t.templateName).join(", ");
      displayText = selectedText;
    } else {
      const selectedText = selectedOptions.map((t) => t.title).join(", ");
      displayText = selectedText;
    }

    const selectedIds = new Set(selectedOptions.map(obj => obj.id));

    const sortedObjects = options.sort((a, b) => {
      if (selectedIds.has(a.id) && !selectedIds.has(b.id)) {
          return -1;
      }
      if (!selectedIds.has(a.id) && selectedIds.has(b.id)) {
          return 1;
      }
      return 0;
  });

    return (
      <div>
        <p className="dropdown-title">{title}</p>
        <div className="custom-dropdown" id={this.uniqueId}>
          <button
            className={`dropdown-button ${isOpen ? "open" : ""}`}
            onClick={this.toggleDropdown}
          >
            {displayText}
          </button>
          {isOpen && (
            <ul className="dropdown-options">
              {
                sortedObjects.length > 0 ? (sortedObjects.map((option, index) =>
                  type == "template" ? (
                    //templates 
                    <li key={option.id}>
                      <div className="dropdown-list-item-template">
                        <div className="list-item-detail">
                          <input
                            type="checkbox"
                            className="custom-checkbox"
                            id={`${option.id}`}
                            checked={selectedOptions.some((selectedOption) => selectedOption.id === option.id)}
                            onChange={() => this.handleOptionChangeTemplate(option, "check")}
                          />
                          <div
                            onClick={() => this.handleOptionChangeTemplate(option, "check")}
                            className="dropdown-list-item-title"
                            htmlFor={`${this.uniqueId}-${option}`}>
                            {option.templateName}
                          </div>
                            {option.user !== user.id && <div style={{marginRight:'5px', marginBottom:'5px'}}>Admin</div> }
                          <label className={option.fees > 0 ? "dropdown-list-item-fees-blue" : "dropdown-list-item-fees-red"}>
                            ${option.fees}
                          </label>
                          <div onClick={(e) => this.onEdit(option, "edit", e)}>
                            {(itemEditable && expandedTemplateItemId === option.id) ? <DisabledEditIconWithoutBackdrop /> : <EditIconWithoutBackdrop />}
                          </div>
                        </div>

                        <>
                          {
                            itemEditable && expandedTemplateItemId == option.id ?
                              <div>
                                <div className="fees-details">
                                  <input onChange={(e) => this.onfeesChange(e, option)} value={this.state.expandedTemplateItem.fees} step="any" type={"number"} min="0" onWheel={this.numberInputOnWheelPreventChange}></input>
                                  <div onClick={(e) => this.onSave(e, option, "save")}>
                                    <Btn className={`template-item-edit`}
                                      label={`Done`}
                                      showLeftIcon={false}
                                      showRightIcon={false}
                                      size="large"
                                      state="default"
                                      variant="filled"
                                      type="primary"
                                    />
                                  </div></div>
                                {templateFeesValidation && <div className="error-message">Fees should be greater than or equal to 0</div>}
                              </div>
                              : <></>
                          }
                        </>
                      </div>
                    </li>
                  ) : type === "agreements" ? (
                    //agreements
                    <li key={option.id} className="dropdown-list-item" onClick={() => this.handleOptionClick(option, index)}>
                      <input
                        type="checkbox"
                        className="custom-checkbox"
                        id={`${this.uniqueId}-${option.id}`}
                        checked={selectedOptions.some((selectedOption) => selectedOption.id === option.id)}
                      />
                      <label htmlFor={`${this.uniqueId}-${option.title}`}>
                        {option.title}
                      </label>
                    </li>
                  ) : (
                    //upsells - services
                    <li key={option.id} className="dropdown-list-item" onClick={() => this.handleOptionClick(option, index)}>
                      <input
                        type="checkbox"
                        className="custom-checkbox"
                        id={`${this.uniqueId}-${option.id}`}
                        checked={selectedOptions.some((selectedOption) => selectedOption.id === option.id)}
                      />
                      <label htmlFor={`${this.uniqueId}-${option.id}`}>
                        {option.title}
                      </label>
                      <label
                        className="dropdown-list-item-fees-blue"
                        htmlFor={`${this.uniqueId}-${option.id}`}
                      >
                        {`$${option.price}`}
                      </label>
                    </li>
                  ))
                ) : <>{`No ${type} found`}</>}
            </ul>
          )}
        </div>
      </div>
    );
  }
}

export default ServiceDropDown;