import React from "react";
import { Container } from "react-bootstrap";
import Modal from 'react-bootstrap/Modal';
import { Button as Btn } from "../../components/button/button";
import { PlusIcon, TrashIcon } from "../../icons/Icons";
import _ from "lodash";
import { CrossIcon } from "../../icons/Icons";

class AddEditModifierModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            show: false,
            modifier: {
                modifier_name: "",
                options: [{ option_name: "", option_value: 0 }],
            },
            validationErrors: {
                modifierName: false,
                optionNames: [false],
                optionValues: [false],
            },
            isSaving: false,
        }
    }

    componentDidMount = () => {
        let modifier = this.props.modifier;
        modifier.options = this.props.modifier.options.map((o) => {
            return {
                option_name: o.option_name,
                option_value: typeof o.option_value === "string" ? parseFloat(o.option_value).toFixed(2) : o.option_value.toFixed(2)
            }
        })
        this.setState({ show: this.props.show, modifier: modifier });
    }

    componentWillReceiveProps = (nextProps) => {
        let modifier = nextProps.modifier;
        if(nextProps.modifier && nextProps.modifier.options){
            modifier.options = nextProps.modifier.options.map((o) => {
                return {
                    option_name: o.option_name,
                    option_value: parseFloat(o.option_value).toFixed(2)
                }
            });
        }
        this.setState({ show: nextProps.show, modifier: modifier });
    }

    addNewModifierOption = () => {
        const { modifier } = this.state;
        modifier.options.push({ option_name: "", option_value: "" });
        this.setState({ modifier });
    }

    onTitleChange = (e) => {
        const { modifier } = this.state;
        modifier.modifier_name = e.target.value;
        this.setState({ modifier });
    }

    onOptionNameChange = (index, e) => {
        const { modifier } = this.state;
        modifier.options[index].option_name = e.target.value;
        this.setState({ modifier });
    }

    onOptionValueChange = (index, e) => {
        const { modifier } = this.state;
        modifier.options[index].option_value = parseFloat(e.target.value);
        this.setState({ modifier });
    }

    close = () => {
        this.setState({ show: false });
    }

    onDeleteModifier = (index) => {
        if(this.state.modifier.options.length>1){
            const { modifier } = this.state;
            modifier.options.splice(index, 1);
            this.setState({ modifier });
        }
    }

    handleSave = () => {
        const { done } = this.props;
        const isValid = this.validateModifier();
        this.setState({ isSaving: true });
        if (isValid) {
            let modifier = this.state.modifier;
            modifier.options = this.state.modifier.options.map((o) => {
                return {
                    option_name: o.option_name,
                    option_value: parseFloat(o.option_value)
                }
            })
            done('Save', this.state.modifier);
        }
    }

    validateModifier = () => {
        const { modifier } = this.state;
        const { modifier_name, options } = modifier;

        // Validate modifier name
        const modifierNameValid = modifier_name.trim() !== "";

        // Validate option names and values
        const optionNamesValid = options.map(option => option.option_name.trim() !== "");
        const optionValuesValid = options.map(option => parseFloat(option.option_value) >= 0);

        // Update validation state for modifier name
        const validationErrors = {
            modifierName: !modifierNameValid,
            optionNames: optionNamesValid.map(valid => !valid),
            optionValues: optionValuesValid.map(valid => !valid),
        };
        this.setState({ validationErrors });

        // Return true if all fields are valid, otherwise false
        return modifierNameValid && optionNamesValid.every(valid => valid) && optionValuesValid.every(valid => valid);
    }


    render() {
        const { show, modifier, validationErrors, isSaving } = this.state;

        return (
            modifier.hasOwnProperty('modifier_name') ?
                <Modal
                    show={show}
                    onHide={this.close}
                    dialogClassName="modal-90w itemEdit modal-scope-modifier"
                    aria-labelledby="template-item-edit"
                    backdrop="static"
                    keyboard={false}
                >
                    <Modal.Header>
                        <Modal.Title id="template-item-edit" className="modal-title">
                            <h4>Add New Modifier</h4>
                            <span onClick={this.close}><CrossIcon /></span>
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <TextFeild
                            value={modifier.modifier_name}
                            label={"Modifier Set"}
                            placeholder={"Modifier Set"}
                            width={100}
                            onChange={this.onTitleChange}
                            isValid={!validationErrors.modifierName || !isSaving}
                            errorMessage="Please enter a modifier title"
                        />
                        <br />
                        <div className="divider"></div>
                        {modifier.options.map((option, index) => (
                            <div className="modifier-item-tile" key={index}>
                                <TextFeild
                                    value={option.option_name}
                                    label={index === 0 ? "Modifiers" : ""}
                                    placeholder="Modifier name"
                                    onChange={(e) => this.onOptionNameChange(index, e)}
                                    isValid={!validationErrors.optionNames[index] || !isSaving}
                                    errorMessage="Please enter a modifier name"
                                />
                                <TextFeild
                                    value={option.option_value}
                                    label={index === 0 ? "Price ($)" : ""}
                                    placeholder="Amount"
                                    type="number"
                                    step="0.05"
                                    onChange={(e) => this.onOptionValueChange(index, e)}
                                    isValid={!validationErrors.optionValues[index] || !isSaving}
                                    errorMessage="Please enter a valid price"
                                />
                                <div style={{ marginTop: index ===0 ? '45px':"25px", marginLeft:"-5px" }} className="trash-icon" onClick={() => this.onDeleteModifier(index)}>
                                    <TrashIcon />
                                </div>
                            </div>
                        ))}
                        <br />
                        <div className="scope-new-modifier" onClick={this.addNewModifierOption}>
                            <PlusIcon className="plus" />
                            <div className="scope-modifier-title">Add New</div>
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <Btn className={`template-item-edit`}
                            label={`Cancel`}
                            showLeftIcon={false}
                            showRightIcon={false}
                            size="large"
                            state="default"
                            variant="ghost"
                            type="primary"
                            clickEvent={(e) => this.props.done('Cancel', {})} />
                        <Btn className={`template-item-edit`}
                            label={`Save`}
                            showLeftIcon={false}
                            showRightIcon={false}
                            size="large"
                            state="default"
                            variant="filled"
                            type="primary"
                            clickEvent={this.handleSave}
                        />
                    </Modal.Footer>
                </Modal>
                :
                <></>
        );
    }
}

export default AddEditModifierModal;

const TextFeild = ({ label, type, placeholder, onChange, width, value, isValid, errorMessage, step }) => {
    const numberInputOnWheelPreventChange = (e) => {
        e.target.blur()
        e.stopPropagation()
        setTimeout(() => {
          e.target.focus()
        }, 0)
      }
      if(step){
        return (
            <div className='textfeild-component-1'>
                <label className='textfeildlabel'>{label}</label>
                <input
                    type={type}
                    onWheel={numberInputOnWheelPreventChange}
                    value={value}
                    className={`text-field-1 ${isValid ? '' : 'is-invalid'}`}
                    placeholder={placeholder}
                    onChange={onChange}
                    min="0"
                    step={step}
                />
                {!isValid && <div className="invalid-feedback">{errorMessage}</div>}
            </div>
        );
      } else {
          return (
              <div className='textfeild-component-1'>
                  <label className='textfeildlabel'>{label}</label>
                  <input
                      type={type}
                      // style={{ maxWidth: width + "%" }}
                      onWheel={numberInputOnWheelPreventChange}
                      value={value}
                      className={`text-field-1 ${isValid ? '' : 'is-invalid'}`}
                      placeholder={placeholder}
                      onChange={onChange}
                      min="0"
                  />
                  {!isValid && <div className="invalid-feedback">{errorMessage}</div>}
              </div>
          );
      }
};
