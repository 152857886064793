/* eslint-disable react/no-direct-mutation-state */
import React from 'react';
import { connect } from 'react-redux';
import { addMedia, saveAgreement, getAgreement, listAgreements } from '../../../redux/apiCalls/appData';
import Spinner from '../../shared/Spinner';
import { Button } from "../../components/button/button.js";
import toast from "react-hot-toast";
import BundledEditor from "../../components/editor/tinyEditor.jsx";
import Form from 'react-bootstrap/Form';
import AgreementImportModal from "../../components/modal/AgreementImportModal.jsx";
import { EditBlueIcon } from '../../icons/Icons';
class Agreement extends React.Component {
    constructor(props) {
        super(props);
        this.textfieldRef = React.createRef();
        this.state = {
            selectedAgreement:"",
            loading: true,
            action_process:false,
            agreement:{},
            currentAgreement: {},
            agreementNameEditState:false,
            clone: false,
            modalSettings:{
                show:false,
                agreements: this.props.agreements
            }
        };
        this.quillEditorRef = React.createRef();
    }
    componentDidMount = () => {
        let agreement_id = this.props.match.params.id;
        if(this.props.match.params.id.includes("clone=")){
            agreement_id = agreement_id.substring(6);
            this.state.clone = true;
        }
        this.state.currentAgreement = this.props.agreements.find(
            (agreement) => agreement.id === agreement_id
        );
        // console.log(this.props.agreements);
        // console.log(this.props.agreements.find(
        //     (agreement) => agreement.id === agreement_id
        // ));
        // console.log(this.state.currentAgreement);
        if(agreement_id === "master"){
            this.state.currentAgreement = {title: "Master"};
        }
        if(agreement_id === "new"){
            this.state.currentAgreement = {title: "Enter Title", description: 'Enter Description ', content: '<p>This is the initial content of the editor.</p>'};
        }
        if(agreement_id !== undefined && agreement_id !== "new") {
            this.props.listAgreements({id:agreement_id}, (data) => {
                if(data && data.length){
                    this.state.currentAgreement = data.find(
                        (agreement) => agreement.id === agreement_id
                    );
                    if(this.quillEditorRef && this.quillEditorRef.current){
                        this.quillEditorRef.current.setContent(this.state.currentAgreement.content);
                        this.state.loading = false;
                    }
                    this.state.agreement = this.state.currentAgreement;
                    this.state.selectedAgreement = agreement_id;
                    if(agreement_id === "master"){
                        this.state.currentAgreement = {title: "Master"};
                    }
                    if(agreement_id === "new"){
                        this.state.currentAgreement = {title: "Enter Title"};
                    }
                    if(this.props.match.params.id.includes("clone=")){
                        this.state.currentAgreement.title = `${this.state.currentAgreement.title} (copy)`;
                    }
                    this.setState(this.state);
                    // this.quillEditorRef.setEditorContent(data.content);
                    // this.setState({loading:false});
                } else {
                    this.setState({loading:false});
                }
            });
        // } else {
        }
        this.setState(this.state);
    }

    componentDidUpdate = () => {
        if(this.quillEditorRef && this.quillEditorRef.current && this.state.agreement && this.state.agreement.content)
            this.quillEditorRef.current.setContent(this.state.agreement.content);
    }

    // onChangeTemplate = (e) => {
    //     this.setState({selectedAgreement: e.target.value, loading:true});
    //     this.props.getAgreement({template:e.target.value}, (data) => {
    //         console.log(data);
    //         if(data){
    //             this.quillEditorRef.current.setContent(data.content);
    //             // this.quillEditorRef.setEditorContent(data.content);
    //             this.setState({agreement:data, loading:false, selectedAgreement: e.target.value});
    //             // this.setState({loading:false});
    //         } else {
    //             this.setState({loading:false});
    //         }
    //     });
    // }

    save = (e) => {
        const editorContent = this.quillEditorRef.current.getContent();
        this.state.currentAgreement.content = editorContent;
        this.setState({action_process:true});
        let callData = {
            agreement_id: this.state.selectedAgreement,
            title: this.state.currentAgreement.title,
            description: this.state.currentAgreement.description,
            // agreementType: this.props.user.userType === "inspector" ? "user" : "default",
            content: editorContent
        };
        let agreement_id = this.props.match.params.id;
        if(agreement_id !== undefined && agreement_id === "master") {
            callData.agreementType = "master";
            delete callData.template;
        } else {
            callData.agreementType = "user";
        }
        if(agreement_id === "new" && !this.state.currentAgreement.hasOwnProperty("id")){
            callData.agreementType = "user";
            delete callData.template;
            delete callData.agreement_id;
        }
        if(this.state.clone){
            callData.agreementType = "user";
            delete callData.template;
            delete callData.agreement_id;
            this.state.clone = false;
        }
        this.props.saveAgreement(callData, (data) => {
                    if(data){
                        toast.success("Agreement saved");
                        // this.state.currentAgreement = data;
                        this.state.selectedAgreement = data.id;
                        this.props.history.push('/agreements');
                        // this.state.currentAgreement.content = editorContent;
                    }
                    this.state.action_process = false;
                    this.setState(this.state);
                });
    }

    editorLoaded = (editor) => {
        this.quillEditorRef.current = editor;
        if(this.quillEditorRef && this.quillEditorRef.current && this.state.agreement && this.state.agreement.content){
            setTimeout(() => {
                this.quillEditorRef.current.setContent(this.state.agreement.content);
                this.setState({loading: false});
            }, 500);
        } else {
            this.setState({loading: false});
        }
    }

    focusOut = (evt, editor) => {
        // this.state.currentAgreement.content = editor.getContent();
        // this.setState(this.state);
    }

    openDailog = (e) => {
        this.state.modalSettings.show = true;
        this.state.modalSettings.agreements = this.props.agreements;
        this.setState(this.state);
    }

    closeModal = (id) => {
        if(id !== "Cancel"){
            this.setState({loading:true});
            this.props.getAgreement({id:id}, (data) => {
                if(data){
                    this.quillEditorRef.current.setContent(data.content);
                    // this.state.agreement = data;
                } else {
                    toast.error("Something went wrong! please try again.");
                }
            });
        }
        this.state.loading = false;
        this.state.modalSettings.show = false;
        this.setState(this.state);
    }

    uploadImage = (e, callback) => {
        var myPromise = new Promise( (resolve, reject) => {
            const file = e.target.files[0];
            let formData = new FormData();
            formData.append('mediaType', 'inspectionAgreement');
            formData.append('image', file);
            this.props.addMedia(formData, (data)=> {
                if(data){
                    callback(data[0].originalImgUrl, { title: file.name });
                    resolve("Promise resolved successfully");
                }else{
                    // TODO:::
                    reject(Error("Promise rejected"));
                }
            });
         });
         toast.promise(myPromise, {
            loading: 'Image is being uploaded...',
            success: 'Image uploaded successfully.',
            error: 'Something went wrong, please try logging out and login again!',
          });
    }

    onChangeAgreementInfo = (e, field) => {
        this.state.currentAgreement[field] = e.target.value;
        this.setState(this.state);
    }

    changeAgreementFieldType = () => {
        this.setState({agreementNameEditState: !this.state.agreementNameEditState})
        setTimeout(() => {
            if(this.textfieldRef.current){
                this.textfieldRef.current.focus();
            }
        }, 500);
    }

    render = () => {
        const { currentAgreement } = this.state;
        return (
            <div style={{ overflowX: 'hidden' }}>
                {this.state.loading && <Spinner />}
                <div className="scope-services-heading">
                    <div className="scope-templetes-title">
                        {this.state.agreementNameEditState ?
                            <Form.Control
                                type="text"
                                value={currentAgreement.title}
                                ref={this.textfieldRef}
                                onChange={(e) => this.onChangeAgreementInfo(e, "title")}
                                className="templateName-edit" />
                            : currentAgreement.title}
                        <div className="editIcon" onClick={(e) => this.changeAgreementFieldType()}>
                            {this.state.agreementNameEditState ? <></>
                                // <CheckIcon className="scope-svg-menu-icon cursor" icon={this.state.agreementNameEditState === true ? "check" : "uncheck"} />
                                :
                                <EditBlueIcon title="Edit Template Title" />
                            }
                        </div>
                    </div>
                    <div className='new-service-actions'>
                        {
                            this.state.agreementNameEditState ?
                                <Button
                                    className="templateName-edit-buttons"
                                    label="Done"
                                    showLeftIcon={false}
                                    showRightIcon={false}
                                    size="small"
                                    state="default"
                                    variant="filled"
                                    type="primary"
                                    clickEvent={(e) => this.changeAgreementFieldType()}
                                />
                                : <>
                                    <Button
                                        className="save-agreement"
                                        label={`Import`}
                                        showLeftIcon={true}
                                        showRightIcon={false}
                                        size="small"
                                        state="default"
                                        variant="outlined"
                                        type="secondary"
                                        clickEvent={(e) => this.openDailog(e)}
                                    />
                                    <Button
                                        className="save-agreement"
                                        label={`Save`}
                                        showLeftIcon={true}
                                        showRightIcon={false}
                                        size="small"
                                        state="default"
                                        variant="filled"
                                        type="primary"
                                        disabled={this.state.action_process}
                                        clickEvent={(e) => this.save(e)}
                                    /></>
                        }
                    </div>
                </div>
                {/* <div className="row row-flex">
                    <div className="col-10">
                        <select name='templates' className="form-control" onChange={(e) => this.onChangeTemplate(e)}>
                            {templates.map((template, i)=> {
                                return <option value={template.id}>{template.title}</option>
                            })}
                        </select>
                    </div>
                    <div className="col-2">
                    <Button
                        className="save-agreement"
                        label={`Save`}
                        showLeftIcon={true}
                        showRightIcon={false}
                        size="small"
                        state="default"
                        variant="filled"
                        type="primary"
                        clickEvent={(e) => this.save(e)}
                    />
                    </div>
                </div> */}
                <div className="container edittemplatecard card">
                    <div className="row description-head">
                        <div className="col-md-12">
                            <div className="description-label mb-2">
                                Description
                            </div>
                            <div className="description-textarea">
                                <Form.Control
                                    as="textarea"
                                    placeholder="Template description goes here..."
                                    style={{ height: '60px' }}
                                    value={currentAgreement.description}
                                    onChange={(e) => this.onChangeAgreementInfo(e, "description")}
                                    />
                                {/* <textarea rows={4} value={template.templateDescription}>
                                    {template.templateDescription}
                                </textarea> */}
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row py-3">
                    <div className="col quileditor-container unlayer-editor-container ml-4 mr-4">
                        <BundledEditor
                            onInit={(evt, editor) => this.editorLoaded(editor)}
                            initialValue={this.state.currentAgreement.content}
                            onFocusOut={(evt, editor) => this.focusOut(evt, editor)}
                            init={{
                                height: 550,
                                min_height:500,
                                max_height:800,
                                menubar: false,
                                elementpath: false,
                                branding: false,
                                resize: false,
                                plugins: [
                                    'advlist', 'anchor', 'autolink', 'image', 'link', 'lists',
                                    'searchreplace', 'preview', 'table', 'wordcount', 'code', 'quickbars', 'pagebreak', 'save', 'preview'
                                ],
                                toolbar: 'blocks fontsize | ' +
                                    'bold italic underline strikethrough hr forecolor backcolor removeformat | alignleft aligncenter ' +
                                    'alignright alignjustify | lineheight | bullist numlist outdent indent | ' +
                                    'image link table refVars preview',
                                quickbars_selection_toolbar: 'bold italic underline fontsize | forecolor backcolor | quicklink',
                                quickbars_insert_toolbar: false,
                                quickbars_image_toolbar: 'alignleft aligncenter alignright | rotateleft rotateright | imageoptions',
                                toolbar_mode: 'wrap',
                                // toolbar_sticky: true,
                                // toolbar_sticky_offset: 100,
                                content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }',
                                advlist_bullet_styles: 'square',
                                advlist_number_styles: 'lower-alpha,lower-roman,upper-alpha,upper-roman',
                                file_picker_types: 'image',
                                file_picker_callback: (callback, value, meta) => {
                                    const input = document.createElement('input');
                                    input.setAttribute('type', 'file');
                                    input.setAttribute('accept', 'image/*');
                                    input.addEventListener('change', (e) => this.uploadImage(e, callback));
                                    input.click();
                                },
                                setup: (editor) => {
                                    editor.ui.registry.addMenuButton('refVars', {
                                    text: 'Variables',
                                    fetch: function (callback) {
                                        var items = [
                                            {
                                                type: 'nestedmenuitem',
                                                text: 'Client',
                                                getSubmenuItems: function () {
                                                return [
                                                    {
                                                        type: 'menuitem',
                                                        text: 'Full Name',
                                                        onAction: function () {
                                                            editor.insertContent('{{client.full_name}}');
                                                        }
                                                    },
                                                    {
                                                        type: 'menuitem',
                                                        text: 'First Name',
                                                        onAction: function () {
                                                            editor.insertContent('{{client.first_name}}');
                                                        }
                                                    },
                                                    {
                                                        type: 'menuitem',
                                                        text: 'Last Name',
                                                        onAction: function () {
                                                            editor.insertContent('{{client.last_name}}');
                                                        },
                                                    },
                                                    {
                                                        type: 'menuitem',
                                                        text: 'Email',
                                                        onAction: function () {
                                                            editor.insertContent('{{client.email}}');
                                                        },
                                                    },
                                                    {
                                                        type: 'menuitem',
                                                        text: 'Phone',
                                                        onAction: function () {
                                                            editor.insertContent('{{client.phone}}');
                                                        },
                                                    },
                                                    {
                                                        type: 'menuitem',
                                                        text: 'Address',
                                                        onAction: function () {
                                                            editor.insertContent('{{client.address}}');
                                                        },
                                                    },
                                                    {
                                                        type: 'menuitem',
                                                        text: 'Signature',
                                                        onAction: function () {
                                                            editor.insertContent('{{client.sign_field}}');
                                                        },
                                                    }
                                                ];
                                                }
                                            },
                                            {
                                                type: 'nestedmenuitem',
                                                text: 'Inspector',
                                                getSubmenuItems: function () {
                                                return [
                                                    {
                                                        type: 'menuitem',
                                                        text: 'Full Name',
                                                        onAction: function () {
                                                            editor.insertContent('{{inspector.full_name}}');
                                                        }
                                                    },
                                                    {
                                                        type: 'menuitem',
                                                        text: 'First Name',
                                                        onAction: function () {
                                                            editor.insertContent('{{inspector.first_name}}');
                                                        }
                                                    },
                                                    {
                                                        type: 'menuitem',
                                                        text: 'Last Name',
                                                        onAction: function () {
                                                            editor.insertContent('{{inspector.last_name}}');
                                                        },
                                                    },
                                                    {
                                                        type: 'menuitem',
                                                        text: 'Email',
                                                        onAction: function () {
                                                            editor.insertContent('{{inspector.email}}');
                                                        },
                                                    },
                                                    {
                                                        type: 'menuitem',
                                                        text: 'Phone',
                                                        onAction: function () {
                                                            editor.insertContent('{{inspector.phone}}');
                                                        },
                                                    },
                                                    {
                                                        type: 'menuitem',
                                                        text: 'Company Logo',
                                                        onAction: function () {
                                                            editor.insertContent('{{inspector.company_logo}}');
                                                        },
                                                    },
                                                    {
                                                        type: 'menuitem',
                                                        text: 'Address',
                                                        onAction: function () {
                                                            editor.insertContent('{{inspector.address}}');
                                                        },
                                                    },
                                                    {
                                                        type: 'menuitem',
                                                        text: 'Signature',
                                                        onAction: function () {
                                                            editor.insertContent('{{inspector.sign_field}}');
                                                        },
                                                    },
                                                ];
                                                }
                                            },
                                            {
                                                type: 'nestedmenuitem',
                                                text: 'Inspection',
                                                getSubmenuItems: function () {
                                                return [
                                                    {
                                                        type: 'menuitem',
                                                        text: 'ID',
                                                        onAction: function () {
                                                            editor.insertContent('{{inspection.id}}');
                                                        }
                                                    },
                                                    {
                                                        type: 'menuitem',
                                                        text: 'Date',
                                                        onAction: function () {
                                                            editor.insertContent('{{inspection.date}}');
                                                        }
                                                    },
                                                    // {
                                                    //     type: 'menuitem',
                                                    //     text: 'Type',
                                                    //     onAction: function () {
                                                    //         editor.insertContent('{{inspection.type}}');
                                                    //     }
                                                    // },
                                                    {
                                                        type: 'menuitem',
                                                        text: 'Fees',
                                                        onAction: function () {
                                                            editor.insertContent('{{inspection.fees}}');
                                                        }
                                                    },
                                                    {
                                                        type: 'menuitem',
                                                        text: 'Payment Method',
                                                        onAction: function () {
                                                            editor.insertContent('{{inspection.payment_method}}');
                                                        }
                                                    },
                                                    {
                                                        type: 'menuitem',
                                                        text: "Today's Date",
                                                        onAction: function () {
                                                            editor.insertContent('{{inspection.todays_date}}');
                                                        }
                                                    },
                                                    {
                                                        type: 'menuitem',
                                                        text: 'Address',
                                                        onAction: function () {
                                                            editor.insertContent('{{inspection.address}}');
                                                        },
                                                    }
                                                ];
                                                }
                                            },
                                            {
                                                type: 'nestedmenuitem',
                                                text: 'Invoice',
                                                getSubmenuItems: function () {
                                                return [
                                                    {
                                                        type: 'menuitem',
                                                        text: 'ID',
                                                        onAction: function () {
                                                            editor.insertContent('{{invoice.id}}');
                                                        }
                                                    },
                                                    {
                                                        type: 'menuitem',
                                                        text: 'Due Date',
                                                        onAction: function () {
                                                            editor.insertContent('{{invoice.due_date}}');
                                                        }
                                                    }
                                                ];
                                                }
                                            },
                                        ];
                                        callback(items);
                                    }
                                    });
                                }
                            }}
                        />
                    </div>
                </div>
                <AgreementImportModal modalSettings={this.state.modalSettings} close={this.closeModal} />
            </div>
        );
    }
}
 
const mapStateToProps = (state) => {
    return {
        agreements: state.appData.agreements,
        rowsPerPage: state.appData.rowsPerPage,
        user: state.authData.user
    };
};
const mapDispatchToProps = (dispatch) => {
    return {
        addMedia: (object, cb) => dispatch(addMedia(object, cb)),
        saveAgreement: (template, cb) => dispatch(saveAgreement(template, cb)),
        getAgreement: (template, cb) => dispatch(getAgreement(template, cb)),
        listAgreements: (template, cb) => dispatch(listAgreements(template, cb))
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Agreement);