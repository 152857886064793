/* eslint-disable react/no-direct-mutation-state */
import { all } from "axios";
import _ from "lodash";
import React from "react";
import Modal from 'react-bootstrap/Modal';
import { Button as Btn } from "../../components/button/button";
import { CrossIcon } from "../../icons/Icons";



class AddEditDiscountModal extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            show: false,
            saveBtnDisabled: true,
            allServices: [],
            selectedAllServices: false,
            coupon: {
                title: "",
                code: "",
                expiry: 0,
                limit: "0",
                type: "percent",
                value: "",
                enabled: false,
                services: []
            },
            validationErrors: {
                couponTitle: false,
                couponCode: false,
                couponLimit: false,
                couponType: false,
                couponValue: false,
                services: false,
            },
        }
    }
    componentDidMount = async () => {
        this.setState({ show: this.props.show, coupon: this.props.coupon, allServices: this.props.allServices });
    }
    componentWillReceiveProps(nextProps) {
        this.setState({ show: nextProps.show, coupon: nextProps.coupon, allServices: nextProps.allServices });
        let check = nextProps.coupon.services.length === nextProps.allServices.length;
        this.setState({ selectedAllServices: check });
    }

    onTitleChange = (e) => {
        this.state.coupon.title = e.target.value;
        this.setState(this.state);
    }

    onCodeChange = (e) => {
        this.state.coupon.code = e.target.value;
        this.setState(this.state);
    }

    onChangeLimit = (e) => {
        this.state.coupon.limit = e.target.value.replace(/[^\d]/,'');
        this.setState(this.state);
    }

    onChangeDiscountValue = (e) => {
        const newValue = e.target.value;
        const max = this.state.coupon.type === "flat" ? "" : 100;

        // Ensure the entered value doesn't exceed the maximum
        const sanitizedValue = Math.min(newValue, max === "" ? Infinity : max);

        this.setState(prevState => ({
            coupon: {
                ...prevState.coupon,
                value: sanitizedValue === 0 ? "" : parseFloat(sanitizedValue.toFixed(2))
            }
        }));
    }

    handleDiscountTypeChange = (e) => {
        this.state.coupon.type = e.target.value;
        this.state.coupon.value = "";
        this.setState(this.state);
    }

    close = () => {
        this.setState({ show: false });
    }

    validateCoupon = () => {
        const { coupon } = this.state;
        const { title, code, limit, type, value, services } = coupon;
        const titleValid = title.trim() !== "";
        const codeValid = code.trim() !== "";
        const limitValid = limit >= 0;
        const typeValid = type.trim() !== "";
        const valueValid = value > 0;
        const servicesValid = services.length > 0;

        const validationErrors = {
            title: !titleValid,
            code: !codeValid,
            limit: !limitValid,
            type: !typeValid,
            value: !valueValid,
            services: !servicesValid,
        };
        this.setState({ validationErrors });
        return titleValid && codeValid && limitValid && typeValid && valueValid && servicesValid;
    }

    onSave = () => {
        if (this.state.coupon.expiry === "" || isNaN(this.state.coupon.expiry)) {
            this.state.coupon.expiry = "0";
            this.setState(this.state);
        }
        const isValid = this.validateCoupon();
        this.setState({ isSaving: true });
        if (isValid) {
            this.props.done('Save', this.state.coupon);
        }
    }

    handleServiceChange = (event, serviceId) => {
        const { checked } = event.target;
        this.setState((prevState) => {
            let updatedServices;
            if (checked) {
                updatedServices = [...prevState.coupon.services, serviceId];
            } else {
                updatedServices = prevState.coupon.services.filter((id) => id !== serviceId);
            }
            const allServicesSelected = updatedServices.length === this.props.allServices.length;
            return {
                coupon: {
                    ...prevState.coupon,
                    services: updatedServices,
                },
                selectedAllServices: allServicesSelected,
            };
        });
    };



    handleSelectAllChange = (event) => {
        const { checked } = event.target;
        this.setState(prevState => ({
            coupon: {
                ...prevState.coupon,
                services: checked ? this.state.allServices.map(service => service.id) : [],
            },
            selectedAllServices: checked,
        }));
    };


    handleExpiryChange = (event) => {
        const selectedDate = new Date(event.target.value);
        const today = new Date();
        this.state.coupon.expiry = selectedDate.getTime();
        this.setState(this.state);
    };

    formatDate(timestamp) {
        if (timestamp === 0) {
            return "";
        }
        const date = new Date(timestamp);
        const day = String(date.getDate()).padStart(2, '0');
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const year = date.getFullYear();
        return `${year}-${month}-${day}`;
    }

     numberInputOnWheelPreventChange = (e) => {
        e.target.blur()
        e.stopPropagation()
        setTimeout(() => {
            e.target.focus()
        }, 0)
    }
    render = () => {
        const { show, coupon, allServices, selectedAllServices, validationErrors, isSaving } = this.state;
        let errorMessage = 'Please enter a value';
        return (
            <>
                <Modal
                    show={show}
                    onHide={() => this.close()}
                    dialogClassName="modal-80w itemEdit modal-scope-modifier"
                    aria-labelledby="template-item-edit"
                    backdrop="static"
                    keyboard={false}
                >
                    <Modal.Header>
                        <Modal.Title id="template-item-edit" className="modal-title">
                            <h4>Create / Edit Discount Code</h4>
                            <span onClick={this.close}><CrossIcon /></span>
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        {/* <Container> */}
                        <TextFeild
                            value={coupon.title}
                            label={"Title"}
                            placeholder={"Enter discount title"}
                            isValid={validationErrors.title}
                            errorMessage="Please enter a Coupon Name"
                            onChange={this.onTitleChange}
                        />
                        <br />
                        <div className="discount-modal-row-1">
                            <TextFeild
                                label={"Code"}
                                value={coupon.code}
                                placeholder={"Set Code"}
                                isValid={validationErrors.code}
                                errorMessage="Please enter a Coupon code"
                                onChange={this.onCodeChange}
                            />
                            <TextFeild
                                label={"Expiry (Blank for Lifetime)"}
                                value={this.formatDate(coupon.expiry)}
                                type = "date"
                                placeholder={"Set Code"}
                                onChange={this.handleExpiryChange}
                            />
                        </div>
                        <br />
                        <div className="discount-modal-row-1">
                            <TextFeild
                                label={"Utilization"}
                                type="number"
                                placeholder={"Limit ( 0 for unlimited)"}
                                value={coupon.limit}
                                steps={1}
                                isValid={validationErrors.limit}
                                errorMessage="Please set Limit"
                                onChange={this.onChangeLimit}
                            />
                            <div className="discount-input">
                                {/* <p id="discount-input-title">Type</p> */}
                                <div id="discount-input-title">Type</div>
                                <div className="input-group-1">
                                    <input
                                        type="number"
                                        id="discountValue"
                                        value={coupon.value}
                                        placeholder="Discount"
                                        min="0"
                                        onWheel={this.numberInputOnWheelPreventChange}
                                        onChange={this.onChangeDiscountValue}
                                    />
                                    <select id="discountType" value={coupon.type} onChange={this.handleDiscountTypeChange}>
                                        <option value="percent">%</option>
                                        <option value="flat">$</option>
                                    </select>
                                </div>
                                {validationErrors.value && (<div className="error-message">{errorMessage}</div>)}
                            </div>
                        </div>
                        <div className="discount-modal-row-2">
                            <p>Services</p>
                            <div className='discount-check'>
                                <input
                                    type="checkbox"
                                    className="custom-checkbox"
                                    checked={selectedAllServices}
                                    onChange={this.handleSelectAllChange}
                                />
                                <p>Select All</p>
                            </div>
                        </div>
                        {
                            (
                                <ul className="service-options">
                                    {_.isEmpty(allServices) ? <li className="service-items">No Services Found</li> :
                                        allServices.map((service) => (
                                            <li key={service.id} className="service-items">
                                                <input
                                                    type="checkbox"
                                                    className="custom-checkbox"
                                                    id={`${this.uniqueId}-${service.id}`}
                                                    checked={coupon.services.includes(service.id)}
                                                    onChange={(event) => this.handleServiceChange(event, service.id)}
                                                />
                                                <label htmlFor={`${this.uniqueId}-${service.id}`}>{service.title}</label>
                                            </li>
                                        ))}
                                </ul>
                            )
                        }
                        {validationErrors.services && (
                            <div className="error-message">Please select at least one service</div>
                        )}
                    </Modal.Body>
                    <Modal.Footer>
                        <Btn className={`template-item-edit`}
                            label={`Cancel`}
                            showLeftIcon={false}
                            showRightIcon={false}
                            size="large"
                            state="default"
                            variant="ghost"
                            type="primary"
                            clickEvent={(e) => this.props.done('Cancel', {})} />
                        <Btn className={`template-item-edit`}
                            label={`Save`}
                            showLeftIcon={false}
                            showRightIcon={false}
                            size="large"
                            state="default"
                            variant="filled"
                            type="primary"
                            clickEvent={(e) => this.onSave()}
                        />
                    </Modal.Footer>
                </Modal>
            </>
        );
    }
}


export default (AddEditDiscountModal);

const TextFeild = ({ label, type, placeholder, onChange, value, isValid, errorMessage }) => {
    const numberInputOnWheelPreventChange = (e) => {
        e.target.blur()
        e.stopPropagation()
        setTimeout(() => {
            e.target.focus()
        }, 0)
    }
    return (
        <div className='textfeild-component-1'>
            <label className='textfeildlabel'>{label}</label>
            <input
                type={type}
                value={value}
                className={"text-field-1"}
                placeholder={placeholder}
                onWheel={numberInputOnWheelPreventChange}
                onChange={onChange}
                min="0"
            />
            {isValid && <div className="error-message">{errorMessage}</div>}
        </div>
    );
};