import React, { Component } from 'react';
import { connect } from 'react-redux';
import { ServiceDefaultIcon, ServiceIcon, ModifierIcon, EditIcon, CopyIcon, TrashIcon, AddIconMobile } from '../../icons/Icons';
import { deleteService } from '../../../redux/apiCalls/appData';
class ViewService extends Component {
  constructor(props) {
    super(props);
    this.state = {
      service: {},
      windowWidth: window.innerWidth,
    };
  }

  componentDidMount = async () => {
    window.addEventListener('resize', this.handleResize);
    const { service } = this.props.location.state;
    this.setState({ service });
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleResize);
  }


  handleResize = () => {
    this.setState({ windowWidth: window.innerWidth });
  };

  newService = () => {
    this.props.history.push(`/new-service`);
  }

  cloneService = () => {
    let service = this.state.service;
    delete service.id;
    service.title = service.title + " (Copy)"
    console.log("this.props", this.props);
    console.log("service",service);
    this.props.history.push({
      pathname: '/new-service',
      state: { service }
    });
  }

  editService = () => {
    const { service } = this.state;
    this.props.history.push({
      pathname: '/new-service',
      state: { service }
    });
  }

  removeEmptyTags = (htmlContent) => {
    const tempElement = document.createElement('div');
    tempElement.innerHTML = htmlContent;

    const ps = tempElement.getElementsByTagName('p');
    for (let el of ps) {
      if (el.innerHTML === '&nbsp;') {
        el.parentNode.removeChild(el);
      }
    }
    return tempElement.innerHTML;
  }

  removeService = () => {
    this.props.deleteService(this.state.service.id, {}, () => {
      this.props.history.push('/services');
    });
  }

  render() {
    const { service, windowWidth } = this.state;
    const templates = service.templates ? service.templates.map(template => template.templateName) : [];
    const totalPrice = service.price;
    const agreements = service.agreements ? service.agreements.map(agreement => agreement.title) : [];
    const upsells = service.upsells ? service.upsells.map(upsell => upsell.title) : [];
    const modifiers = service.modifiers ? service.modifiers.map(modifier => modifier.modifier_name) : [];
    const serviceModifiers = service.modifiers;
    const durationString = service.duration ? `${Math.floor(service.duration / 60)} hours and ${service.duration % 60} minutes` : 'not defined';
    const blockExtraTime = service.extraTime ? `${service.extraTime} minutes` : `0 min`;
    const description = this.state.service && this.state.service.description ? this.state.service.description : '';
    return (
      <div>
        <div className="scope-services-heading">
          <div className="scope-services-title">Manage Services</div>
          <div onClick={this.newService}>
            {
              windowWidth < 700 ? <AddIconMobile /> : <AddIconMobile height="50" width="40" />
            }
          </div>
        </div>
        {
          windowWidth < 700 ? <div className="service-details-container">
            <div className='header-col-1-row-1'>
              <div className='header-col-1-row-1-title'>
                <ServiceIcon />
                <p className='Page-label' >Service Details</p>
              </div>
              <div className='header-col-1-row-1-actions'>
                <div onClick={this.editService}> <EditIcon /> </div>
                <div onClick={this.cloneService}> <CopyIcon /> </div>
                <div onClick={this.removeService}> <TrashIcon />  </div>
              </div>
            </div>
            <div className='header-col-1-mobile'>
              {service.icon ? (
                <>
                  <img src={service.icon.url} alt="Selected" className="selected-image" />
                </>
              ) : (
                <ServiceDefaultIcon />
              )}
            </div>
            <br />
            <label className='label'>Service Name</label>
            <p className='value'>{this.state.service.title}</p>
            <br />
            <label className='label'>Description</label>
            <p className='value' dangerouslySetInnerHTML={{ __html: this.removeEmptyTags(description) }}></p>
            <Divider />
            <DetailsCategory title="Templates" items={templates} />
            <Divider />
            <DetailsCategory title="Agreements" items={agreements} />
            <Divider />
            <DetailsCategory title="UpSells" items={upsells} />
            <Divider />
            <DetailsCategory title="Duration" items={[durationString]} />
            <Divider />
            <DetailsCategory title="Total Service Fee" items={[`$${totalPrice}`]} />
            <Divider />
            <DetailsCategory title="Block Extra Time" items={[blockExtraTime]} />
            <Divider />
            <div className='details-section-3'>
              <div className='modifiers-title'>
                <ModifierIcon />
                <p>Modifiers</p>
              </div>
              <ModifierItems items={serviceModifiers} />
            </div>

          </div> : <div className="service-details-container">
            <div className='header'>
              <div className='header-col-1'>
                {service.icon ? (
                  <>
                    <img src={service.icon.url} alt="Selected" className="selected-image" />
                  </>
                ) : (
                  <ServiceDefaultIcon />
                )}
              </div>
              <div className='header-col-2'>
                <div className='header-col-1-row-1'>
                  <div className='header-col-1-row-1-title'>
                    <ServiceIcon />
                    <p className='Page-label' >Service Details</p>
                  </div>
                  <div className='header-col-1-row-1-actions'>
                    <div onClick={this.editService}> <EditIcon /> </div>
                    <div onClick={this.cloneService}> <CopyIcon /> </div>
                    <div onClick={this.removeService}> <TrashIcon />  </div>
                  </div>
                </div>
                <label className='label'>Service Name</label>
                <p className='value'>{this.state.service.title}</p>
                <br />
                <label className='label'>Description</label>
                <p className='value' dangerouslySetInnerHTML={{ __html: this.removeEmptyTags(description) }}></p>
              </div>
            </div>
            <Divider />
            <div className='details-section-1'>
              <DetailsCategory title="Templates" items={templates} />
              <DetailsCategory title="Agreements" items={agreements} />
              <DetailsCategory title="UpSells" items={upsells} />
            </div>
            <Divider />
            <div className='details-section-2'>
              <DetailsCategory title="Duration" items={[durationString]} />
              <DetailsCategory title="Total Service Fee" items={[`$${totalPrice}`]} />
              <DetailsCategory title="Block Extra Time" items={[blockExtraTime]} />
            </div>
            <Divider />
            <div className='details-section-3'>
              <div className='modifiers-title'>
                <ModifierIcon />
                <p className='modifier-icon-title'>Modifiers</p>
              </div>
              <ModifierItems items={serviceModifiers} />
            </div>
          </div>
        }
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    services: state.appData.services
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    deleteService: (id, service, cb) => dispatch(deleteService(id, service, cb)),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(ViewService);


const Divider = () => {
  return <div className="divider"></div>;
};

class ModifierItems extends React.Component {
  render() {
    const { items } = this.props;
    // const modifierNames = items ? items.map(modifier => modifier.modifier_name) : [];
    return (
      <div>
        {(items == null || items.length <= 0) ? <p>Not Defined</p> : items.map((item, index) => (
          <div key={index}>
            <p style={{marginTop: '20px'}}><strong>{item.modifier_name}</strong></p>
            {item.options && item.options.length > 0 ? 
                <table>
                {item.options.map((option, i) => {
                  return <tr key={i}>
                      <td width={250}>{option.option_name}</td><td style={{color: '#2775ff'}}>+ ${option.option_value}</td>
                    </tr>
                })}
              </table>
              : <></>
            }
          </div>
        ))}
      </div>
    );
  }
}

class DetailsCategory extends React.Component {
  render() {
    const { title, items } = this.props;

    return (
      <div className='DetailsCategory-Componenet'>
        <p className='DetailsCategory-text-label'>{title}</p>
        {(items == null || items.length <= 0) ? <p>Not Defined</p> : items.map((item, index) => (
          <p key={index} className='DetailsCategory-text-value'>{item}</p>
        ))}
      </div>
    );
  }
}