import _ from 'lodash';
import ACTIONS from '../actions/appAction.js';
const initialState = {
    loader: false,
    templates: [],
    items: [],
    alertPopup: {
        open: false,
        message: '',
        color: 'success'
    },
    rowsPerPage: 20,
    users: [],
    entries: [],
    plans: [],
    comments: [],
    inspections: [],
    contacts: [],
    reportSent:[],
    emailHooks: [],
    modifiers: [],
    services:[],
    coupons: [],
    agreements: [],
};

// eslint-disable-next-line import/no-anonymous-default-export
export default function (state = initialState, action) {
    switch (action.type) {
        case ACTIONS.Types.LOADER:
            return (state = { ...state, loader: action.payload });
        case ACTIONS.Types.DELETE_TEMPLATES:
            return (state = { ...state, templates: initialState.templates });
        case ACTIONS.Types.SET_ALL_TEMPLATES:
            // return (state = {...state, templates: action.payload});
            return (state = {...state, templates: state.templates.concat(action.payload)});
        case ACTIONS.Types.SET_MODIFIERS:
            return (state = {...state, modifiers: action.payload});
        case ACTIONS.Types.SET_SERVICES:
            return (state = {...state, services: action.payload});
        case ACTIONS.Types.SET_COUPONS:
            return (state = {...state, coupons: action.payload});
        case ACTIONS.Types.SET_ITEMS:
            return (state = {...state, items: action.payload});
        case ACTIONS.Types.SET_USERS:
            return (state = {...state, users: action.payload});
        case ACTIONS.Types.SET_EMAIL_HOOKS:
            return (state = {...state, emailHooks: action.payload});
        case ACTIONS.Types.SET_AGREEMENTS:
            return (state = {...state, agreements: action.payload});
        case ACTIONS.Types.SET_ENTRIES:
            return (state = {...state, entries: action.payload});
        case ACTIONS.Types.SET_PLANS:
            return (state = {...state, plans: action.payload});
        case ACTIONS.Types.SET_USER_INSPECTIONS:
            return (state = {...state, inspections: action.payload});
        case ACTIONS.Types.SET_CONTACT:
            return (state = {...state, contacts: action.payload});
        case ACTIONS.Types.SEND_INSPECTION_REPORT:
            return (state = {...state, reportSent: action.payload});
        case ACTIONS.Types.SET_USER:
            _.each(state.users, (user, i) => {
                if(user.id === action.payload.id) {
                    state.users[i] = action.payload;
                }
            });
            return (state = { ...state, users: [...state.users]});
        case ACTIONS.Types.ALERT_POPUP:
            return {...state, alertPopup: action.data};
        case ACTIONS.Types.ROWS_PER_PAGE:
            return {...state, rowsPerPage: action.data};
        case ACTIONS.Types.SET_COMMENTS:
            return (state = {...state, comments: action.payload});
        default:
            return state;
    }
}
