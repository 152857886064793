/* eslint-disable react/no-direct-mutation-state */
import { Component, React } from 'react';
import { connect } from 'react-redux';
import CustomTable from "../../components/tables/CustomTable";
import { PlusIcon, EmptyScreenIcon, AddIconMobile, DragSortIcon } from '../../icons/Icons';
import AddEditModifierModal from '../../components/modal/addModifier';
import { addModifier, deleteModifier, getAllModifiers, updateModifier, updateMultipleModifiers } from '../../../redux/apiCalls/appData';
import _ from "lodash";
import { setRowsPerPage } from '../../../redux/actions/appAction';
import Spinner from '../../shared/Spinner';
import toast from 'react-hot-toast';
import DeleteWarningModal from '../../components/modal/deleteWarningModal';
class Modifiers extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showModal: false,
            page: 0,
            input: '',
            sort: 'ASC',
            modifiers: [],
            modifier: {
                id: "",
                modifier_name: "",
                options: [
                    {
                        "option_name": "",
                        "option_value": 0
                    }
                ]

            },
            loading: true,
            totalRecords: 0,
            rowsPerPage: 20,
            user: {},
            type: '',
            windowWidth: window.innerWidth,
            activeType: '',
            deleteWarningModal: {
                show: false,
                title: "",
                body: "",
                services: [],
                type: "",
                id: "",
                deletable:true
            }
        };
    }

    componentDidMount = () => {
        window.addEventListener('resize', this.handleResize);
        this.getAllModifiers();
    }


    getAllModifiers = async () => {
        this.props.getAllModifiers(
            {
                sort: this.state.sort,
                page: this.state.page,
                rowsPerPage: this.state.rowsPerPage,
            }, (data) => {
                if (data.records.length > 0) {
                    this.state.modifiers = data.records;
                    this.state.totalRecords = data.totalRecords;
                }
                this.state.loading = false;
                this.setState(this.state);
            });
    }

    handleChangePage = (page) => {
        this.setState({ page, loading: true });
        this.props.getAllModifiers(
            {
                sort: this.state.sort,
                page: page,
                rowsPerPage: this.state.rowsPerPage,
            },
            (data) => {
                if (data.records.length > 0) {
                    let appendNewRecords = [...this.state.modifiers, ...data.records];
                    this.setState({ modifiers: appendNewRecords, page, totalRecords: data.totalRecords, loading: false });
                }
            }
        );
    };


    componentWillUnmount() {
        window.removeEventListener('resize', this.handleResize);
    }

    addNewModifier = () => {
        this.state.showModal = true;
        this.setState(this.state);
    }

    handleResize = () => {
        this.setState({ windowWidth: window.innerWidth });
    };

    saveModifier = (modifier) => {
        if (modifier.id != '') {
            let id = modifier.id.trim();
            delete modifier.id;
            this.props.updateModifier(id, modifier, () => {
                this.getAllModifiers();
            });
        } else {
            delete modifier.id;
            this.props.addModifier(modifier, () => {
                this.getAllModifiers();
            });
        }
    };

    handleChangeRowsPerPage = (newRowSet) => {
        this.setState({ page: 0, loading: true });
        this.props.setRowsPerPage(newRowSet);
        this.props.getAllModifiers(
            {
                sort: this.state.sort,
                page: 0,
                rowsPerPage: newRowSet,
            },
            (data) => {
                if (data.records.length > 0) {
                    this.setState({ loading: false, modifiers: data.records, totalRecords: data.totalRecords });
                }
            }
        );
    };

    onDone = (action, object) => {
        if (action === "Save") {
            this.saveModifier(object);
        }
        this.state.showModal = false;
        this.state.modifier = {
            id: "",
            modifier_name: "",
            options: [
                {
                    "option_name": "",
                    "option_value": 0
                }
            ]

        };
        this.setState(this.state);
    }

    onClose = () => {
        this.state.showModal = true;
        this.state.modifier = {
            id: "",
            modifier_name: "",
            options: [
                {
                    "option_name": "",
                    "option_value": 0
                }
            ]

        };
        this.setState(this.state);
    }

    editModifier = (modifier) => {
        this.state.showModal = true;
        this.state.modifier = _.merge({}, modifier);
        this.setState(this.state);
    }

    deleteModifier = (id) => {
        this.props.deleteModifier(id, {}, (data) => {
            if (data.status && data.status === 200) {
                this.setState({ modifiers: data.data, loading: false, totalRecords: data.data.length });
                toast.success("Modifier deleted successfully");
            } else {
                if(data && data.data && data.data.message && data.data.services){
                    this.state.deleteWarningModal = {
                        show: true,
                        title: "Warning!",
                        body: data.data.message,
                        services: data.data.services || [],
                        type: 'danger',
                        id: id,
                        deletable: true,
                    }
                    this.setState(this.state);
                }
            }
            // window.location.reload();
        });
    }

    deleteWarningModalClose = (action, object) => {
        this.state.deleteWarningModal = {
            show: false,
            title: "",
            body: "",
            services: [],
            type: "",
            id: null,
            deletable: true,
        };
        this.setState(this.state);
        if (action === "Yes") {
            this.props.deleteModifier(object.id, {confirm: true}, (data) => {
                // window.location.reload();
                if (data.status) {
                    this.setState({ modifiers: data.data, loading: false, totalRecords: data.data.length });
                    toast.success("Modifier deleted successfully");
                }
            });

            //   this.props.deleteSection(this.state.index, this.state.type);
        }
    }

    cloneModifier = (modifier) => {
        this.state.showModal = true;
        this.state.modifier = _.merge({}, modifier);
        this.state.modifier.id = '';
        this.state.modifier.modifier_name = this.state.modifier.modifier_name + " (copy)";
        this.setState(this.state);
    }

    sortingModifiers = (modifiers) => {
        const modifiersList = modifiers.map((modifier) => {
            return {
                id: modifier.id,
                sortNo: modifier.sortNo + (this.state.page * this.state.rowsPerPage),
            }
        });
        this.props.updateMultipleModifiers(modifiersList);
    }

    render() {
        const { showModal, loading, rowsPerPage, modifiers, page, totalRecords, modifier, windowWidth } = this.state;
        let tableHeader = [
            { id: 'modifier_name', label: 'Sets', sortable: false, beforeIcon: <DragSortIcon className="header-col scope-svg-header-icon" />, width: "150px" },
            { id: 'modifierActions', label: 'Action', sortable: false },
        ];

        return (
            <div>
                <div className="scope-services-heading">
                    <div className="scope-services-title">Modifier Sets</div>
                    <div onClick={this.addNewModifier}>
                        {
                            windowWidth < 700 ? <AddIconMobile /> : <AddIconMobile height="50" width="40" />
                        }
                    </div>
                </div>
                {loading ? <Spinner /> :
                    !_.isEmpty(modifiers) ? <CustomTable
                        tableHead={tableHeader}
                        tableData={modifiers}
                        editRow={this.editModifier}
                        clone={this.cloneModifier}
                        remove={this.deleteModifier}
                        type="services"
                        page={page}
                        sorting={this.sortingModifiers}
                        loading={loading}
                        rowsPerPage={rowsPerPage}
                        handleChangePage={this.handleChangePage}
                        handleChangeRowsPerPage={this.handleChangeRowsPerPage}
                        totalNumber={totalRecords}
                        disableDrag={false}
                    /> : <div className="empty-modifier-container">
                        <div className="empty-modifier-content">
                            <EmptyScreenIcon />
                            <h2>Oops...!</h2>
                            <h3>No Modifier Found</h3>
                        </div>
                    </div>
                }
                <AddEditModifierModal show={showModal} save={this.saveModifier} modifier={modifier} close={this.onClose} done={this.onDone} />
                <DeleteWarningModal modalSettings={this.state.deleteWarningModal} close={this.deleteWarningModalClose} />
            </div>
        );
    }
}



const mapStateToProps = (state) => {
    return {
        rowsPerPage: state.appData.rowsPerPage,
        modifiers: state.appData.modifiers
    };
};
const mapDispatchToProps = (dispatch) => {
    return {
        setRowsPerPage: (rows) => dispatch(setRowsPerPage(rows)),
        getAllModifiers: (modifier, cb) => dispatch(getAllModifiers(modifier, cb)),
        addModifier: (modifier, cb) => dispatch(addModifier(modifier, cb)),
        deleteModifier: (id, modifier, cb) => dispatch(deleteModifier(id, modifier, cb)),
        updateModifier: (id, modifier, cb) => dispatch(updateModifier(id, modifier, cb)),
        updateMultipleModifiers: (object, cb) => dispatch(updateMultipleModifiers(object, cb)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Modifiers);